/* eslint-disable react/destructuring-assignment */
function GetCompanyAttributes(
  companySearchResult,
  companyAttribute,
  companyKey
) {
  // Find the company with the matching companyKey
  const company = companySearchResult.find(
    (item) => item.company.entityKey === companyKey
  );

  if (!company) {
    return null; // Return null if no company is found with the given key
  }

  switch (companyAttribute) {
    case 'company':
      return company.company; // Return the company object
    case 'jobs':
      return company.jobs; // Return the jobs array
    case 'presentations':
      return company.presentations; // Return the presentations array
    default:
      return null; // Return null for invalid companyAttribute
  }
}

export default GetCompanyAttributes;
