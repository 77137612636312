import React, {useEffect, useState} from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import {Container, Row, Col, Alert} from 'react-bootstrap';
import axios from 'axios';
import {useForm} from 'react-hook-form';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

function useQuery() {
  const {search} = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function checkTokenValid(token) {
  let valid = true;
  try {
    const {exp} = jwt_decode(token);

    const now = Date.now().valueOf() / 1000;
    if (typeof exp !== 'undefined' && exp < now) {
      valid = false;
      throw new Error(`token expired: ${JSON.stringify(exp)} < ${now}`);
    } else {
      console.log(`The token is still valid: ${JSON.stringify(exp)} >= ${now}`);
    }
  } catch (error) {
    valid = false;
  }

  return valid;
}

function ChangePassword() {
  const [tokenValid, setTokenValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [updateFailed, setUpdateFailed] = useState(false);
  const [showFailedTag, setShowFailedTag] = useState(false);
  const [showSuccessTag, setShowSuccessTag] = useState(false);
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  // let { token } = useParams();
  const query = useQuery();
  const token = query.get('token');

  useEffect(() => {
    if (!checkTokenValid(token)) {
      setTokenValid(false);
    }
  }, [token]);

  const validationSchema = yup.object().shape({
    newPassword: yup
      .string()
      .required('New password required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*"'()+,-./:;<=>?[\]^_`{|}~])(?=.{8,})/,
        'Does not meet the password rules!'
      ),
    newPassword2: yup
      .string()
      .oneOf(
        [yup.ref('newPassword'), null],
        'Does not match the new password entered above!'
      )
      .required('Please repeat new password!'),
  });

  const {
    formState: {errors},
    handleSubmit,
    register,
    setValue: setPasswordValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  async function handleSubmitUpdatePassword(data) {
    setLoading(true);
    setIsError(false);
    setIsSuccess(false);
    setUpdateFailed(false);
    setShowSuccessTag(false);
    setShowFailedTag(false);
    try {
      const changePassword = {
        token: `${token}`,
        newPassword: data.newPassword,
        repeatNewPassword: data.newPassword2,
      };
      const response = await axios.post(
        `${API_SERVER_URL}/api/user/changePassword`,
        changePassword,
        {
          headers: {Authorization: `Bearer ${token}`},
        }
      );
      if (response.status === 200) {
        setIsSuccess(true);
        setPasswordValue('newPassword', '');
        setPasswordValue('newPassword2', '');
        setShowSuccessTag(true);
      } else {
        setUpdateFailed(true);
        setShowFailedTag(true);
        setIsError(true);
      }
    } catch (e) {
      setUpdateFailed(true);
      setShowFailedTag(true);
      setShowSuccessTag(false);
      setIsSuccess(false);
    }
    setLoading(false);
  }

  if (!token) {
    return <Redirect to="/" />;
  }
  if (!tokenValid) {
    return (
      <Container>
        <Row>
          <Col className="text-center">
            <p className="mt-3 d-inline-block">
              The link has expired, please use{' '}
              <a href="/forgot" className="text-success">
                Forgot Password
              </a>{' '}
              to resend reset link.
            </p>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col>
          <h3 className="text-center py-5">Input New Password</h3>
          <div className="mt-5 mb-3">
            {isError && !isSuccess && (
              <small className="mt-3 d-inline-block text-danger">
                Something went wrong. Please try again later.
              </small>
            )}
          </div>
        </Col>
      </Row>
      <Col>
        <form onSubmit={handleSubmit(handleSubmitUpdatePassword)}>
          <div className="my-4  ml-2">
            <label className="form-label">New password</label>
            <div id="passwordHelp" className="text-primary">
              <small>
                (Minimum 8 characters and contain at least one upper case
                letter, one lower case letter, one number, and one special
                character)
              </small>
            </div>
            <input
              type="password"
              {...register('newPassword')}
              className={`form-control ${
                errors.newPassword ? 'is-invalid' : ''
              }`}
              id="userInputNewPassword"
            />
            <div className="invalid-feedback">
              {errors.newPassword?.message}
            </div>
          </div>
          <div className="my-4  ml-2">
            <label className="form-label">New password again</label>
            <input
              type="password"
              {...register('newPassword2')}
              className={`form-control ${
                errors.newPassword2 ? 'is-invalid' : ''
              }`}
              id="userInputNewPasswordAgain"
            />
            <div className="invalid-feedback">
              {errors.newPassword2?.message}
            </div>
          </div>
          <div className="mb-4  ml-3">
            {!isSuccess && (
              <button
                type="submit"
                disabled={loading}
                className="px-5 btn btn-primary rounded-pill"
              >
                {loading ? 'Please waiting...' : 'Submit'}
              </button>
            )}
            {isSuccess && (
              <a href="/signin" className="px-5 btn btn-success rounded-pill">
                Re-Signin
              </a>
            )}
          </div>
        </form>
        {isSuccess && showSuccessTag && (
        <Alert
          variant="success"
          onClose={() => setShowSuccessTag(false)}
          dismissible
          style={{width: '270px'}}
          className="ml-3"
        >
          Change successful!
        </Alert>
      )}
      {updateFailed && showFailedTag && (
        <Alert
          variant="danger"
          onClose={() => setShowFailedTag(false)}
          dismissible
          style={{width: '270px'}}
          className="ml-3"
        >
          Change failed!
        </Alert>
      )}
      </Col>
    </Container>
  );
}

export default ChangePassword;
