import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Card} from 'react-bootstrap';
import {useRecoilValue} from 'recoil';
import moment from 'moment';
import {BsExclamationCircleFill} from 'react-icons/bs';
import axios from 'axios';
import fp from 'lodash/fp';
import {userSettings, talentApplicationListState} from '../store/atoms';
import JobApplyCard from './JobApplyCard';

function JobApplyHistoryCard({apply, onDeleteFavorite}) {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const [applyJobShow, setApplyJobShow] = useState(false);
  const [applyButtonText, setApplyButtonText] = useState('Apply');
  const talentApplicationList = useRecoilValue(talentApplicationListState);
  const applyJob = {
    entityKey: apply.jobKey,
    jobTitle: apply.appliedJobAttributes.jobTitle,
  };
  const [companyInfo, setCompanyInfo] = useState(undefined);
  const [webLink, setWebLink] = useState(undefined);
  const [companyName, setCompanyName] = useState(undefined);

  useEffect(() => {
    async function fetchCompanyLink(searchOptions, entityKey) {
      try {
        const findCompaniesResponse = await axios.post(
          `${API_SERVER_URL}/api/company/findDetailsByKeyWord/`,
          searchOptions,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (findCompaniesResponse.status === 200) {
          const temp = fp.filter((el) => el.company?.entityKey === entityKey)(
            findCompaniesResponse.data
          );
          try {
            const findPresentationResponse = await axios.get(
              `${API_SERVER_URL}/api/company/presentation/findByEntityKey/${temp[0].presentationKey}`,
              {headers: {Authorization: `Bearer ${userInfo.token}`}}
            );
            if (findPresentationResponse.status === 200) {
              setWebLink(
                findPresentationResponse.data.presentationAttributes
                  .videoPresentationLink
              );
            }
          } catch (error) {
            console.error(
              'CompanyPresentationCardModal found company Presentation error - ',
              error
            );
          }
        }
      } catch (error) {
        console.error('Failed to get inbox messages - ', error);
      }
    }

    async function fetchCompanyInfo(entityKey) {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/company/findByEntityKey/${entityKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          setCompanyName(response.data.companyName);
          const searchOptions = {
            searchString: `${response.data.companyName.trim()}`,
            searchAttributes: '',
          };
          fetchCompanyLink(searchOptions, response.data.entityKey);
        }
      } catch (error) {
        console.error('Failed to get inbox messages - ', error);
      }
    }

    async function fetchJobDetail() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/company/job/findByEntityKey/${apply.jobKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          setCompanyInfo(response.data.jobAttributes);
          fetchCompanyInfo(response.data.companyKey);
        }
      } catch (error) {
        console.error('CompanyJobAdd found job error - ', error);
      }
    }

    if (talentApplicationList) {
      const applyIndex = talentApplicationList.findIndex(
        (listItem) => listItem.jobKey === apply.jobKey
      );
      if (applyIndex > -1) {
        if (
          talentApplicationList[applyIndex].appliedJobAttributes?.status ===
          'applied'
        ) {
          setApplyButtonText('Applied');
        } else if (
          talentApplicationList[applyIndex].appliedJobAttributes?.status ===
          'draft'
        ) {
          setApplyButtonText('Draft');
        } else {
          setApplyButtonText('Apply');
        }
        fetchJobDetail();
      } else {
        setApplyButtonText('Apply');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentApplicationList]);

  return (
    <Col md={12} className="my-3">
      <Card className="bg-light">
        <Card.Header>
          {apply.appliedJobAttributes?.jobTitle}{' '}
          {companyInfo &&
            companyInfo?.closed &&
            companyInfo.closed === 'true' && (
              <span
                style={{
                  'margin-left': '20px',
                  'font-size': '13px',
                  color: '#ffba00',
                }}
              >
                {' '}
                <BsExclamationCircleFill /> Closed Job
              </span>
            )}
        </Card.Header>
        <Card.Body>
          <dl className="row my-3">
            <dt className="col-sm-4 text-muted">Company Name:</dt>
            <dd className="col-sm-8">{companyName}</dd>
            <dt className="col-sm-4 text-muted">Video Presentation:</dt>
            <dd className="col-sm-8">
              <a
                href={webLink}
                target="_blank"
                rel="noreferrer"
                style={{color: 'black'}}
              >
                {webLink}
              </a>
            </dd>
            <dt className="col-sm-4 text-muted">Job ID:</dt>
            <dd className="col-sm-8">{apply?.jobKey}</dd>
          </dl>

          <Card.Text>
            Applied at{' '}
            {moment(apply?.updatedTimestamp).format('MMMM Do YYYY, h:mm:ss a')}
          </Card.Text>
        </Card.Body>
        <Card.Footer className="bg-light">
          <Row>
            {!(
              companyInfo &&
              companyInfo?.closed &&
              companyInfo.closed === 'true'
            ) ? (
              <Col md={2}>
                {applyButtonText === 'Applied' && (
                  <button
                    type="button"
                    className="btn btn-block btn-success rounded-pill"
                    onClick={() => setApplyJobShow(true)}
                  >
                    {applyButtonText}
                  </button>
                )}
                {applyButtonText === 'Draft' && (
                  <button
                    type="button"
                    className="btn btn-block btn-outline-warning rounded-pill"
                    onClick={() => setApplyJobShow(true)}
                  >
                    {applyButtonText}
                  </button>
                )}
                {applyButtonText === 'Apply' && (
                  <button
                    type="button"
                    className="btn btn-block btn-outline-primary rounded-pill"
                    onClick={() => setApplyJobShow(true)}
                  >
                    {applyButtonText}
                  </button>
                )}
              </Col>
            ) : (
              <Col md={1} className="text-right">
                {companyInfo &&
                  companyInfo?.closed &&
                  companyInfo.closed === 'true' && (
                    <button
                      type="button"
                      className="btn btn-block btn-outline-success rounded-pill"
                      style={{width: '120px'}}
                      onClick={() => {
                        onDeleteFavorite(`${apply.entityKey}`);
                      }}
                    >
                      Delete
                    </button>
                  )}
              </Col>
            )}
          </Row>
        </Card.Footer>
      </Card>
      {applyJobShow && (
        <JobApplyCard
          job={applyJob}
          showApplyModal={applyJobShow}
          onHideApplyModal={() => setApplyJobShow(false)}
        />
      )}
    </Col>
  );
}

JobApplyHistoryCard.propTypes = {
  apply: PropTypes.object.isRequired,
  onDeleteFavorite: PropTypes.func.isRequired,
};

export default JobApplyHistoryCard;
