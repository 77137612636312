import React, {useState, useEffect} from 'react';
import {Container, Row, Col, Alert} from 'react-bootstrap';
import axios from 'axios';
import * as yup from 'yup';
import {useRecoilValue} from 'recoil';
import {useForm} from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {yupResolver} from '@hookform/resolvers/yup';
import CompanyInfoLinks from './CompanyInfoLinks';
import {userSettings} from '../store/atoms';

import 'react-phone-input-2/lib/style.css';

function AlertDismissibleText() {
  const [show, setShow] = useState(true);
  if (show) {
    return (
      <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <p>
          If you want to provide business status info for talents to manage
          equity pays, please provide equity Info here, thanks!
        </p>
      </Alert>
    );
  }

  return null;
}

function CompanyEquityInfoUpdate() {
  const has = Object.prototype.hasOwnProperty;
  const userInfo = useRecoilValue(userSettings);
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [equityAlert, setEquityAlert] = useState(false);
  const [fundingStatus, setFundingStatus] = useState('');
  const [fundingOther, setFundingOther] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [isCompanyInfoExist, setIsCompanyInfoExist] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({});
  const equityInfoAttributes = [
    'operationalStatus',
    'sharePrice',
    'source',
    'additional',
  ];
  const fundingStatusOptions = {
    Owner: 'Owner funded',
    Angel: 'Angel funded',
    Venture: 'VC funded',
    Other: 'Other',
  };
  const validationSchema = yup.object().shape({});

  const equityInfoAttributesDefaultValues = {
    operationalStatus: 'Operational',
  };

  const {
    formState: {errors, isSubmitting},
    handleSubmit,
    register,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: equityInfoAttributesDefaultValues,
    mode: 'onBlur',
  });

  useEffect(() => {
    let response;
    async function fetchCompanyUser() {
      try {
        response = await axios.get(
          `${API_SERVER_URL}/api/company/user/findByUserKey/${userInfo.userKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          const {companyKey} = response.data;
          try {
            response = await axios.get(
              `${API_SERVER_URL}/api/company/findByEntityKey/${companyKey}`,
              {
                headers: {Authorization: `Bearer ${userInfo.token}`},
              }
            );
            if (response.status === 200) {
              setIsCompanyInfoExist(true);
              setCompanyInfo(response.data);
              const fundingStatusSaved =
                response.data.companyAttributes.fundingStatus || '';
              if (!fundingStatusSaved) {
                setEquityAlert(true);
              } else if (
                ['Owner', 'Angel', 'Venture'].includes(fundingStatusSaved)
              ) {
                setFundingStatus(fundingStatusSaved);
              } else if (
                !['Owner', 'Angel', 'Venture'].includes(fundingStatusSaved) &&
                fundingStatusSaved !== ''
              ) {
                setFundingStatus('Other');
                setFundingOther(fundingStatusSaved);
              }
              equityInfoAttributes.forEach((element) => {
                if (has.call(response.data.companyAttributes, element)) {
                  setValue(element, response.data.companyAttributes[element]);
                }
              });
              if (has.call(response.data.companyAttributes, 'valuationDate')) {
                setStartDate(
                  new Date(response.data.companyAttributes.valuationDate)
                );
              }
            }
          } catch (error) {
            setIsCompanyInfoExist(false);
          }
        }
      } catch (error) {
        console.error(
          'CompanyEquityInfoUpdate found company user error - ',
          error
        );
      }
    }

    if (userInfo.userKey) {
      fetchCompanyUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function updateCompanyInfo(data) {
    const o = new Intl.DateTimeFormat('en', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    const dataFormatted = o.format(startDate);
    const newCompanyInfo = JSON.parse(JSON.stringify(companyInfo));
    newCompanyInfo.companyAttributes.valuationDate = dataFormatted;

    if (['Owner', 'Angel', 'Venture'].includes(fundingStatus)) {
      newCompanyInfo.companyAttributes.fundingStatus = fundingStatus;
    } else if (fundingStatus === 'Other') {
      if (!fundingOther) {
        newCompanyInfo.companyAttributes.fundingStatus = 'Other';
      } else {
        newCompanyInfo.companyAttributes.fundingStatus = fundingOther;
      }
    }

    equityInfoAttributes.forEach((element) => {
      newCompanyInfo.companyAttributes[element] = data[element];
    });

    try {
      const response = await axios.put(
        `${API_SERVER_URL}/api/company/updateByEntityKey/${userInfo.companyKey}`,
        newCompanyInfo,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (response.status === 200) {
        setIsSuccess(true);
      }
    } catch (error) {
      setIsSuccess(false);
      setIsError(true);
    }
  }

  const onSubmit = (data) => {
    setIsSubmitted(true);
    setIsError(false);
    setIsSuccess(false);
    if (fundingStatus === '') return;
    updateCompanyInfo(data);
  };

  if (!isCompanyInfoExist) {
    return (
      <Container className="shadow rounded">
        <Row>
          <Col>
            <CompanyInfoLinks linkKey="businessStatus" />
          </Col>
        </Row>
        <Row className="py-5 m-5">
          <Col>
            <h2>Please input Corporate Info first.</h2>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="mb-5">
      <Row>
        <Col>
          <CompanyInfoLinks linkKey="businessStatus" />
        </Col>
      </Row>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <div className="mb-3">
              {isError && !isSuccess && (
                <small className="mt-3 d-inline-block text-danger">
                  Something went wrong. Please try again later.
                </small>
              )}
              {isSuccess && (
                <small className="mt-3 d-inline-block text-success">
                  Submit success!
                </small>
              )}
            </div>
          </Col>
        </Row>
        <Row className="my-2">
          <Col />
        </Row>
        <Row>
          <Col xs={12} md={8} className="shadow rounded">
            <h4 className="my-4 text-center">Business Status Info</h4>

            {equityAlert && <AlertDismissibleText />}

            <div className="form-group row my-4 has-validation">
              <label
                htmlFor="userInputFundingStatus"
                className="col-sm-4  col-xl-3  col-form-label"
              >
                Funding Status<abbr className="text-danger">*</abbr>
              </label>
              <div className="col-sm-8 col-xl-9">
                <div className="form-check mb-2 ">
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={fundingStatus === 'Owner'}
                    onChange={(e) => setFundingStatus(e.target.value)}
                    name="fundingOptions"
                    id="fundingOptions1"
                    value="Owner"
                  />
                  <label className="form-check-label">
                    {fundingStatusOptions.Owner}
                  </label>
                </div>
                <div className="form-check mb-2 ">
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={fundingStatus === 'Angel'}
                    onChange={(e) => setFundingStatus(e.target.value)}
                    name="fundingOptions"
                    id="fundingOptions2"
                    value="Angel"
                  />
                  <label className="form-check-label">
                    {fundingStatusOptions.Angel}
                  </label>
                </div>
                <div className="form-check mb-2 ">
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={fundingStatus === 'Venture'}
                    onChange={(e) => setFundingStatus(e.target.value)}
                    name="fundingOptions"
                    id="fundingOptions3"
                    value="Venture"
                  />
                  <label className="form-check-label">
                    {fundingStatusOptions.Venture}
                  </label>
                </div>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={
                      !['Owner', 'Angel', 'Venture'].includes(fundingStatus) &&
                      fundingStatus !== ''
                    }
                    name="fundingOptions"
                    id="fundingOptions4"
                    onChange={(e) => setFundingStatus(e.target.value)}
                    value="Other"
                  />
                  <label className="form-check-label">
                    {fundingStatusOptions.Other}
                  </label>
                  {!['Owner', 'Angel', 'Venture'].includes(fundingStatus) &&
                    fundingStatus !== '' && (
                      <input
                        type="text"
                        className="form-control mt-2"
                        placeholder="Please specify"
                        id="userInputOtherFundingStatus"
                        disabled={['Owner', 'Angel', 'Venture'].includes(
                          fundingStatus
                        )}
                        aria-describedby="userInputOtherEligibilityHelp"
                        value={fundingOther}
                        onChange={(e) => setFundingOther(e.target.value)}
                        required
                      />
                    )}
                </div>
                {isSubmitted && fundingStatus === '' && (
                  <div className="text-danger font-weight-light">
                    Funding Status is required.
                  </div>
                )}
              </div>
            </div>

            <div className="form-group row my-4">
              <label
                htmlFor="userInputOperationalStatus"
                className="col-sm-4  col-xl-3  col-form-label"
              >
                Operational Status<abbr className="text-danger">*</abbr>
              </label>
              <div className="col-sm-8 col-xl-9">
                <div className="form-check mb-2 ">
                  <input
                    className="form-check-input"
                    {...register('operationalStatus', {required: true})}
                    type="radio"
                    value="Operational"
                    name="operationalStatus"
                    id="operationalStatus1"
                  />
                  <label className="form-check-label">Operational</label>
                </div>
                <div className="form-check mb-2 ">
                  <input
                    className="form-check-input"
                    {...register('operationalStatus', {required: true})}
                    type="radio"
                    value="Dissolved"
                    name="operationalStatus"
                    id="operationalStatus2"
                  />
                  <label className="form-check-label">Dissolved</label>
                </div>
                <div className="invalid-feedback">
                  {errors.operationalStatus?.message}
                </div>
              </div>
            </div>

            <div className="form-group row my-4">
              <label
                htmlFor="userInputSharePrice"
                className="col-sm-4  col-xl-3  col-form-label"
              >
                Valuation (USD)
              </label>
              <div className="col-sm-8 col-xl-9">
                <input
                  type="text"
                  {...register('sharePrice')}
                  className={`form-control ${
                    errors.sharePrice ? 'is-invalid' : ''
                  }`}
                  id="userInputSharePrice"
                  aria-describedby="userInputSharePriceHelp"
                />
                <div className="invalid-feedback">
                  {errors.sharePrice?.message}
                </div>
                <div id="sharePriceHelp" className="form-text">
                  <small>
                    (For example 120K, 3.5M, or N/A if not available)
                  </small>
                </div>
                <div className="invalid-feedback">{errors.source?.message}</div>
              </div>
            </div>

            <div className="form-group row my-4">
              <label
                htmlFor="userInputSource"
                className="col-sm-4  col-xl-3  col-form-label"
              >
                Valuation Source
              </label>
              <div className="col-sm-8 col-xl-9">
                <input
                  type="text"
                  {...register('source')}
                  className={`form-control ${
                    errors.source ? 'is-invalid' : ''
                  }`}
                  id="userInputSource"
                  aria-describedby="userInputSourceHelp"
                />
                <div id="sharePriceHelp" className="form-text">
                  <small>
                    (For example, internal valuation, valuation provider, like
                    Carta, or leave this information blank.)
                  </small>
                </div>
                <div className="invalid-feedback">{errors.source?.message}</div>
              </div>
            </div>

            <div className="form-group row my-4">
              <label
                htmlFor="userInputvaluationDate"
                className="col-sm-4  col-xl-3  col-form-label"
              >
                Valuation date
              </label>
              <div className="col-sm-8 col-xl-9">
                <DatePicker
                  dateFormat="MM/dd/yyyy"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group row my-4">
              <label
                htmlFor="userInputAdditional"
                className="col-sm-4  col-xl-3  col-form-label"
              >
                Additional Info
              </label>
              <div className="col-sm-8 col-xl-9">
                <textarea
                  rows="5"
                  type="text"
                  {...register('additional')}
                  className={`form-control ${
                    errors.additional ? 'is-invalid' : ''
                  }`}
                  id="userInputAdditional"
                  aria-describedby="userInputAdditionalHelp"
                />
                <div className="invalid-feedback">
                  {errors.additional?.message}
                </div>
                <div id="sharePriceHelp" className="form-text">
                  <small>
                    (Please provide any additional information, that is not
                    proprietary, about the status of your company&apos;s
                    business so interested parties can understand your company
                    and it&apos;s business better.)
                  </small>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="my-2">
          <Col>
            <div className="row my-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className="mx-2 btn btn-success px-5 my-3 rounded-pill"
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-1" />
                )}
                Save
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </Container>
  );
}

export default CompanyEquityInfoUpdate;
