import React, {useState, useEffect} from 'react';
import {Switch, Route, useRouteMatch, useParams} from 'react-router-dom';
import {Container, Row, Col, Alert} from 'react-bootstrap';
import axios from 'axios';
import CompanyPresentationCard from '../common/CompanyPresentationCard';

function ProfilesNotExistAlert() {
  const [show, setShow] = useState(true);
  if (show) {
    return (
      <Alert
        variant="warning"
        className="my-5"
        onClose={() => setShow(false)}
        dismissible
      >
        <Alert.Heading>
          Sorry, the company presentation does not exist or is not open to the
          public.
        </Alert.Heading>
      </Alert>
    );
  }

  return null;
}

function CompanyPublicProfile() {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const {url} = useRouteMatch();
  const {profile, user} = useParams();
  const [presentation, setPresentation] = useState({});

  useEffect(() => {
    async function fetchCompanyPresentation() {
      try {
        const fetchAPI = `${API_SERVER_URL}/api/company/presentation/public/findByCompanyKey/${profile}`;
        const response = await axios.get(fetchAPI);
        if (response.status === 200) {
          // console.log("CompanyPublicProfile found company presentation - ", JSON.stringify(response.data[0]));
          setPresentation(response.data[0]);
        }
      } catch (error) {
        console.log(
          'CompanyPublicProfile found company presentation error - ',
          error
        );
      }
    }
    fetchCompanyPresentation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, profile]);

  return (
    <>
      <Container>
        {presentation ? (
          <Row className="my-3">
            <Col>
              <CompanyPresentationCard presentationInfo={presentation} />
            </Col>
          </Row>
        ) : (
          <ProfilesNotExistAlert />
        )}
      </Container>
      <Switch>
        <Route path={`${url}/:user/:profile`} />
      </Switch>
    </>
  );
}

export default CompanyPublicProfile;
