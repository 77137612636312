import {atom} from 'recoil';

export const userSettings = atom({
  key: 'userSettings',
  default: {
    firstName: localStorage.getItem('firstName') || '',
    lastName: localStorage.getItem('lastName') || '',
    emailAddress: localStorage.getItem('emailAddress') || '',
    userKey: localStorage.getItem('userKey') || '',
    companyKey: localStorage.getItem('companyKey') || '',
    userTypes: localStorage.getItem('userTypes') || '',
    authenticated: localStorage.getItem('authenticated') || '',
    loggedInAsUserType: localStorage.getItem('loggedInAsUserType') || '',
    token: localStorage.getItem('token') || '',
  },
});

export const companyJobListState = atom({
  key: 'companyJobListState',
  default: [],
});

export const companyJobApplicantsListState = atom({
  key: 'companyJobApplicantsListState',
  default: [],
});

export const companyJobSummaryListState = atom({
  key: 'companyJobSummaryListState',
  default: [],
});

export const companyPresentationState = atom({
  key: 'companyPresentationState',
  default: {},
});

export const talentFavoriteCompanyListState = atom({
  key: 'talentFavoriteCompanyListState',
  default: [],
});

export const talentFavoriteJobListState = atom({
  key: 'talentFavoriteJobListState',
  default: [],
});

export const investorFavoriteCompanyListState = atom({
  key: 'investorFavoriteCompanyListState',
  default: [],
});

export const investorCompanySearchResultState = atom({
  key: 'investorCompanySearchResultState',
  default: [],
});

export const investorCompanySearchStringState = atom({
  key: 'investorCompanySearchStringState',
  default: '',
});

export const talentProfileListState = atom({
  key: 'talentProfileListState',
  default: [],
});

export const talentApplicationListState = atom({
  key: 'talentApplicationList',
  default: [],
});

export const talentEquityHeldListState = atom({
  key: 'talentEquityHeldListState',
  default: [],
});

export const talentEquityNotHeldListState = atom({
  key: 'talentEquityNotHeldListState',
  default: [],
});

export const talentCompanyInfoListState = atom({
  key: 'talentCompanyInfoListState',
  default: [],
});

export const talentJobSearchResultState = atom({
  key: 'talentJobSearchResultState',
  default: [],
});

export const talentJobSearchStringState = atom({
  key: 'talentJobSearchStringState',
  default: '',
});

export const talentCompanySearchResultState = atom({
  key: 'talentCompanySearchResultState',
  default: [],
});

export const talentCompanySearchStringState = atom({
  key: 'talentCompanySearchStringState',
  default: '',
});

export const newFeedbackInfoState = atom({
  key: 'newFeedbackInfoState',
  default: {
    show: false,
    status: '',
    title: '',
    body: '',
  },
});

export const systemAlertInfoState = atom({
  key: 'systemAlertInfoState',
  default: {
    show: false,
    status: '',
    title: '',
    body: '',
  },
});

export const startVideoPostsState = atom({
  key: 'startVideoPostsState',
  default: [],
});

export const startVideoRecommendedListState = atom({
  key: 'startVideoRecommendedListState',
  default: [],
});

export const userVideoWatchingState = atom({
  key: 'userVideoWatchingState',
  default: {
    watchingId: '',
  },
});

export const searchCompanyResultVideosState = atom({
  key: 'searchCompanyResultVideosState',
  default: [],
});

export const searchCompanyResultState = atom({
  key: 'searchCompanyResultState',
  default: [],
});
