import React, {useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {useRecoilValue, useRecoilState} from 'recoil';
import axios from 'axios';
import {userSettings, investorFavoriteCompanyListState} from '../store/atoms';
import CompanyFavoriteCard from './CompanyFavoriteCard';

function InvestorFavorite() {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const [investorFavoriteCompanyList, setInvestorFavoriteCompanyList] =
    useRecoilState(investorFavoriteCompanyListState);

  useEffect(() => {
    async function fetchFavoriteList() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/investor/favorite/company/findFavoriteDetailByUserKey/${userInfo.userKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setInvestorFavoriteCompanyList(response.data);
        }
      } catch (error) {
        console.error(
          'InvestorFavorite found FavoriteList from remote error - ',
          error
        );
      }
    }

    fetchFavoriteList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investorFavoriteCompanyList.length]);

  const connectCompany = async (companyName, companyKey) => {
    console.log(
      'Investor click to connect company - ',
      companyName,
      companyKey
    );
  };

  const addToFavorite = async (companyKey) => {
    const newFavorite = {
      userKey: userInfo.userKey,
      companyKey,
    };
    try {
      const response = await axios.post(
        `${API_SERVER_URL}/api/investor/favorite/company/addFavorite`,
        newFavorite,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        setInvestorFavoriteCompanyList((oldFavoriteCompanyList) => [
          ...oldFavoriteCompanyList,
          response.data,
        ]);
      }
    } catch (error) {
      console.log('InvestorFavorite add new favorite company error -', error);
    }
  };

  const deleteFavorite = async (favoriteEntityKey) => {
    try {
      const response = await axios.delete(
        `${API_SERVER_URL}/api/investor/favorite/company/deleteFavoriteByEntityKey/${favoriteEntityKey}`,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (response.status === 200) {
        const favoriteIndex = investorFavoriteCompanyList.findIndex(
          (listItem) => listItem.entityKey === favoriteEntityKey
        );
        const newFavoriteList = [
          ...investorFavoriteCompanyList.slice(0, favoriteIndex),
          ...investorFavoriteCompanyList.slice(favoriteIndex + 1),
        ];
        setInvestorFavoriteCompanyList(newFavoriteList);
      }
    } catch (error) {
      console.log('InvestorFavorite delete favorite company error -', error);
    }
  };

  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <p>Favorite Companies</p>
        </Col>
      </Row>
      <Row className="mt-1">
        {investorFavoriteCompanyList &&
          investorFavoriteCompanyList.map((item) => (
            <CompanyFavoriteCard
              key={item.entityKey}
              companyInfo={item}
              onAddFavorite={addToFavorite}
              onDeleteFavorite={deleteFavorite}
              onConnectCompany={connectCompany}
            />
          ))}
      </Row>
    </Container>
  );
}

export default InvestorFavorite;
