import React, {useState, useEffect} from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import {Container, Row, Col} from 'react-bootstrap';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import axios from 'axios';

function useQuery() {
  const {search} = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function checkTokenValid(token) {
  let valid = true;
  try {
    const {exp} = jwt_decode(token);
    const now = Date.now().valueOf() / 1000;
    if (typeof exp !== 'undefined' && exp < now) {
      valid = false;
      throw new Error(`token expired: ${JSON.stringify(exp)} < ${now}`);
    } else {
      console.log(`The token is still valid: ${JSON.stringify(exp)} >= ${now}`);
    }
  } catch (error) {
    valid = false;
  }
  return valid;
}

function VerifyNewAccount() {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [tokenValid, setTokenValid] = useState(true);
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const query = useQuery();
  const token = query.get('token');
  const email = query.get('email');
  const decodedEmail = email ? decodeURIComponent(email.replace(/ /g, '+')) : null;
  const resendVerifyCodePageLink = `/resendVerifyCode`;
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    if (!token || !email) {
      setShouldRedirect(true);
    }
    if (!checkTokenValid(token)) {
      setTokenValid(false);
    }
  }, [token, email]);

  if (shouldRedirect) {
    return <Redirect to="/" />;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    setIsError(false);
    setSubmitted(false);
    try {
      const validationToken = {token: `${token}`};
      await axios.post(`${API_SERVER_URL}/api/user/verify`, validationToken);
      setSubmitted(true);
    } catch (error) {
      setIsError(true);
    } finally {
      setLoading(false);
    }
  }

  if (!tokenValid) {
    return (
      <Container>
        <Row>
          <Col className="text-center">
            <p className="mt-3 d-inline-block">
              The link has expired, please use{' '}
              <a href={resendVerifyCodePageLink} className="text-success">
                Resend Verify Code
              </a>{' '}
              to resend verify link.
            </p>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="">
      <Row>
        <Col xs={6} className="d-none d-sm-block">
          <h3 className="mt-5 w-100">
            <span>
              The place where remote talents and American startup companies 
              join forces to build the future.
            </span>
          </h3>
        </Col>
        <Col>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              {isError && (
                <p className="mt-3 d-inline-block">
                  Your account could not be verified from this token, please use{' '}
                  <a href={resendVerifyCodePageLink} className="text-warning">
                    Resend Verify Code
                  </a>{' '}
                  to resend your verify code.
                </p>
              )}
              {submitted && !isError && (
                <p className="mt-3 d-inline-block">
                  Your Account is now verified you can now{' '}
                  <a href="/signin" className="text-success">
                    log in
                  </a>
                  !
                </p>
              )}
            </div>
            {!submitted && !isError && (
              <>
                <div className="my-4">
                  <label className="form-label">
                    Verify your account for email:{' '}
                    <b className="text-success">{decodedEmail}</b>
                  </label>
                </div>
                <div className="mb-3">
                  <button
                    type="submit"
                    disabled={loading || submitted}
                    className="px-5 px-5 btn btn-success rounded-pill"
                  >
                    {loading ? 'Please waiting...' : 'Verify'}
                  </button>
                </div>
              </>
            )}
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default VerifyNewAccount;
