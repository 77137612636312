import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import {Container, Row, Col, Navbar, Nav, NavDropdown} from 'react-bootstrap';
import {useRecoilValue} from 'recoil';
import {userSettings} from '../store/atoms';
import userTypes from '../store/constants';

const getNavDropdownText = (authenticated, emailAddress) => {
  let navDropdownTitle = '';
  if (authenticated) {
    navDropdownTitle = (
      <>
        <i className="bi bi-person-fill mr-1" />
        <span className="" style={{fontStyle: 'normal'}}>
          Me({emailAddress})
        </span>
      </>
    );
  }
  return navDropdownTitle;
};

function MainHeaderNavBarLoginType(props) {
  const {authenticated, loggedInAsUserType} = props;

  if (authenticated === true) {
    return (
      <NavDropdown.Item className="font-weight-lighter font-smaller">
        <b>Logged in as: {loggedInAsUserType}</b>
      </NavDropdown.Item>
    );
  }
  return null;
}

function MainHeaderNavBar() {
  const userInfo = useRecoilValue(userSettings);
  const navDropdownTitle = getNavDropdownText(
    userInfo.authenticated,
    userInfo.emailAddress
  );
  const [enableRoleSwitching, setEnableRoleSwitching] = useState(false);
  const [enableRoleAdd, setEnableRoleAdd] = useState(false);
  const demoAccounts = [
    'demo@startoutinc.com',
    'demo@startnation.live',
    'feedback@startnation.live',
  ];

  useEffect(() => {
    const ENABLE_ROLE_SWITCHING = process.env.REACT_APP_ENABLE_ROLE_SWITCHING;
    const ENABLE_ROLE_SWITCHING_FOR_DEMO_USER =
      process.env.REACT_APP_ENABLE_ROLE_SWITCHING_FOR_DEMO_USER;
    async function CheckRoleSwitchingEnable() {
      const userEmailAddress = userInfo.emailAddress.toLocaleLowerCase();
      if (
        ENABLE_ROLE_SWITCHING_FOR_DEMO_USER === 'true' &&
        demoAccounts.includes(userEmailAddress)
      ) {
        setEnableRoleSwitching(true);
        setEnableRoleAdd(true);
      }

      if (ENABLE_ROLE_SWITCHING === 'true') {
        setEnableRoleSwitching(true);
        setEnableRoleAdd(true);
      }
    }
    CheckRoleSwitchingEnable();
    // eslint-disable-next-line
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('authenticated');
    localStorage.removeItem('entityKey');
    localStorage.removeItem('userKey');
    localStorage.removeItem('companyKey');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('userTypes');
    localStorage.removeItem('loggedInAsUserType');
    localStorage.removeItem('emailAddress');
    localStorage.removeItem('token');
    window.location = '/';
  };

  return (
    <Container fluid>
      <Row>
        <Col xl={{span: 10, offset: 1}}>
          <Navbar collapseOnSelect expand="lg" className="my-0 py-0">
            <Navbar.Brand href="/">
              <img
                src="/images/startnation-logo-s.png"
                className="align-top img-fluid my-2"
                alt="Logo"
              />{' '}
              <span className="d-none font-weight-bolder">Start Nation!</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav-main" />
            <Navbar.Collapse
              id="basic-navbar-nav-main"
              className="my-0 my-md-0 mr-md-3 navbar-expand-lg justify-content-end"
            >
              <Nav>
                {userInfo.authenticated &&
                userInfo.loggedInAsUserType === userTypes.COMPANY.value ? (
                  <NavLink to="/company" className="p-2 text-success">
                    Company Home
                  </NavLink>
                ) : (
                  ''
                )}

                {userInfo.authenticated &&
                userInfo.loggedInAsUserType === userTypes.TALENT.value ? (
                  <NavLink to="/talent/jobs" className="p-2 text-success">
                    Talent Home
                  </NavLink>
                ) : (
                  ''
                )}

                {userInfo.authenticated &&
                userInfo.loggedInAsUserType === userTypes.INVESTOR.value ? (
                  <NavLink to="/investor" className="p-2 text-success">
                    Investor Home
                  </NavLink>
                ) : (
                  ''
                )}

                {!userInfo.authenticated ? (
                  <NavLink to="/signin" className="p-2 pl-3 text-body">
                    Log In
                  </NavLink>
                ) : (
                  <NavDropdown
                    title={navDropdownTitle}
                    className="text-success"
                  >
                    <MainHeaderNavBarLoginType
                      authenticated={userInfo.authenticated}
                      loggedInAsUserType={userInfo.loggedInAsUserType}
                    />
                    {/* <NavDropdown.Divider /> */}
                    {enableRoleSwitching ? (
                      <NavDropdown.Item href="/switch">
                        <i className="bi bi-arrow-left-right pr-1" />
                        Switch Login Type
                      </NavDropdown.Item>
                    ) : null}
                    {enableRoleAdd ? (
                      <NavDropdown.Item href="/addType">
                        <i className="bi bi-person-plus pr-1" />
                        Add Login Type
                      </NavDropdown.Item>
                    ) : null}
                    {/* <NavDropdown.Divider /> */}
                    <NavDropdown.Item onClick={handleLogout}>
                      <i className="bi bi-box-arrow-right pr-1" />
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                )}

                {!userInfo.authenticated ? (
                  <NavLink to="/signup" className="p-2 pl-3 text-body">
                    Sign up
                  </NavLink>
                ) : (
                  ''
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Col>
      </Row>
    </Container>
  );
}
MainHeaderNavBarLoginType.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  loggedInAsUserType: PropTypes.string.isRequired,
};

export default MainHeaderNavBar;
