import React from 'react';
import {Container} from 'react-bootstrap';
import {
  HashRouter as Router,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';
import InvestorLayoutHeader from '../layout/InvestorLayoutHeader';
import InvestorFindCompanies from './InvestorFindCompanies';
import InvestorFavorite from './InvestorFavorite';
import InvestorUserInfo from './InvestorUserInfo';
import InvestorMessage from './InvestorMessage';
import AccountInfoManagement from '../common/AccountInfoManagement';
import MessageInbox from '../common/message/MessageInbox';
import {MessageSent} from '../common/message/MessageSent';
import MessageDraft from '../common/message/MessageDraft';
import UserFeedbackInbox from '../common/feedback/UserFeedbackInbox';
import UserFeedbackSent from '../common/feedback/UserFeedbackSent';
import UserFeedbackDraft from '../common/feedback/UserFeedbackDraft';

function InvestorMain() {
  const {path} = useRouteMatch();
  return (
    <>
      <InvestorLayoutHeader />
      <Container>
        <Router>
          <Switch>
            <Route exact path={path} component={InvestorFindCompanies} />
            <Route
              exact
              path={`${path}/company/find`}
              component={InvestorFindCompanies}
            />
            <Route
              exact
              path={`${path}/favorite`}
              component={InvestorFavorite}
            />
            <Route
              exact
              path={`${path}/account`}
              component={AccountInfoManagement}
            />
            <Route exact path={`${path}/user`} component={InvestorUserInfo} />
            <Route
              exact
              path={`${path}/message/create`}
              component={InvestorMessage}
            />
            <Route
              exact
              path={`${path}/message/update`}
              component={InvestorMessage}
            />
            <Route
              exact
              path={`${path}/message/inbox`}
              component={MessageInbox}
            />
            <Route
              exact
              path={`${path}/message/sent`}
              component={MessageSent}
            />
            <Route
              exact
              path={`${path}/message/draft`}
              component={MessageDraft}
            />
            <Route
              exact
              path={`${path}/user-feedback/inbox`}
              component={UserFeedbackInbox}
            />
            <Route
              exact
              path={`${path}/user-feedback/sent`}
              component={UserFeedbackSent}
            />
            <Route
              exact
              path={`${path}/user-feedback/draft`}
              component={UserFeedbackDraft}
            />
          </Switch>
        </Router>
      </Container>
    </>
  );
}

export default InvestorMain;
