import React from 'react';
import PropTypes from 'prop-types';
import {Nav} from 'react-bootstrap';

function TalentMainJobLinks({linkKey}) {
  return (
    <Nav variant="pills" activeKey={linkKey}>
      <Nav.Item>
        <Nav.Link eventKey="jobFind" href="/talent/jobs">
          Find
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="jobFavorited" href="/talent/favorite/job">
          Favorite
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="jobApplied" href="/talent/history/job">
          Applied
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
}

TalentMainJobLinks.propTypes = {
  linkKey: PropTypes.string.isRequired,
};

export default TalentMainJobLinks;
