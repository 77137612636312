import React, {useState, useEffect, Suspense} from 'react';
import {Switch, Route, useRouteMatch, useParams} from 'react-router-dom';
import {Container, Row, Col, Alert} from 'react-bootstrap';
import axios from 'axios';

const TalentProfilePublicDetail = React.lazy(() =>
  import('./TalentProfilePublicDetail')
);

function ProfilesNotExistAlert() {
  const [show, setShow] = useState(true);
  if (show) {
    return (
      <Alert
        variant="warning"
        className="my-5"
        onClose={() => setShow(false)}
        dismissible
      >
        <Alert.Heading>
          Sorry, the profile does not exist or is not open to the public.
        </Alert.Heading>
      </Alert>
    );
  }

  return null;
}

function TalentPublicProfilePage() {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const {url} = useRouteMatch();
  const {profile, user} = useParams();
  const [userProfile, setUserProfile] = useState({});
  const [profileExist, setProfileExist] = useState(false);

  useEffect(() => {
    async function fetchTalentProfile() {
      try {
        const fetchAPI = `${API_SERVER_URL}/api/talent/profile/public/findByEntityKey/${profile}`;
        const response = await axios.get(fetchAPI);
        if (response.status === 200) {
          setUserProfile(response.data);
          setProfileExist(true);
        }
      } catch (error) {
        console.log('TalentProfile found talent profile  error - ', error);
      }
    }
    fetchTalentProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, profile]);

  return (
    <>
      <Container>
        {profileExist ? (
          <>
            <Row className="my-3">
              <Col>
                <h2 className="my-5 text-center">
                  {userProfile?.profileAttributes?.userName}
                </h2>
                <h3 className="my-5 text-center">{userProfile?.profileName}</h3>
              </Col>
            </Row>
            <Row className="my-3">
              <Col>
                <Suspense fallback={<div>Loading...</div>}>
                  <TalentProfilePublicDetail profile={userProfile} />
                </Suspense>
              </Col>
            </Row>
          </>
        ) : (
          <ProfilesNotExistAlert />
        )}
      </Container>
      <Switch>
        <Route path={`${url}/:user/:profile`} />
      </Switch>
    </>
  );
}

export default TalentPublicProfilePage;
