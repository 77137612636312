import React, {useState, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Container, Row, Col} from 'react-bootstrap';
import axios from 'axios';
import {useRecoilState} from 'recoil';
import {userSettings, companyJobListState} from '../store/atoms';
import JobCards from './JobList';

function CompanyFindTalents() {
  const [userInfo, setUserInfo] = useRecoilState(userSettings);
  const [companyJobList, setCompanyJobList] =
    useRecoilState(companyJobListState);
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    async function fetchJobsList(companyKey) {
      try {
        setLoading(true);
        const response = await axios.get(
          `${API_SERVER_URL}/api/company/job/findByCompanyKey/${companyKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          setCompanyJobList(response.data);
        }
      } catch (error) {
        console.error('CompanyFindTalents found jobs error - ', error);
      }
      setLoading(false);
    }

    async function fetchCompanyInfo() {
      try {
        const findCompanyUserResponse = await axios.get(
          `${API_SERVER_URL}/api/company/user/findByUserKey/${userInfo.userKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (findCompanyUserResponse.status === 200) {
          const {companyKey} = findCompanyUserResponse.data;
          if (companyKey) {
            try {
              const findCompanyResponse = await axios.get(
                `${API_SERVER_URL}/api/company/findByEntityKey/${companyKey}`,
                {headers: {Authorization: `Bearer ${userInfo.token}`}}
              );
              if (findCompanyResponse.status === 200) {
                localStorage.setItem('companyKey', companyKey);
                const newUserInfo = {...userInfo};
                newUserInfo.companyKey = companyKey;
                setUserInfo(newUserInfo);
                if (
                  findCompanyUserResponse.data.companyUserAttributes.title &&
                  findCompanyUserResponse.data.companyUserAttributes.email &&
                  findCompanyUserResponse.data.companyUserAttributes.phoneNumber
                ) {
                  fetchJobsList(companyKey);
                } else {
                  history.push(`/company/user`);
                }
              }
            } catch (error) {
              history.push(`/company/create/info`);
            }
          } else {
            history.push(`/company/create/info`);
          }
        }
      } catch (error) {
        history.push(`/company/create/info`);
      }
    }

    fetchCompanyInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="shadow rounded">
      <Row className="mt-3">
        <Col>
          <h3>Hire Talents</h3>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <h6 className="mt-3">
            <Link to="/company/create/job">Post </Link>| Posted Jobs
          </h6>
          {loading && <h4>Loading...</h4>}
          {companyJobList && <JobCards jobList={companyJobList} />}
        </Col>
      </Row>
    </Container>
  );
}

export default CompanyFindTalents;
