import React from 'react';
import {Container} from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';
import TalentSearch from './TalentSearch';
import TalentFindCompanies from './TalentFindCompanies';
import TalentFindCompanyJobs from './TalentFindCompanyJobs';
import TalentFindJobs from './TalentFindJobs';
import TalentInfo from './TalentInfo';
import TalentProfiles from './TalentProfiles';
import TalentLayoutHeader from '../layout/TalentLayoutHeader';
import TalentFavoriteCompany from './TalentFavoriteCompany';
import TalentFavoriteJob from './TalentFavoriteJob';
import TalentJobApplyHistory from './TalentJobApplyHistory';
import TalentCompanyApplyHistory from './TalentCompanyApplyHistory';
import TalentEquityHeld from './TalentEquityHeld';
import AccountInfoManagement from '../common/AccountInfoManagement';
import MessageInbox from '../common/message/MessageInbox';
import {MessageSent} from '../common/message/MessageSent';
import MessageDraft from '../common/message/MessageDraft';
import UserFeedbackInbox from '../common/feedback/UserFeedbackInbox';
import UserFeedbackSent from '../common/feedback/UserFeedbackSent';
import UserFeedbackDraft from '../common/feedback/UserFeedbackDraft';
import StartMedia from '../media';
import StartVideo from '../media/video';

function TalentMain() {
  const {path} = useRouteMatch();
  return (
    <>
      <TalentLayoutHeader />
      <Container>
        <Router>
          <Switch>
            <Route exact path={path}>
              <h3 className="text-center">Talent Home</h3>
            </Route>
            <Route exact path={`${path}/search`} component={TalentSearch} />
            <Route exact path={`${path}/jobs`} component={TalentFindJobs} />
            <Route
              exact
              path={`${path}/favorite/job`}
              component={TalentFavoriteJob}
            />
            <Route
              exact
              path={`${path}/history/job`}
              component={TalentJobApplyHistory}
            />
            <Route
              exact
              path={`${path}/companies`}
              component={TalentFindCompanies}
            />
            <Route
              path={`${path}/company/jobs/:companyKey`}
              component={TalentFindCompanyJobs}
            />
            <Route
              exact
              path={`${path}/favorite/company`}
              component={TalentFavoriteCompany}
            />
            <Route
              exact
              path={`${path}/history/company`}
              component={TalentCompanyApplyHistory}
            />
            <Route
              exact
              path={`${path}/equity/held`}
              component={TalentEquityHeld}
            />
            <Route
              exact
              path={`${path}/account`}
              component={AccountInfoManagement}
            />
            <Route path={`${path}/profiles`} component={TalentProfiles} />
            <Route exact path={`${path}/info`} component={TalentInfo} />
            <Route
              exact
              path={`${path}/message/inbox`}
              component={MessageInbox}
            />
            <Route
              exact
              path={`${path}/message/sent`}
              component={MessageSent}
            />
            <Route
              exact
              path={`${path}/message/draft`}
              component={MessageDraft}
            />
            <Route
              exact
              path={`${path}/user-feedback/inbox`}
              component={UserFeedbackInbox}
            />
            <Route
              exact
              path={`${path}/user-feedback/sent`}
              component={UserFeedbackSent}
            />
            <Route
              exact
              path={`${path}/user-feedback/draft`}
              component={UserFeedbackDraft}
            />
            <Route exact path={`${path}/startmedia`} component={StartMedia} />
            <Route
              path={`${path}/startmedia/video/:videoKey`}
              component={StartVideo}
            />
          </Switch>
        </Router>
      </Container>
    </>
  );
}

export default TalentMain;
