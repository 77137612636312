import React from 'react';
import PropTypes from 'prop-types';
import MainHeaderNavBar from './MainHeaderNavBar';
import MainFooter from './MainFooter';

function MainLayout({children}) {
  return (
    <>
      <MainHeaderNavBar />
      {children}
      <MainFooter />
    </>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
