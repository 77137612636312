import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Container, Row, Col, Alert} from 'react-bootstrap';
import {useRecoilValue} from 'recoil';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {userSettings} from '../store/atoms';

function AlertDismissibleText() {
  const [show, setShow] = useState(true);
  if (show) {
    return (
      <Alert variant="success" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Welcome</Alert.Heading>
        <p>Please provide more info.</p>
      </Alert>
    );
  }

  return null;
}

function InvestorUserInfo() {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const [investorUserExist, setInvestorUserExist] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [openToPublic, setOpenToPublic] = useState(false);
  const [isTagShow, setIsTagShow] = useState(true);

  const InvestorAttributes = [
    'title',
    'email',
    'interest',
    'linkedInLink',
    'resumeLink',
    'videoLink',
  ];

  const getUserProfileURI = () => {
    const userName = `${userInfo.firstName} ${userInfo.lastName}`;
    const profileLinkWords =
      userName.length >= 2 ? userName[0] + userName[1][0] : 'investorprofile';
    const baseUrl = `${window.location.href.substring(
      0,
      window.location.href.indexOf('/#/') + 3
    )}public/profile/investor/${profileLinkWords}/${userInfo.userKey}`;
    return baseUrl;
  };

  const validationSchema = yup.object().shape({
    title: yup
      .string()
      .required('Job Title is required')
      .max(128, 'title has a maximum limit of 128 characters.'),
    email: yup.string().required('Work Email is required').email(),
    interest: yup
      .string()
      .required('Industries of interest is required')
      .max(
        512,
        'Industries of interest has a maximum limit of 512 characters.'
      ),
    linkedInLink: yup.string().url('Please enter correct url!'),
    resumeLink: yup.string().url('Please enter correct url!'),
    videoLink: yup.string().url('Please enter correct url!'),
    otherLinks: yup.array().of(
      yup.object().shape({
        linkName: yup.string().required('Link name is required'),
        linkUrl: yup
          .string()
          .url('Please enter correct url!')
          .required('Link url is required'),
      })
    ),
    additionalInfo: yup.array().of(
      yup.object().shape({
        infoName: yup.string().required('Additional info title is required'),
        infoBody: yup.string().required('Additional info body is required'),
      })
    ),
  });

  const investorAttributesDefaultValues = {
    isAccredited: false,
    email: userInfo.emailAddress,
  };

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: investorAttributesDefaultValues,
  };

  const {
    formState: {errors, isSubmitting},
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm(formOptions);

  useEffect(() => {
    async function fetchInvestorUser() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/investor/user/findByUserKey/${userInfo.userKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          if (response.data?.investorAttributes.isPublic) {
            setOpenToPublic(
              response.data.investorAttributes.isPublic === 'true'
            );
          }
          setPhoneNumber(response.data.investorAttributes.phoneNumber);
          InvestorAttributes.forEach((element) => {
            setValue(element, response.data.investorAttributes[element]);
          });
          if (response.data.investorAttributes.isAccredited === 'true') {
            setValue('isAccredited', true);
          } else {
            setValue('isAccredited', false);
          }
          const otherLinksArray = response.data?.investorAttributes?.otherLinks
            ? JSON.parse(response.data.investorAttributes.otherLinks)
            : [];
          const additionalInfoArray = response.data?.investorAttributes
            ?.additionalInfo
            ? JSON.parse(response.data.investorAttributes.additionalInfo)
            : [];

          if (otherLinksArray.length > 0) {
            setValue('numberOfOtherLinks', otherLinksArray.length);
            setValue('otherLinks', otherLinksArray);
          }
          if (additionalInfoArray.length > 0) {
            setValue('numberOfAdditionalInfo', additionalInfoArray.length);
            setValue('additionalInfo', additionalInfoArray);
          }
          setInvestorUserExist(true);
          setIsError(false);
        }
      } catch (error) {
        setInvestorUserExist(false);
      }
    }

    if (userInfo.userKey) {
      fetchInvestorUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createInvestorUser = async (data) => {
    const investorUser = {
      userKey: userInfo.userKey,
      investorAttributes: {
        phoneNumber,
        isAccredited: data.isAccredited,
        isPublic: `${openToPublic}`,
        userName: `${userInfo.firstName} ${userInfo.lastName}`,
        otherLinks: data?.otherLinks
          ? JSON.stringify(data.otherLinks.slice(0, data.numberOfOtherLinks))
          : JSON.stringify([]),
        additionalInfo: data?.additionalInfo
          ? JSON.stringify(
              data.additionalInfo.slice(0, data.numberOfAdditionalInfo)
            )
          : JSON.stringify([]),
      },
    };
    InvestorAttributes.forEach((element) => {
      investorUser.investorAttributes[element] = data[element];
    });
    try {
      const response = await axios.post(
        `${API_SERVER_URL}/api/investor/user/create`,
        investorUser,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        setIsSuccess(true);
        setInvestorUserExist(true);
      }
    } catch (error) {
      setIsSuccess(false);
    }
  };

  const updateInvestorUser = async (data) => {
    const investorUser = {
      investorAttributes: {
        phoneNumber,
        isAccredited: data.isAccredited,
        isPublic: `${openToPublic}`,
        userName: `${userInfo.firstName} ${userInfo.lastName}`,
        otherLinks: data?.otherLinks
          ? JSON.stringify(data.otherLinks.slice(0, data.numberOfOtherLinks))
          : JSON.stringify([]),
        additionalInfo: data?.additionalInfo
          ? JSON.stringify(
              data.additionalInfo.slice(0, data.numberOfAdditionalInfo)
            )
          : JSON.stringify([]),
      },
    };
    InvestorAttributes.forEach((element) => {
      investorUser.investorAttributes[element] = data[element];
    });
    try {
      const response = await axios.put(
        `${API_SERVER_URL}/api/investor/user/updateByUserKey/${userInfo.userKey}`,
        investorUser,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (response.status === 200) {
        setIsSuccess(true);
      }
    } catch (error) {
      setIsSuccess(false);
    }
  };

  const onSubmit = (data) => {
    setIsError(false);
    setIsSuccess(false);
    setIsTagShow(false);
    return investorUserExist
      ? updateInvestorUser(data)
      : createInvestorUser(data);
  };

  const otherLinksCount = watch('numberOfOtherLinks');
  const additionalInfoCount = watch('numberOfAdditionalInfo');

  function otherLinkNumbers() {
    return [...Array(parseInt(otherLinksCount || 0, 10)).keys()];
  }

  function additionalInfoNumbers() {
    return [...Array(parseInt(additionalInfoCount || 0, 10)).keys()];
  }

  return (
    <Container className="shadow rounded">
      <Row>
        <Col>
          <form onSubmit={handleSubmit(onSubmit)}>
            {isTagShow && <AlertDismissibleText />}
            <div className="my-4">
              {isError && !isSuccess && (
                <small className="mt-3 d-inline-block text-danger">
                  Something went wrong. Please try again later.
                </small>
              )}
            </div>
            <div className="form-group my-3">
              <div className="form-check">
                <input
                  name="openToPublicCheck"
                  className="form-check-input"
                  type="checkbox"
                  checked={openToPublic}
                  onChange={() => setOpenToPublic(!openToPublic)}
                />
                <label className="form-check-label" htmlFor="openToPublicCheck">
                  Open to Public
                </label>
              </div>
            </div>
            {openToPublic && (
              <h6>
                <a
                  href={getUserProfileURI()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bi bi-link-45deg mr-1" />
                  Public Profile Link
                </a>
              </h6>
            )}

            <div className="my-4">
              <label className="form-label">
                Job Title<abbr className="text-danger">*</abbr>
              </label>
              <input
                type="text"
                {...register('title')}
                className={`form-control ${errors.title ? 'is-invalid' : ''}`}
                id="userInputJobTitle"
              />
              <div className="invalid-feedback">{errors.title?.message}</div>
            </div>
            <div className="my-4">
              <label className="form-label">
                Work Email<abbr className="text-danger">*</abbr>
              </label>
              <input
                type="text"
                {...register('email')}
                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                id="userInputEmail"
              />
              <div className="invalid-feedback">{errors.email?.message}</div>
            </div>
            <div className="my-4 pt-3">
              <label className="form-label">Work Phone</label>
              <br />
              <PhoneInput
                country="us"
                value={phoneNumber}
                inputProps={{
                  required: true,
                }}
                onChange={(phone) => setPhoneNumber(phone)}
              />
            </div>
            <div className="my-4 pt-3">
              <div className="form-group form-check">
                <input
                  name="isAccredited"
                  type="checkbox"
                  {...register('isAccredited')}
                  id="isAccredited"
                  className={`form-check-input ${
                    errors.isAccredited ? 'is-invalid' : ''
                  }`}
                />
                <label htmlFor="isAccredited" className="form-check-label">
                  is Accredited.
                </label>
                <div className="invalid-feedback">
                  {errors.isAccredited?.message}
                </div>
              </div>
            </div>
            <div className="my-4">
              <label className="form-label">
                Industries of interest<abbr className="text-danger">*</abbr>
              </label>
              <input
                type="text"
                {...register('interest')}
                className={`form-control ${
                  errors.interest ? 'is-invalid' : ''
                }`}
                id="userInputInterest"
              />
              <div className="invalid-feedback">{errors.interest?.message}</div>
            </div>
            <div className="form-group my-3 ">
              <label
                htmlFor="userInputPersonalWebsiteUrl"
                className="col-form-label"
              >
                <i className="bi bi-linkedin mr-1" />
                LinkedIn Profile Link
              </label>
              <input
                type="text"
                {...register('linkedInLink')}
                className={`form-control ${
                  errors.linkedInLink ? 'is-invalid' : ''
                }`}
                id="userInputPersonalWebsiteUrl"
              />
              <div className="invalid-feedback">
                {errors.linkedInLink?.message}
              </div>
            </div>
            <div className="form-group my-3">
              <label
                htmlFor="userInputPersonalResumeUrl"
                className="col-form-label"
              >
                <i className="bi bi-file-earmark-person mr-1" />
                Personal Profile Link
              </label>
              <input
                type="text"
                {...register('resumeLink')}
                className={`form-control ${
                  errors.resumeLink ? 'is-invalid' : ''
                }`}
                id="userInputPersonalResumeUrl"
              />
              <div className="invalid-feedback">
                {errors.resumeLink?.message}
              </div>
            </div>
            <div className="form-group my-3">
              <label
                htmlFor="userInputPersonalVideoUrl"
                className="col-form-label"
              >
                <i className="bi bi-file-play-fill mr-1" />
                Video Link
              </label>
              <input
                type="text"
                {...register('videoLink')}
                className={`form-control ${
                  errors.videoLink ? 'is-invalid' : ''
                }`}
                id="userInputPersonalVideoUrl"
              />
              <div className="invalid-feedback">
                {errors.videoLink?.message}
              </div>
            </div>
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <div className="form-row">
                  <div className="mr-2">
                    <label
                      htmlFor="userInputNumberOfOtherLinks"
                      className="col-form-label text-primary"
                    >
                      <i className="bi bi-paperclip mr-1" />
                      Another links(maximum 3)
                    </label>
                  </div>
                  <div className="">
                    <select
                      name="numberOfOtherLinks"
                      {...register('numberOfOtherLinks')}
                      className={`form-control ${
                        errors.numberOfOtherLinks ? 'is-invalid' : ''
                      }`}
                    >
                      {['', 1, 2, 3].map((i) => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {otherLinkNumbers().map((i) => (
              <div
                key={i}
                className="list-group list-group-flush shadow rounded p-4 bg-light"
              >
                <div className="list-group-item">
                  <div className="form-row">
                    <div className="form-group col-3">
                      <label>Name</label>
                      <input
                        name={`otherLinks[${i}]linkName`}
                        {...register(`otherLinks.[${i}].linkName`)}
                        type="text"
                        className={`form-control ${
                          errors.otherLinks?.[i]?.linkName ? 'is-invalid' : ''
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.otherLinks?.[i]?.linkName?.message}
                      </div>
                    </div>
                    <div className="form-group col-9">
                      <label>Link</label>
                      <input
                        name={`otherLinks[${i}]linkUrl`}
                        {...register(`otherLinks.[${i}].linkUrl`)}
                        type="text"
                        className={`form-control ${
                          errors.otherLinks?.[i]?.linkUrl ? 'is-invalid' : ''
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.otherLinks?.[i]?.linkUrl?.message}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <div className="form-row">
                  <div className="mr-2">
                    <label
                      htmlFor="userInputNumberOfAdditionalInfo"
                      className="col-form-label text-primary"
                    >
                      <i className="bi bi-info-circle mr-1" />
                      Additional Information(maximum 3)
                    </label>
                  </div>
                  <div className="">
                    <select
                      name="numberOfAdditionalInfo"
                      {...register('numberOfAdditionalInfo')}
                      className={`form-control ${
                        errors.numberOfAdditionalInfo ? 'is-invalid' : ''
                      }`}
                    >
                      {['', 1, 2, 3].map((i) => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {additionalInfoNumbers().map((i) => (
              <div
                key={i}
                className="list-group list-group-flush shadow rounded p-4 bg-light"
              >
                <div className="list-group-item">
                  <div className="form-row">
                    <div className="form-group col-3">
                      <label>Title</label>
                      <input
                        name={`additionalInfo[${i}]infoName`}
                        {...register(`additionalInfo.[${i}].infoName`)}
                        type="text"
                        className={`form-control ${
                          errors.additionalInfo?.[i]?.infoName
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.additionalInfo?.[i]?.infoName?.message}
                      </div>
                    </div>
                    <div className="form-group col-9">
                      <label>Text</label>
                      <input
                        name={`additionalInfo[${i}]infoBody`}
                        {...register(`additionalInfo.[${i}].infoBody`)}
                        type="text"
                        className={`form-control ${
                          errors.additionalInfo?.[i]?.infoBody
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.additionalInfo?.[i]?.infoBody?.message}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="my-5">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary px-5 mr-3"
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-1" />
                )}
                Update
              </button>
              {isSuccess && (
                <small className="mt-3 d-inline-block text-success">
                  Submit success!
                </small>
              )}
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default InvestorUserInfo;
