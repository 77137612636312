import React, {useEffect, useState} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import axios from 'axios';
import {useRecoilValue} from 'recoil';
import {useLocation} from 'react-router-dom';
import {userSettings} from '../store/atoms';
import CompanyPresentationLinks from './CompanyPresentationLinks';
import CompanyPresentationLinksInstaIntro from './CompanyPresentationLinksInstaIntro';
import CompanyPresentationPreviewCard from '../common/CompanyPresentationPreviewCard';

function CompanyPresentation() {
  const userInfo = useRecoilValue(userSettings);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const mediaType = query.get('type');

  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const [presentationInfo, setPresentationInfo] = useState({});
  const [presentationExist, setPresentationExist] = useState(false);

  useEffect(() => {
    async function fetchCompanyPresentation() {
      try {
        const findPresentationResponse = await axios.get(
          `${API_SERVER_URL}/api/company/presentation/findByCompanyKey/${userInfo.companyKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (findPresentationResponse.status === 200) {
          setPresentationExist(true);
          setPresentationInfo(findPresentationResponse.data[0]);
          localStorage.setItem(
            'presentationID',
            findPresentationResponse.data.entityKey
          );
        }
      } catch (error) {
        setPresentationExist(false);
      }
    }

    if (userInfo.companyKey) {
      fetchCompanyPresentation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!presentationExist) {
    return (
      <Container className="shadow rounded">
        <Row>
          <Col>
            <CompanyPresentationLinks
              linkKey="linkPreview"
              publishEnable={false}
            />
          </Col>
        </Row>
        <Row className="py-5 m-5">
          <Col>
            <h2>No content yet.</h2>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="shadow rounded mb-5">
      <Row>
        <Col>
          {mediaType === 'image' ? (
            <CompanyPresentationLinksInstaIntro
              linkKey="linkPreview"
              publishEnable={presentationExist}
            />
          ) : (
            <CompanyPresentationLinks
              linkKey="linkPreview"
              publishEnable={presentationExist}
            />
          )}
        </Col>
      </Row>
      {presentationExist && (
        <CompanyPresentationPreviewCard
          presentation={presentationInfo}
          type={mediaType}
        />
      )}
    </Container>
  );
}

export default CompanyPresentation;
