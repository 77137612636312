import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Col, ButtonGroup, Button, Card} from 'react-bootstrap';
import {useRecoilState, useRecoilValue} from 'recoil';
import axios from 'axios';
import {
  userSettings,
  talentFavoriteCompanyListState,
  talentCompanyInfoListState,
} from '../store/atoms';
import CompanyFavoriteInformationCard from '../common/CompanyInformationCard';
import JobListModal from '../common/message/JobListModal';
import {FavMessageModal} from '../common/message/FavMessageModal';

function CompanyFavoriteCard({companyInfo, onAddFavorite, onDeleteFavorite}) {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const [isFavorite, setIsFavorite] = useState(false);
  const talentFavoriteCompanyList = useRecoilValue(
    talentFavoriteCompanyListState
  );
  const [talentCompanyInfoList, setTalentCompanyInfoList] = useRecoilState(
    talentCompanyInfoListState
  );
  const [showModal, setShowModal] = useState(false);
  const [companyDetail, setCompanyDetail] = useState({});
  const has = Object.prototype.hasOwnProperty;
  const [jobList, setJobList] = useState([]);
  const [showJobListModal, setShowJobListModal] = useState(false);

  useEffect(() => {
    if (talentFavoriteCompanyList && talentFavoriteCompanyList.length > 0) {
      talentFavoriteCompanyList.forEach((item) => {
        if (item.companyKey === companyInfo.companyKey) {
          setIsFavorite(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchCompanyInfo() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/company/findByEntityKey/${companyInfo.companyKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          setCompanyDetail(response.data);
          const companyIndex = talentCompanyInfoList.findIndex(
            (listItem) => listItem.entityKey === companyInfo.companyKey
          );
          if (companyIndex < 0) {
            setTalentCompanyInfoList((oldTalentCompanyInfoList) => [
              ...oldTalentCompanyInfoList,
              response.data,
            ]);
          }

          try {
            const response1 = await axios.get(
              `${API_SERVER_URL}/api/company/job/findByUserKey/${companyInfo.companyUserKey}`,
              {headers: {Authorization: `Bearer ${userInfo.token}`}}
            );
            if (response1.status === 200) {
              setJobList(response1.data);
            }
          } catch (error) {
            console.error('Company job list error - ', error);
          }
        }
      } catch (error) {
        console.error('Company job list info error - ', error);
      }
    }

    if (has.call(companyInfo, 'companyAttributes')) {
      setCompanyDetail(JSON.parse(JSON.stringify(companyInfo)));
    } else if (talentCompanyInfoList.length > 0) {
      const companyIndex = talentCompanyInfoList.findIndex(
        (listItem) => listItem.entityKey === companyInfo.companyKey
      );
      if (companyIndex >= 0) {
        setCompanyDetail(
          JSON.parse(JSON.stringify(talentCompanyInfoList[companyIndex]))
        );
      } else {
        fetchCompanyInfo();
      }
    } else {
      fetchCompanyInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInfo.companyKey]);

  return (
    <Col sm={12} md={6} xl={4} className="my-2">
      <Card bg="light" text="dark" className="text-center">
        <CompanyFavoriteInformationCard
          companyInfo={companyDetail}
          favoriteInfo={companyInfo}
        />
        {/* </Card.Body> */}
        <Card.Footer className="bg-light text-right">
          <ButtonGroup>
            {isFavorite ? (
              <Button
                variant="light"
                onClick={() => onDeleteFavorite(`${companyInfo.entityKey}`)}
              >
                <i className="bi bi-heart-fill" style={{color: 'green'}} />
              </Button>
            ) : (
              <Button
                variant="light"
                onClick={() => onAddFavorite(`${companyInfo.companyKey}`)}
              >
                <i className="bi bi-heart" />
              </Button>
            )}
            <Button variant="light" onClick={() => setShowModal(!showModal)}>
              <i className="bi bi-chat-square-dots" />
            </Button>
            {showModal && (
              <FavMessageModal
                showModal={showModal}
                setShowModal={setShowModal}
                item={companyInfo}
                toType="COMPANY"
              />
            )}
            <Button
              variant="light"
              onClick={() => setShowJobListModal(!showJobListModal)}
            >
              <i className="bi bi-card-list" />
            </Button>
            {showJobListModal && (
              <JobListModal
                showModal={showJobListModal}
                setShowModal={setShowJobListModal}
                item={jobList}
              />
            )}
          </ButtonGroup>
        </Card.Footer>
      </Card>
    </Col>
  );
}

CompanyFavoriteCard.propTypes = {
  companyInfo: PropTypes.object.isRequired,
  onAddFavorite: PropTypes.func.isRequired,
  onDeleteFavorite: PropTypes.func.isRequired,
};

export default CompanyFavoriteCard;
