import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useRecoilState, useRecoilValue} from 'recoil';
import axios from 'axios';
import {userSettings, talentCompanyInfoListState} from '../store/atoms';
import {CompanyInformationCard} from './CompanyInformationCard';

function CompanyInformationCardModal({companyKey}) {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const [talentCompanyInfoList, setTalentCompanyInfoList] = useRecoilState(
    talentCompanyInfoListState
  );
  const [companyDetail, setCompanyDetail] = useState({});

  useEffect(() => {
    async function fetchCompanyInfo() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/company/findByEntityKey/${companyKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          setCompanyDetail(response.data);
          const companyIndex = talentCompanyInfoList.findIndex(
            (listItem) => listItem.entityKey === companyKey
          );
          if (companyIndex < 0) {
            setTalentCompanyInfoList((oldTalentCompanyInfoList) => [
              ...oldTalentCompanyInfoList,
              response.data,
            ]);
          }
        }
      } catch (error) {
        console.error(
          'CompanyInformationCardModal fetch company info error - ',
          error
        );
      }
    }

    if (talentCompanyInfoList.length > 0) {
      const companyIndex = talentCompanyInfoList.findIndex(
        (listItem) => listItem.entityKey === companyKey
      );
      if (companyIndex >= 0) {
        setCompanyDetail(
          JSON.parse(JSON.stringify(talentCompanyInfoList[companyIndex]))
        );
      } else {
        fetchCompanyInfo();
      }
    } else {
      fetchCompanyInfo();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyKey]);

  return <CompanyInformationCard companyInfo={companyDetail} />;
}

CompanyInformationCardModal.propTypes = {
  companyKey: PropTypes.string.isRequired,
};

export default CompanyInformationCardModal;
