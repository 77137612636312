import React from 'react';
import PropTypes from 'prop-types';
import SinglePageHeader from './SinglePageHeader';
import MainFooter from './MainFooter';

function SinglePageLayout({children}) {
  return (
    <>
      <SinglePageHeader />
      {children}
      <MainFooter />
    </>
  );
}

SinglePageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SinglePageLayout;
