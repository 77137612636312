import React from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col} from 'react-bootstrap';
import ReactPlayer from 'react-player';

function CompanyPresentationCard({presentationInfo}) {
  return (
    <Container className="shadow rounded mb-5">
      {presentationInfo.presentationAttributes
        ?.clientIntro_videoPresentationLink && (
        <>
          <Row className="my-3">
            <Col>
              <div className="my-3 text-center">
                <h2>Present: Use Your Content</h2>
              </div>
            </Col>
          </Row>
          <Row className="my-3">
            <Col className="">
              <h4 className="text-muted">ClientIntro Link</h4>
              <a
                href={
                  presentationInfo.presentationAttributes
                    .clientIntro_videoPresentationLink
                }
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-link-45deg mr-1" />
                {
                  presentationInfo.presentationAttributes
                    .clientIntro_videoPresentationLink
                }
              </a>
              <div>
                <ReactPlayer
                  url={
                    presentationInfo.presentationAttributes
                      .clientIntro_videoPresentationLink
                  }
                />
              </div>
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <h4 className="text-muted">
                What’s exciting about your company?
              </h4>
              <div>
                <p>
                  {
                    presentationInfo.presentationAttributes
                      ?.clientIntro_videoIntro
                  }
                </p>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

CompanyPresentationCard.propTypes = {
  presentationInfo: PropTypes.object.isRequired,
};

export default CompanyPresentationCard;
