import React, {useState} from 'react';
import {Alert} from 'react-bootstrap';
import PropTypes from 'prop-types';

function SystemAlert({info}) {
  const [show, setShow] = useState(info.show);
  const theme = info.status === 'success' ? 'success' : 'danger';

  if (!show) {
    return null;
  }

  return (
    <Alert variant={theme} onClose={() => setShow(false)} dismissible>
      <Alert.Heading>{info.title}</Alert.Heading>
      {info.body && <p>{info.body}</p>}
    </Alert>
  );
}

SystemAlert.propTypes = {
  info: PropTypes.object.isRequired,
};

export default SystemAlert;
