import React from 'react';

function LogoImage() {
  return (
    <>
      <img
        src="/images/startnation-logo-s.png"
        className="d-inline-block align-top img-fluid my-2"
        alt="Logo"
      />{' '}
    </>
  );
}

export default LogoImage;
