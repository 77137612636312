import React, {useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {useRecoilValue, useRecoilState} from 'recoil';
import axios from 'axios';
import {
  userSettings,
  talentFavoriteJobListState,
  talentApplicationListState,
} from '../store/atoms';
import JobFavoriteCard from './JobFavoriteCard';
import TalentMainJobLinks from './TalentMainJobLinks';

function TalentFavoriteJob() {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const [talentFavoriteJobList, setTalentFavoriteJobList] = useRecoilState(
    talentFavoriteJobListState
  );
  const [talentApplicationList, setTalentApplicationList] = useRecoilState(
    talentApplicationListState
  );

  useEffect(() => {
    async function fetchFavoriteList() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/favorite/job/findFavoriteDetailByUserKey/${userInfo.userKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setTalentFavoriteJobList(response.data);
        }
      } catch (error) {
        console.error(
          'TalentFavoriteJob found FavoriteList from remote error - ',
          error
        );
      }
    }
    fetchFavoriteList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentFavoriteJobList.length]);

  useEffect(() => {
    async function fetchTalentApplicationList() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/job/findAppliedJobWithTimeStampByUserKey/${userInfo.userKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setTalentApplicationList(response.data);
        }
      } catch (error) {
        console.error(
          'TalentFavoriteJob found ApplicationList from remote error - ',
          error
        );
      }
    }

    if (talentApplicationList.length === 0) {
      fetchTalentApplicationList();
    } else {
      console.log(
        'TalentFavoriteJob get talentApplicationListState - ',
        talentApplicationList.length
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentApplicationList]);

  const connectCompany = async (entityID) => {
    console.log('Talent click connect to company - ', entityID);
  };

  const addToFavorite = async (jobKey) => {
    const newFavorite = {
      userKey: userInfo.userKey,
      jobKey,
    };
    try {
      const response = await axios.post(
        `${API_SERVER_URL}/api/talent/favorite/job/addFavorite`,
        newFavorite,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        setTalentFavoriteJobList((oldFavoriteCompanyList) => [
          ...oldFavoriteCompanyList,
          response.data,
        ]);
      }
    } catch (error) {
      console.log('TalentFavoriteJob add new favorite cpmpany error -', error);
    }
  };

  const deleteFavorite = async (favoriteEntityKey) => {
    // console.log("Talent click delete the favorite - ", favoriteEntityKey);
    try {
      const response = await axios.delete(
        `${API_SERVER_URL}/api/talent/favorite/job/deleteFavoriteByEntityKey/${favoriteEntityKey}`,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (response.status === 200) {
        const favoriteIndex = talentFavoriteJobList.findIndex(
          (listItem) => listItem.entityKey === favoriteEntityKey
        );
        const newFavoriteList = [
          ...talentFavoriteJobList.slice(0, favoriteIndex),
          ...talentFavoriteJobList.slice(favoriteIndex + 1),
        ];
        setTalentFavoriteJobList(newFavoriteList);
      }
    } catch (error) {
      console.log('TalentFavoriteJob delete favorite company error -', error);
    }
  };

  return (
    <Container>
      <Row className="my-3">
        <Col>
          <TalentMainJobLinks linkKey="jobFavorited" />
        </Col>
      </Row>
      <Row className="mt-1">
        {/* {JSON.stringify(talentFavoriteJobList)} */}
        {talentFavoriteJobList &&
          talentFavoriteJobList.map((item) => (
            <JobFavoriteCard
              job={item}
              key={item.entityKey}
              onAddFavorite={addToFavorite}
              onDeleteFavorite={deleteFavorite}
              onConnectCompany={connectCompany}
            />
          ))}
      </Row>
    </Container>
  );
}

export default TalentFavoriteJob;
