import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {useRecoilValue} from 'recoil';
import {Row, Col, Card} from 'react-bootstrap';
import {userSettings} from '../../store/atoms';

function MessageDraft() {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const [messageList, setMessageList] = useState([]);

  useEffect(() => {
    setMessageList([]);
    async function fetchUnsentMessage() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/${userInfo.loggedInAsUserType.toLowerCase()}/message/send/findByUnsent/${
            userInfo.userKey
          }`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setMessageList(response.data);
        }
      } catch (error) {
        console.error('Failed to get draft messages - ', error);
      }
    }
    if (userInfo.userKey) {
      fetchUnsentMessage();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <p>Message Draft</p>
      <Row className="mt-1">
        {messageList &&
          messageList.map((item) => (
            <MessageCard
              key={item.entityKey}
              message={item}
              userInfo={userInfo}
              apiServer={API_SERVER_URL}
              messageList={messageList}
              setMessageList={setMessageList}
            />
          ))}
      </Row>
    </>
  );
}

function MessageCard({
  apiServer,
  message,
  messageList,
  setMessageList,
  userInfo,
}) {
  const [companyInfo, setCompanyInfo] = useState({});

  useEffect(() => {
    async function fetchCompanyInfo() {
      try {
        const response = await axios.get(
          `${apiServer}/api/company/user/findByUserKey/${message.toUserKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          try {
            const responseCompany = await axios.get(
              `${apiServer}/api/company/findByEntityKey/${response.data.companyKey}`,
              {headers: {Authorization: `Bearer ${userInfo.token}`}}
            );
            if (responseCompany.status === 200) {
              setCompanyInfo(responseCompany.data);
            }
          } catch (error) {
            console.error('Failed to get inbox messages - ', error);
          }
        }
      } catch (error) {
        console.error('Failed to get company info - ', error);
      }
    }
    if (userInfo.userKey) {
      fetchCompanyInfo();
    }
    // eslint-disable-next-line
  }, []);

  const handleDelete = () => {
    async function deleteMessage() {
      try {
        const response = await axios.delete(
          `${apiServer}/api/${userInfo.loggedInAsUserType.toLowerCase()}/message/send/deleteByEntityKey/${
            message.entityKey
          }`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setMessageList(
            messageList.filter((msg) => msg.entityKey !== message.entityKey)
          );
        }
      } catch (error) {
        console.error('Failed to get delete messages - ', error);
      }
    }
    if (userInfo.userKey) {
      deleteMessage();
    }
  };

  const handleSend = () => {
    async function sendWithDraft() {
      try {
        const sendResponse = await axios.get(
          `${apiServer}/api/${userInfo.loggedInAsUserType.toLowerCase()}/message/send/sendByEntityKey/${
            message.entityKey
          }`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (sendResponse.status === 200) {
          setMessageList(
            messageList.filter((msg) => msg.entityKey !== message.entityKey)
          );
        }
      } catch (error) {
        console.error('Failed to get sent messages - ', error);
      }
    }

    if (userInfo.userKey) {
      sendWithDraft();
    }
  };

  return (
    <Col md={12} className="my-3">
      <Card className="bg-light">
        {message.toUserType === 'COMPANY' &&
          !message.sentMessageAttributes?.jobTitle && (
            <Card.Header>Message To Company</Card.Header>
          )}
        {message.toUserType === 'COMPANY' &&
          message.sentMessageAttributes?.jobTitle && (
            <Card.Header>Message To Company For Job</Card.Header>
          )}
        {message.toUserType === 'TALENT' && (
          <Card.Header>Message To Job</Card.Header>
        )}
        <Card.Body>
          <dl className="row my-3">
            <dt className="col-sm-4 text-muted">Company Name:</dt>
            <dd className="col-sm-8">
              {' '}
              {companyInfo && companyInfo.companyName}
            </dd>

            {message.toUserType === 'COMPANY' &&
              message.sentMessageAttributes?.jobTitle && (
                <>
                  <dt className="col-sm-4 text-muted">Job Title:</dt>
                  <dd className="col-sm-8">
                    {message.sentMessageAttributes?.jobTitle}
                  </dd>
                </>
              )}

            {message.toUserType === 'TALENT' && (
              <>
                <dt className="col-sm-4 text-muted">Job Title:</dt>
                <dd className="col-sm-8">
                  {message.sentMessageAttributes?.jobTitle}
                </dd>
              </>
            )}

            <dt className="col-sm-4 text-muted">Subject:</dt>
            <dd className="col-sm-8">
              {message.sentMessageAttributes?.subject}
            </dd>

            <dt className="col-sm-4 text-muted">Message:</dt>
            <dd className="col-sm-8">{message.sentMessageAttributes?.body}</dd>
          </dl>
        </Card.Body>
        <Card.Footer>
          <button
            type="button"
            className="btn btn-md btn-outline-success rounded-pill"
            onClick={handleDelete}
          >
            Delete
          </button>
          <button
            type="button"
            className="btn btn-md btn-outline-success rounded-pill"
            onClick={handleSend}
            style={{margin: '0px 20px'}}
          >
            Send
          </button>
        </Card.Footer>
      </Card>
    </Col>
  );
}

MessageCard.propTypes = {
  message: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired,
  apiServer: PropTypes.string.isRequired,
  messageList: PropTypes.object.isRequired,
  setMessageList: PropTypes.func.isRequired,
};

export default MessageDraft;
