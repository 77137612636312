export function AddPreviewImages(posts) {
  let updatedPosts = [];
  const DefautBackgroudImageWithPlayButton = `/images/Defaut-image-with-play-button.png`;
  if (posts && posts.length > 0) {
    updatedPosts = posts.filter((present) => present?.presentationAttributes?.isPublic === true)
      .map(video => {
        const modifiedVideo = JSON.parse(JSON.stringify(video));
        const videoLink = modifiedVideo?.presentationAttributes?.clientIntro_videoPresentationLink || '';
        const hasYoutubeVideo = videoLink.includes('youtube.com');
        if (hasYoutubeVideo) {
          modifiedVideo.presentationAttributes.hasVideo = true;
          const hasCoverImage = modifiedVideo.presentationAttributes.coverURL || '';
          if (!hasCoverImage) {
            const videoIdStartPos = videoLink.lastIndexOf('?v=');
            const videoId = videoLink.substring(videoIdStartPos + 3);
            modifiedVideo.presentationAttributes.coverURL = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
          }
        }
        else if (videoLink) {
          modifiedVideo.presentationAttributes.hasVideo = true;
          modifiedVideo.presentationAttributes.coverURL = DefautBackgroudImageWithPlayButton;
        }
        else {
          modifiedVideo.presentationAttributes.hasVideo = false;
        }
        return modifiedVideo;
      });
  }
  return updatedPosts;
}

export function ValidateUrlExist(URL) {
  if (!URL) return false;
  const regEx =
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;
  return regEx.test(URL);
}
