import React, {useState, useEffect} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';

import PropTypes from 'prop-types';

function CompanyPresentationUpdateSavePublishedModal({
  handleSubmit,
  isPublic,
  setIsPublic,
  setShowModal,
  showModal,
}) {
  const handleClose = () => {
    setShowModal(false);
  };

  const [selectedOption, setSelectedOption] = useState(
    isPublic ? 'saveAndPublish' : 'unPublish'
  );

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);

    if (event?.target?.value === 'saveAndPublish') {
      setIsPublic(true);
    } else {
      setIsPublic(false);
    }
  };

  useEffect(() => {
    setSelectedOption(isPublic ? 'saveAndPublish' : 'unPublish');
  }, [isPublic]);

  useEffect(() => {
    if (selectedOption === 'saveAndPublish') {
      setIsPublic(true);
    } else {
      setIsPublic(false);
    }
  }, [selectedOption, setIsPublic]);

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      centered
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Please select one of the options to save your changes:
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Check
            type="radio"
            label="Save and publish changes immediately"
            name="saveOption"
            id="saveAndPublish"
            value="saveAndPublish"
            checked={selectedOption === 'saveAndPublish'}
            onChange={handleOptionChange}
          />
          <Form.Check
            type="radio"
            label="Un-publish published content and save changes for later editing only"
            name="saveOption"
            id="unPublish"
            value="unPublish"
            checked={selectedOption === 'unPublish'}
            onChange={handleOptionChange}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

CompanyPresentationUpdateSavePublishedModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isPublic: PropTypes.bool.isRequired,
  setIsPublic: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default CompanyPresentationUpdateSavePublishedModal;
