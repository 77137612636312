import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {Alert} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {useRecoilValue, useRecoilState} from 'recoil';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import {userSettings, companyJobListState} from '../store/atoms';

function AlertDismissibleText({jobTitle}) {
  const [show, setShow] = useState(true);
  if (show) {
    return (
      <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Delete Job</Alert.Heading>
        <p>Please input the job title({jobTitle}).</p>
      </Alert>
    );
  }

  return null;
}

function CompanyJobDelete({job, onCancelClick}) {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const [isError, setIsError] = useState(false);
  const [companyJobList, setCompanyJobList] =
    useRecoilState(companyJobListState);
  const userInfo = useRecoilValue(userSettings);
  const history = useHistory();

  const validationSchema = yup.object().shape({
    profileName: yup
      .string()
      .oneOf([job.jobTitle], 'Job Title must be matched'),
  });

  const {
    formState: {errors, isSubmitting},
    handleSubmit,
    register,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  async function onSubmit() {
    try {
      const response = await axios.delete(
        `${API_SERVER_URL}/api/company/job/deleteByEntityKey/${job.entityKey}`,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        const jobIndex = companyJobList.findIndex(
          (listItem) => listItem.entityKey === job.entityKey
        );
        const newCompanyJobList = [
          ...companyJobList.slice(0, jobIndex),
          ...companyJobList.slice(jobIndex + 1),
        ];
        setCompanyJobList(newCompanyJobList);
        history.push(`/company/talents/find`);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AlertDismissibleText jobTitle={job.jobTitle} />
      {isError && (
        <small className="mt-3 d-inline-block text-danger">
          Something went wrong. Please try again later.
        </small>
      )}
      <div className="form-row">
        <div className="form-group col-6">
          <label>Job Title</label>
          <input
            name="profileName"
            type="text"
            {...register('profileName')}
            className={`form-control ${errors.profileName ? 'is-invalid' : ''}`}
          />
          <div className="invalid-feedback">{errors.profileName?.message}</div>
        </div>
      </div>
      <div className="form-group">
        <button
          type="submit"
          disabled={isSubmitting}
          className="btn btn-primary"
        >
          {isSubmitting && (
            <span className="spinner-border spinner-border-sm mr-1" />
          )}
          Submit
        </button>
        <button
          type="button"
          className="btn btn-link ml-3"
          onClick={onCancelClick}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

AlertDismissibleText.propTypes = {
  jobTitle: PropTypes.string.isRequired,
};

CompanyJobDelete.propTypes = {
  job: PropTypes.object.isRequired,
  onCancelClick: PropTypes.func.isRequired,
};

export default CompanyJobDelete;
