import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Nav} from 'react-bootstrap';
import axios from 'axios';
import {useRecoilValue, useRecoilState} from 'recoil';
import {userSettings, companyJobSummaryListState} from '../store/atoms';

function CompanyInfoLinks({linkKey}) {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const [companyJobList, setCompanyJobList] = useRecoilState(
    companyJobSummaryListState
  );
  const userInfo = useRecoilValue(userSettings);

  useEffect(() => {
    async function fetchJobsSummaryList(companyKey) {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/company/job/findByCompanyKeyWithFilter?companyKey=${companyKey}&filter=title`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setCompanyJobList(response.data);
        }
      } catch (error) {
        console.error('CompanyInfoLinks get jobs summary list - ', error);
      }
    }

    if (companyJobList.length === 0) {
      if (userInfo.companyKey) {
        fetchJobsSummaryList(userInfo.companyKey);
      } else {
        const companyEntityKey = localStorage.getItem('companyKey');
        if (companyEntityKey) {
          fetchJobsSummaryList(companyEntityKey);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Nav variant="pills" activeKey={linkKey}>
      <Nav.Item>
        <Nav.Link eventKey="companyInfo" href="/company/create/info">
          Corporate Info
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="businessStatus" href="/company/create/equity">
          Business Status
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
}

CompanyInfoLinks.propTypes = {
  linkKey: PropTypes.string.isRequired,
};

export default CompanyInfoLinks;
