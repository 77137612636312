import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Modal, Form} from 'react-bootstrap';
import axios from 'axios';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {userSettings, systemAlertInfoState} from '../store/atoms';

export function FeedbackReplyModal({feedback, setShowModal, showModal}) {
  const handleClose = () => setShowModal(false);
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const [subject, setSubject] = useState(
    feedback.receivedFeedbackAttributes?.subject
  );
  const [messageContent, setMessageContent] = useState('');
  const setSystemAlertInfoState = useSetRecoilState(systemAlertInfoState);

  const handleSend = () => {
    async function sendFeedbackReply() {
      try {
        const request = {
          fromUserKey: userInfo.userKey,
          toUserKey: feedback.fromUserKey,
          toUserType: userInfo.loggedInAsUserType,
          sentFeedbackAttributes: {
            feedbackKey: feedback.receivedFeedbackAttributes?.feedbackKey,
            subject,
            body: messageContent,
            modifyTime: new Date(),
            userType: userInfo.loggedInAsUserType,
            userName: feedback.receivedFeedbackAttributes?.userName,
            userEmail: feedback.receivedFeedbackAttributes?.userEmail,
            originMsgSub: feedback.receivedFeedbackAttributes.originMsgSub,
            originMsgBody: feedback.receivedFeedbackAttributes.originMsgSub,
          },
        };
        const response = await axios.post(
          `${API_SERVER_URL}/api/${userInfo.loggedInAsUserType.toLowerCase()}/feedback/send/create`,
          request,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          try {
            const sendResponse = await axios.get(
              `${API_SERVER_URL}/api/${userInfo.loggedInAsUserType.toLowerCase()}/feedback/send/sendByEntityKey/${
                response.data.entityKey
              }`,
              {headers: {Authorization: `Bearer ${userInfo.token}`}}
            );
            if (sendResponse.status === 200) {
              setSystemAlertInfoState({
                show: 'true',
                status: 'success',
                title: 'Reply feedback success',
                body: '',
              });
            }
          } catch (error) {
            setSystemAlertInfoState({
              show: 'true',
              status: 'danger',
              title: 'Reply feedback failed, please try again!',
              body: '',
            });
          }
        }
      } catch (error) {
        setSystemAlertInfoState({
          show: 'true',
          status: 'danger',
          title: 'Reply feedback failed, please try again!',
          body: '',
        });
      }
    }
    if (userInfo.userKey) {
      sendFeedbackReply();
      setShowModal(false);
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Reply:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="validationCustom01">
          <Form.Label>Re:</Form.Label>
          <Form.Control
            type="textarea"
            value={subject}
            onChange={(event) => setSubject(event.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="validationCustom02">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={messageContent}
            onChange={(event) => setMessageContent(event.target.value)}
            required
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-md btn-outline-success rounded-pill"
          onClick={handleSend}
        >
          Send
        </button>
      </Modal.Footer>
    </Modal>
  );
}

FeedbackReplyModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  feedback: PropTypes.object.isRequired,
};

export default FeedbackReplyModal;
