import React, {useEffect, useState} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import uuid from 'react-uuid';
import {useRecoilValue} from 'recoil';
import axios from 'axios';
import Post from './post';
import {userSettings} from '../store/atoms';
import {AddPreviewImages} from './Utils/Helper';

function Media() {
  const userInfo = useRecoilValue(userSettings);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [posts, setPosts] = useState([]);
  const SEARCH_URL = `${process.env.REACT_APP_API_URL}/api/company/findCompanyPresentationsAndJobsByKeyWordList`;

  useEffect(() => {
    const keywords = [''];
    if (keywords.length > 0) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await axios.post(
            SEARCH_URL,
            {
              searchList: keywords,
            },
            {
              headers: {
                Authorization: `Bearer ${userInfo.token}`,
              },
            }
          );

          const presentationsData = [];
          response.data.forEach((item) => {
            presentationsData.push(...item.presentations);
          });
          setPosts(AddPreviewImages(presentationsData));
        } catch (err) {
          if (err.response && err.response.status === 404) {
            setPosts([]);
          } else {
            setError(err);
          }
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [SEARCH_URL, userInfo.token]);

  return (
    <Container className="">
      <Row>
        <Col md={12}>
          {error && (
            <div>
              <p>{error.message}</p>
            </div>
          )}
        </Col>
      </Row>

      {loading ? (
        <p>loading...</p>
      ) : (
        <InfiniteScroll
          dataLength={posts.length}
          hasMore
          Loader={
            <div className="spinner-border text-danger d-block mx-auto" />
          }
          className="videos_previews_container"
        >
          {posts.map((video) => (
            <Post video={video} key={uuid()} />
          ))}
        </InfiniteScroll>
      )}
    </Container>
  );
}

export default Media;
