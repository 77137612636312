import React from 'react';
import {createRoot} from 'react-dom/client';
import {RecoilRoot} from 'recoil';
import {Provider} from 'react-redux';
import MainRouter from './router/router';
import store from './store/index';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const container = document.getElementById('app');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <RecoilRoot>
      <MainRouter />
    </RecoilRoot>
  </Provider>
);
