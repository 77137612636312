import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, ButtonGroup, Button, Card} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {useRecoilValue, useRecoilState} from 'recoil';
import axios from 'axios';
import {
  userSettings,
  talentFavoriteCompanyListState,
  talentCompanyInfoListState,
} from '../store/atoms';
import {CompanyInformationCard} from '../common/CompanyInformationCard';
import {FindMessageModal} from '../common/message/FindMessageModal';

function CompanySearchCard({companyInfo, onAddFavorite, onDeleteFavorite}) {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteKey, setFavoriteKey] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [companyDetail, setCompanyDetail] = useState({});
  const talentFavoriteCompanyList = useRecoilValue(
    talentFavoriteCompanyListState
  );
  const [talentCompanyInfoList, setTalentCompanyInfoList] = useRecoilState(
    talentCompanyInfoListState
  );
  const has = Object.prototype.hasOwnProperty;

  useEffect(() => {
    if (talentFavoriteCompanyList && talentFavoriteCompanyList.length > 0) {
      talentFavoriteCompanyList.forEach((item) => {
        if (has.call(companyInfo, 'companyKey')) {
          if (item.companyKey === companyInfo.companyKey) {
            setIsFavorite(true);
            setFavoriteKey(item.entityKey);
          }
        } else if (has.call(companyInfo, 'entityKey')) {
          if (item.companyKey === companyInfo.entityKey) {
            setIsFavorite(true);
            setFavoriteKey(item.entityKey);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentFavoriteCompanyList]);

  useEffect(() => {
    async function fetchCompanyInfo() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/company/findByEntityKey/${companyInfo.companyKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          setCompanyDetail(response.data);
          const companyIndex = talentCompanyInfoList.findIndex(
            (listItem) => listItem.entityKey === companyInfo.companyKey
          );
          if (companyIndex < 0) {
            setTalentCompanyInfoList((oldTalentCompanyInfoList) => [
              ...oldTalentCompanyInfoList,
              response.data,
            ]);
          }
        }
      } catch (error) {
        console.error('CompanySearchCard fetch company info error - ', error);
      }
    }

    if (has.call(companyInfo, 'companyName')) {
      setCompanyDetail(JSON.parse(JSON.stringify(companyInfo)));
    } else {
      const companyIndex = talentCompanyInfoList.findIndex(
        (listItem) => listItem.entityKey === companyInfo.companyKey
      );
      if (companyIndex >= 0) {
        setCompanyDetail(
          JSON.parse(JSON.stringify(talentCompanyInfoList[companyIndex]))
        );
      } else {
        fetchCompanyInfo();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInfo.companyKey]);

  return (
    <Col sm={12} md={6} xl={4} className="my-2">
      <Card bg="light" text="dark" className="text-center">
        <CompanyInformationCard companyInfo={companyDetail} />
        <Card.Footer className="bg-light text-right">
          <Row>
            <Col md={6} className="text-left">
              {companyDetail.numJobsPosted > 0 && (
                <Link to={`/talent/company/jobs/${companyDetail?.entityKey}`}>
                  <Button variant="light">
                    <span className="badge badge-default">
                      <i className="bi bi-lightbulb mr-1" />
                      View job
                      {companyDetail?.numJobsPosted > 1 ? 's' : ''} (
                      {companyDetail?.numJobsPosted})
                    </span>
                  </Button>
                </Link>
              )}
            </Col>
            <Col md={6} className="text-right">
              <ButtonGroup>
                {isFavorite ? (
                  <Button
                    variant="light"
                    onClick={() => {
                      onDeleteFavorite(`${favoriteKey}`);
                      setIsFavorite(false);
                    }}
                  >
                    <i className="bi bi-heart-fill" style={{color: 'green'}} />
                  </Button>
                ) : (
                  <Button
                    variant="light"
                    onClick={() => onAddFavorite(`${companyDetail.entityKey}`)}
                  >
                    <i className="bi bi-heart" />
                  </Button>
                )}
                <Button
                  variant="light"
                  onClick={() => setShowModal(!showModal)}
                >
                  <i className="bi bi-chat-square-dots" />
                </Button>
                {showModal && (
                  <FindMessageModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    item={companyInfo}
                    toType="COMPANY"
                  />
                )}
              </ButtonGroup>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </Col>
  );
}

CompanySearchCard.propTypes = {
  companyInfo: PropTypes.object.isRequired,
  onAddFavorite: PropTypes.func.isRequired,
  onDeleteFavorite: PropTypes.func.isRequired,
};

export default CompanySearchCard;
