import React, {useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Container, Row, Col, Alert, Badge} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {useRecoilValue} from 'recoil';
import axios from 'axios';
import {userSettings} from '../store/atoms';
import CompanyPresentationLinks from './CompanyPresentationLinks';
import CompanyPresentationLinksInstaIntro from './CompanyPresentationLinksInstaIntro';

function AlertDismissibleText() {
  const [show, setShow] = useState(true);
  if (show) {
    return (
      <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Publish</Alert.Heading>
        <p>
          If you choose to publish, your company’s presentation info will be
          visible to our users and the public.
        </p>
      </Alert>
    );
  }

  return null;
}

function CompanyPresentationPublish() {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const userInfo = useRecoilValue(userSettings);
  const [presentationInfo, setPresentationInfo] = useState({});
  const [presentationExist, setPresentationExist] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const mediaType = query.get('type');
  const backButtonLink = `/company/presentation/preview?type=${mediaType}`;

  const getCompanyProfileURI = (companyName) => {
    const profileLinkWords =
      companyName.length >= 2
        ? companyName.replace(/\s+/g, '').toLowerCase().substr(0, 10)
        : 'companyprofile';
    const baseUrl = `${window.location.href.substring(
      0,
      window.location.href.indexOf('/company/')
    )}/public/profile/company/${profileLinkWords}/${userInfo.companyKey}`;
    return baseUrl;
  };

  const presentationAttributesDefaultValues = {
    acceptTerms: isPublic,
  };
  const formOptions = {defaultValues: presentationAttributesDefaultValues};

  const {formState, handleSubmit, register, setValue} = useForm(formOptions);

  useEffect(() => {
    async function fetchCompanyPresentation() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/company/presentation/findByCompanyKey/${userInfo.companyKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          if (response.data[0].presentationAttributes.isPublic === true) {
            setValue('acceptTerms', true);
            setIsPublic(true);
          } else {
            setValue('acceptTerms', false);
            setIsPublic(false);
          }
          setPresentationExist(true);
          setPresentationInfo(response.data[0]);
        }
      } catch (error) {
        setPresentationExist(false);
      }
    }
    if (userInfo.companyKey) {
      fetchCompanyPresentation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPublic]);

  const {errors, isSubmitting} = formState;

  async function onSubmit(data) {
    try {
      presentationInfo.presentationAttributes.isPublic = data.acceptTerms;

      const response = await axios.put(
        `${API_SERVER_URL}/api/company/presentation/updateByEntityKey/${presentationInfo.entityKey}`,
        presentationInfo,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (response.status === 200) {
        setIsSuccess(true);
        setIsPublic(data.acceptTerms);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsSuccess(false);
      setIsError(true);
    }
  }

  if (!presentationExist) {
    return (
      <Container className="shadow rounded">
        <Row>
          <Col>
            {mediaType === 'image' ? (
              <CompanyPresentationLinksInstaIntro
                linkKey="linkPublish"
                publishEnable={presentationExist}
              />
            ) : (
              <CompanyPresentationLinks
                linkKey="linkPublish"
                publishEnable={presentationExist}
              />
            )}
          </Col>
        </Row>
        <Row className="py-5 m-5">
          <Col>
            <h2>No content yet.</h2>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="ml-3 shadow rounded">
      <Row className="mb-3">
        <Col>
          {mediaType === 'image' ? (
            <CompanyPresentationLinksInstaIntro
              linkKey="linkPublish"
              publishEnable={presentationExist}
            />
          ) : (
            <CompanyPresentationLinks
              linkKey="linkPublish"
              publishEnable={presentationExist}
            />
          )}
        </Col>
      </Row>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mt-3">
          <Col>
            <AlertDismissibleText />
          </Col>
        </Row>

        <Row>
          <Col>
            {isError && (
              <small className="mt-3 d-inline-block text-danger">
                Something went wrong. Please try again later.
              </small>
            )}
            {isSuccess && isPublic && (
              <small className="mt-3 d-inline-block text-success">
                Publish success!
              </small>
            )}
            {isSuccess && !isPublic && (
              <small className="mt-3 d-inline-block text-warning">
                Update success!
              </small>
            )}
          </Col>
        </Row>

        {isPublic ? (
          <Row className="mt-3">
            <Col>
              <h4>
                <Badge pill variant="success">
                  Current status: Published
                </Badge>
              </h4>
              {presentationInfo.presentationAttributes?.companyName && (
                <h6>
                  <a
                    href={getCompanyProfileURI(
                      presentationInfo.presentationAttributes?.companyName
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="bi bi-link-45deg mr-1" />
                    Public Presentation Link
                  </a>
                </h6>
              )}
            </Col>
          </Row>
        ) : (
          <Row className="my-5">
            <Col>
              <h4>
                <Badge pill variant="warning">
                  Current status: Unpublished
                </Badge>
              </h4>
            </Col>
          </Row>
        )}

        <div className="form-row mt-5 mx-2">
          <div className="form-group form-check">
            <input
              name="acceptTerms"
              type="checkbox"
              {...register('acceptTerms')}
              id="acceptTerms"
              className={`form-check-input ${
                errors.acceptTerms ? 'is-invalid' : ''
              }`}
            />
            <label htmlFor="acceptTerms" className="form-check-label">
              Publish to public.
            </label>
            <div className="invalid-feedback">
              {errors.acceptTerms?.message}
            </div>
          </div>
        </div>
        <div className="form-group">
          <Link
            to={backButtonLink}
            className="mx-2 px-5 my-3 btn btn-outline-success rounded-pill"
          >
            Back
          </Link>
          <button
            type="submit"
            disabled={isSubmitting}
            className="btn btn-success px-5 my-3 rounded-pill"
          >
            {isSubmitting && (
              <span className="spinner-border spinner-border-sm mr-1" />
            )}
            Submit
          </button>
        </div>
      </form>
    </Container>
  );
}

export default CompanyPresentationPublish;
