import React from 'react';

function Vision() {
  return (
    <div className="container">
      <div className="row my-5">
        <div className="col">
          <h1 className="display-4 text-center">Our Vision</h1>
        </div>
      </div>
      <div className="row my-5">
        <div className="col">
          <blockquote className="blockquote">
            <p className="mb-0">
              At StartNation, we envision a future where businesses are built
              and shared with human capital and labor instead of venture capital.
            </p>
          </blockquote>
        </div>
      </div>

      <div className="row my-5">
        <div className="col">
          <blockquote className="blockquote">
            <p className="mb-0">
              StartNation strives to be the place where innovative early stage companies
              can grow their teams and businesses successfully without requiring significant
              investor or startup capital.
            </p>
          </blockquote>
        </div>
      </div>
    </div>
  );
}

export default Vision;
