import React, {useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {useRecoilValue, useRecoilState} from 'recoil';
import axios from 'axios';
import fp from 'lodash/fp';
import {userSettings, talentApplicationListState} from '../store/atoms';
import JobApplyHistoryCardCompany from './JobApplyHistoryCardCompany';
import TalentMainCompanyLinks from './TalentMainCompanyLinks';

function TalentCompanyApplyHistory() {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const [talentApplicationList, setTalentApplicationList] = useRecoilState(
    talentApplicationListState
  );

  useEffect(() => {
    async function fetchTalentApplicationList() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/job/findAppliedJobWithTimeStampByUserKey/${userInfo.userKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          const result = Object.keys(
            fp.groupBy('appliedJobAttributes.companyKey')(response.data)
          ).map((key) => [
            key,
            fp.groupBy('appliedJobAttributes.companyKey')(response.data)[key],
          ]);
          setTalentApplicationList(result);
        }
      } catch (error) {
        console.error(
          'TalentJobApplyHistory found ApplicationList from remote error - ',
          error
        );
      }
    }

    const talentApplyJob = localStorage.getItem('talentApplyJob');
    if (talentApplicationList.length === 0 || talentApplyJob === 'update') {
      localStorage.setItem('talentApplyJob', '');
      fetchTalentApplicationList();
    } else {
      console.log(
        'TalentJobApplyHistory get talentApplicationListState - ',
        talentApplicationList.length
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentApplicationList]);

  return (
    <Container>
      <Row className="my-3">
        <Col>
          <TalentMainCompanyLinks linkKey="companyApplied" />
        </Col>
      </Row>
      <Row className="mt-1">
        {talentApplicationList &&
          talentApplicationList.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <JobApplyHistoryCardCompany apply={item} key={index} />
          ))}
      </Row>
    </Container>
  );
}

export default TalentCompanyApplyHistory;
