/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CompanyPresentationCard from './CompanyPresentationCard';
import CompanyPresentationImageCard from './CompanyPresentationImageCard';

const StyledBorderWrapper = styled.div`
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  border-color: ${({active}) => (active === 'true' ? 'blue' : 'gray')};
  margin: 20px;
`;

function BorderWrapper({active, children}) {
  return <StyledBorderWrapper active={active}>{children}</StyledBorderWrapper>;
}

function CompanyPresentationPreviewCard({presentation, type}) {
  if (!presentation || !presentation?.presentationAttributes) return null;
  const {clientIntro_videoPresentationLink, startIntro_backgroundImage} =
    presentation?.presentationAttributes;
  return (
    <>
      {presentation &&
        type === 'video' &&
        clientIntro_videoPresentationLink && (
          <BorderWrapper active="true">
            <CompanyPresentationCard presentationInfo={presentation} />
          </BorderWrapper>
        )}
      {presentation && type === 'image' && startIntro_backgroundImage && (
        <BorderWrapper active="true">
          <CompanyPresentationImageCard presentationInfo={presentation} />
        </BorderWrapper>
      )}
      {presentation && !type && (
        <>
          {startIntro_backgroundImage && (
            <BorderWrapper active="false">
              <CompanyPresentationImageCard presentationInfo={presentation} />
            </BorderWrapper>
          )}
          {clientIntro_videoPresentationLink && (
            <BorderWrapper active="false">
              <CompanyPresentationCard presentationInfo={presentation} />
            </BorderWrapper>
          )}
        </>
      )}
    </>
  );
}

CompanyPresentationPreviewCard.propTypes = {
  presentation: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

BorderWrapper.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.object.isRequired,
};

export default CompanyPresentationPreviewCard;
