import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Col, ButtonGroup, Button, Card} from 'react-bootstrap';
import {useRecoilValue} from 'recoil';
import {investorFavoriteCompanyListState} from '../store/atoms';
import {CompanyInformationCard} from '../common/CompanyInformationCard';
import {FindMessageModal} from '../common/message/FindMessageModal';

function CompanySearchCard({companyInfo, onAddFavorite, onDeleteFavorite}) {
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteKey, setFavoriteKey] = useState('');
  const investorFavoriteCompanyList = useRecoilValue(
    investorFavoriteCompanyListState
  );
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (investorFavoriteCompanyList && investorFavoriteCompanyList.length > 0) {
      investorFavoriteCompanyList.forEach((item) => {
        if (item.companyKey === companyInfo.entityKey) {
          setIsFavorite(true);
          setFavoriteKey(item.entityKey);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investorFavoriteCompanyList]);

  return (
    <Col sm={12} md={6} xl={4} className="my-2 text-center">
      <Card className="bg-light">
        <CompanyInformationCard companyInfo={companyInfo} />
        <Card.Footer className="bg-light text-right">
          <ButtonGroup>
            {isFavorite ? (
              <Button
                variant="light"
                onClick={() => {
                  onDeleteFavorite(`${favoriteKey}`);
                  setIsFavorite(false);
                }}
              >
                <i className="bi bi-heart-fill" style={{color: 'green'}} />
              </Button>
            ) : (
              <Button
                variant="light"
                onClick={() => onAddFavorite(`${companyInfo.entityKey}`)}
              >
                <i className="bi bi-heart" />
              </Button>
            )}
            <Button variant="light" onClick={() => setShowModal(!showModal)}>
              <i className="bi bi-chat-square-dots" />
            </Button>
            {showModal && (
              <FindMessageModal
                showModal={showModal}
                setShowModal={setShowModal}
                item={companyInfo}
                toType="COMPANY"
              />
            )}
          </ButtonGroup>
        </Card.Footer>
      </Card>
    </Col>
  );
}

CompanySearchCard.propTypes = {
  companyInfo: PropTypes.object.isRequired,
  onAddFavorite: PropTypes.func.isRequired,
  onDeleteFavorite: PropTypes.func.isRequired,
};

export default CompanySearchCard;
