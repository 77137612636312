import React from 'react';
import axios from 'axios';
import {Container} from 'react-bootstrap';

class SwitchLoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (localStorage.getItem('authenticated') === 'true') {
      this.setState({authenticated: true});
      this.setState({userTypes: localStorage.getItem('userTypes')});
      if (localStorage.getItem('loggedInAsUserType') === 'COMPANY') {
        this.setState({authenticatedAsCompany: true});
      }
      if (localStorage.getItem('loggedInAsUserType') === 'INVESTOR') {
        this.setState({authenticatedAsInvestor: true});
      }
      if (localStorage.getItem('loggedInAsUserType') === 'TALENT') {
        this.setState({authenticatedAsTalent: true});
      }
    }
  }

  onChangeRadioValue = async (event) => {
    const currentTypeFromRadio = event.target.value;
    if (currentTypeFromRadio !== undefined) {
      this.setState({currentType: currentTypeFromRadio});
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const {currentType} = this.state;
    this.setState({});
    localStorage.setItem('loggedInAsUserType', currentType);

    const API_SERVER_URL = process.env.REACT_APP_API_URL;
    const entityKey = localStorage.getItem('entityKey');
    const token = localStorage.getItem('token');

    if (currentType !== undefined) {
      const user = {currentType};

      await Promise.all([
        axios.put(`${API_SERVER_URL}/api/user/updateByKey/${entityKey}`, user, {
          headers: {Authorization: `Bearer ${token}`},
        }),
      ]);

      if (currentType === 'TALENT') {
        window.location = '/talent/jobs';
        window.location.reload();
      } else if (currentType === 'INVESTOR') {
        window.location = '/investor/company/find';
        window.location.reload();
      } else if (currentType === 'COMPANY') {
        window.location = 'company/talents/find';
        window.location.reload();
      } else {
        window.location = '/';
      }
    }
  };

  render() {
    const {
      authenticated,
      authenticatedAsCompany,
      authenticatedAsInvestor,
      authenticatedAsTalent,
      userTypes,
    } = this.state;
    return (
      <Container className="py-5">
        <form onSubmit={this.handleSubmit}>
          <div>
            <h2>Switch Login</h2>
            <p>Use this page to switch login types</p>
          </div>

          <div>
            <p>
              <b>Types Available</b>
            </p>
          </div>

          <div onChange={this.onChangeRadioValue}>
            {authenticated === true ? (
              <span>
                {userTypes.includes('COMPANY') &&
                authenticatedAsCompany !== true ? (
                  <span className="mr-3">
                    <input type="radio" value="COMPANY" name="currentType" />{' '}
                    COMPANY
                  </span>
                ) : (
                  <span />
                )}
                {userTypes.includes('TALENT') &&
                authenticatedAsTalent !== true ? (
                  <span className="mr-3">
                    <input type="radio" value="TALENT" name="currentType" />{' '}
                    TALENT
                  </span>
                ) : (
                  <span />
                )}
                {userTypes.includes('INVESTOR') &&
                authenticatedAsInvestor !== true ? (
                  <span className="mr-3">
                    <input type="radio" value="INVESTOR" name="currentType" />{' '}
                    INVESTOR
                  </span>
                ) : (
                  <span />
                )}
              </span>
            ) : (
              <span />
            )}
          </div>

          <div>
            <p />
          </div>

          <button
            className="px-5 px-5 btn btn-warning rounded-pill"
            type="submit"
          >
            Switch!
          </button>
        </form>
      </Container>
    );
  }
}

export default SwitchLoginForm;
