import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import MainLayout from '../layout/MainLayout';
import SinglePageLayout from '../layout/SinglePageLayout';
import userTypes from '../store/constants';
import SignIn from '../common/SignIn';
import SignUp from '../common/SignUp';
import Terms from '../startout/Terms';
import Home from '../Home';
import About from '../startout/About';
import Vision from '../startout/Vision';
import Team from '../startout/Team';
import Contact from '../startout/Contact';
import Logout from '../common/LogoutForm';
import VerifyAccount from '../common/VerifyNewAccount';
import ResendVerifyCode from '../common/ResendVerifyCode';
import SwitchType from '../common/SwitchLoginForm';
import AddType from '../common/AddTypeForm';
import TalentMain from '../talent/TalentMain';
import TalentPublicProfilePage from '../talent/PublicProfile';
import InvestorPublicProfile from '../investor/InvestorPublicProfile';
import CompanyPublicProfile from '../company/CompanyPublicProfile';
import CompanyMain from '../company/CompanyMain';
import InvestorMain from '../investor/InvestorMain';
import checkTokenValid from '../common/CheckToken';
import ForgotPassword from '../common/ForgotPassword';
import ChangePassword from '../common/ChangePassword';

function MainLayoutRoute({auth, component: Component, ...rest}) {
  return auth ? (
    <Route
      {...rest}
      render={(matchProps) => (
        <MainLayout>
          <Component {...matchProps} />
        </MainLayout>
      )}
    />
  ) : null;
}

function SinglePageLayoutRoute({component: Component, ...rest}) {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <SinglePageLayout>
          <Component {...matchProps} />
        </SinglePageLayout>
      )}
    />
  );
}

function AuthRoute({auth, ...rest}) {
  return auth ? <Route {...rest} /> : null;
}

function RouteUserCheckPermission() {
  const authenticated = localStorage.getItem('authenticated');
  const loggedInAsUserType = localStorage.getItem('loggedInAsUserType');
  let userHasThePermission = false;
  let talentHasThePermission = false;
  let investorHasThePermission = false;
  let companyHasThePermission = false;
  if (authenticated && loggedInAsUserType) {
    userHasThePermission = true;
    if (loggedInAsUserType === userTypes.TALENT.value) {
      talentHasThePermission = true;
    } else if (loggedInAsUserType === userTypes.INVESTOR.value) {
      investorHasThePermission = true;
    } else if (loggedInAsUserType === userTypes.COMPANY.value) {
      companyHasThePermission = true;
    }
  }

  return (
    <>
      <MainLayoutRoute
        exact
        path="/switch"
        auth={userHasThePermission}
        component={SwitchType}
      />
      <MainLayoutRoute
        exact
        path="/addType"
        auth={userHasThePermission}
        component={AddType}
      />
      <MainLayoutRoute
        exact
        path="/logout"
        auth={userHasThePermission}
        component={Logout}
      />
      <AuthRoute
        path="/investor"
        auth={investorHasThePermission}
        component={InvestorMain}
      />
      <AuthRoute
        path="/talent"
        auth={talentHasThePermission}
        component={TalentMain}
      />
      <AuthRoute
        path="/company"
        auth={companyHasThePermission}
        component={CompanyMain}
      />
    </>
  );
}

// eslint-disable-next-line no-promise-executor-return
const loadingRequest = () => new Promise((resolve) => setTimeout(resolve, 500));

function MainRouter() {
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('token');

  useEffect(() => {
    // Home loading spinner
    loadingRequest().then(() => {
      const el = document.querySelector('.loader-container');
      if (el) {
        el.remove(); // removing the spinner element
        setLoading(false); // showing the app
      }
    });

    // console.log(window.location.href);
    if (token && !window.location.href.includes('/public/')) {
      checkTokenValid(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return null;
  }

  return (
    <Router>
      <Switch>
        <MainLayoutRoute exact auth path="/" component={Home} />
        <MainLayoutRoute exact auth path="/startout/about" component={About} />
        <MainLayoutRoute
          exact
          auth
          path="/startout/vision"
          component={Vision}
        />
        <MainLayoutRoute exact auth path="/startout/team" component={Team} />
        <MainLayoutRoute exact auth path="/startout/terms" component={Terms} />
        <MainLayoutRoute
          exact
          auth
          path="/startout/contact"
          component={Contact}
        />
        <MainLayoutRoute
          auth
          path="/public/profile/talent/:user/:profile"
          component={TalentPublicProfilePage}
        />
        <MainLayoutRoute
          auth
          path="/public/profile/investor/:user/:profile"
          component={InvestorPublicProfile}
        />
        <MainLayoutRoute
          auth
          path="/public/profile/company/:user/:profile"
          component={CompanyPublicProfile}
        />
        <SinglePageLayoutRoute exact path="/signin" component={SignIn} />
        <SinglePageLayoutRoute exact path="/signup" component={SignUp} />
        <SinglePageLayoutRoute
          exact
          path="/changePassword"
          component={ChangePassword}
        />
        <SinglePageLayoutRoute
          exact
          path="/forgot"
          component={ForgotPassword}
        />
        <SinglePageLayoutRoute
          exact
          path="/verifyAccount"
          component={VerifyAccount}
        />
        <SinglePageLayoutRoute
          exact
          path="/resendVerifyCode"
          component={ResendVerifyCode}
        />
        <RouteUserCheckPermission />
      </Switch>
    </Router>
  );
}

MainLayoutRoute.propTypes = {
  auth: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
};

SinglePageLayoutRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

AuthRoute.propTypes = {
  auth: PropTypes.bool.isRequired,
};

export default MainRouter;
