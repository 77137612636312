import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';

function refreshPage() {
  window.location.reload(false);
}

function AccountNotVerified() {
  return (
    <Container>
      <Row>
        <Col xs={6} className="d-none d-sm-block">
          <h1 className="mb-3">Talented.</h1>
          <h1 className="mb-3">Motivated. </h1>
          <h1 className="mb-3">Affordable.</h1>
        </Col>
        <Col>
          <h3 className="mb-3 text-warning">Account is not verified</h3>
          <small className="my-3 d-inline-block">
            Please go to your registered email inbox to click on the link in
            email and get it verified.
          </small>
          <NavLink to="/resendVerifyCode">
            <small>Resend Verify Code</small>
          </NavLink>
          <br />
          <NavLink to="#" onClick={refreshPage}>
            <small>Go Back</small>
          </NavLink>
          <hr />
        </Col>
      </Row>
    </Container>
  );
}

export default AccountNotVerified;
