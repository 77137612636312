import React, {useEffect, useState} from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import {Col, Row} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import uuid from 'react-uuid';
import axios from 'axios';
import {useRecoilValue, useSetRecoilState, useRecoilState} from 'recoil';
import {
  userSettings,
  startVideoRecommendedListState,
  userVideoWatchingState,
  searchCompanyResultVideosState,
} from '../store/atoms';
import {AddPreviewImages} from './Utils/Helper';
import VideoHorizontal from './postHorizontal';
import VideoInfo from './videoInfo';
import VideoPresentationBackground from './VideoPresentationImage';
import {PresentationTypes} from './Utils/PresentationTypes';
import './videos.css';

const VideoUnavailableImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  background-image: url('/images/delivery services.png');
  background-size: cover;
  background-position: center;
  opacity: 0.7;
  color: black;
  font-size: 36px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
`;

function Video() {
  const {videoKey} = useParams();
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const userSearchResults = useRecoilValue(searchCompanyResultVideosState);
  const setRecommendedList = useSetRecoilState(startVideoRecommendedListState);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [video, setVideo] = useState({});
  const [userVideoWatching, setUserVideoWatching] = useRecoilState(
    userVideoWatchingState
  );
  const [videoCanPlay, setVideoCanPlay] = useState(true);

  const handlePlayerError = (err) => {
    if (err) {
      setVideoCanPlay(false);
    }
  };

  useEffect(() => {
    async function fetchCompanyPresentation() {
      try {
        const findPresentationResponse = await axios.get(
          `${API_SERVER_URL}/api/company/presentation/findByEntityKey/${videoKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (findPresentationResponse.status === 200) {
          const presentationVideo = findPresentationResponse.data;
          const userWatching = {...userVideoWatching, watchingId: videoKey};
          setVideo(presentationVideo);
          setUserVideoWatching(userWatching);
          const canPlayVideo = presentationVideo?.presentationAttributes?.clientIntro_videoPresentationLink 
          && presentationVideo?.presentationAttributes?.lastSaved === PresentationTypes.ClientIntro;
          setVideoCanPlay(canPlayVideo);
        }
      } catch (err) {
        setVideoCanPlay(false);
      }
    }
    if (videoKey) {
      fetchCompanyPresentation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoKey]);

  useEffect(() => {
    const keywords = [''];
    const RecommendedListUrl = `${process.env.REACT_APP_API_URL}/api/company/findCompanyPresentationsAndJobsByKeyWordList`;
    const fetchData = async () => {
      if (keywords.length > 0) {
        try {
          setLoading(true);
          const response = await axios.post(
            RecommendedListUrl,
            {
              searchList: keywords,
            },
            {
              headers: {
                Authorization: `Bearer ${userInfo.token}`,
              },
            }
          );
          const presentationsData = [];
          response.data.forEach((item) => {
            presentationsData.push(...item.presentations);
          });
          const videos = AddPreviewImages(presentationsData);
          setPosts(videos);
          setRecommendedList(videos);
        } catch (err) {
          if (err.response && err.response.status === 404) {
            setPosts([]);
          } else {
            setError(err);
          }
        } finally {
          setLoading(false);
        }
      }
    };
    if (userSearchResults.length === 0) {
      fetchData();
    } else {
      setPosts(userSearchResults);
    }
  }, [setRecommendedList, userInfo.token, userSearchResults, userSearchResults.length]);

  if (!video) return null;

  return (
    <Row>
      <Col lg={8}>
        {videoCanPlay && (
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url={
                video?.presentationAttributes?.clientIntro_videoPresentationLink
              }
              controls
              playing
              width="100%"
              height="100%"
              onError={handlePlayerError}
            />
          </div>
        )}

        <div className="player-no-video-wrapper">
          {!videoCanPlay &&
            video.presentationAttributes?.startIntro_backgroundImage && (
              <VideoPresentationBackground
                video={video.presentationAttributes}
                showOverlayText
              />
            )}

          {!videoCanPlay &&
            !video.hasVideo &&
            !video.presentationAttributes?.startIntro_backgroundImage && (
              <VideoUnavailableImage>
                Video Preview Unavailable
              </VideoUnavailableImage>
            )}
        </div>
        <VideoInfo video={video} key={uuid()} />
      </Col>
      <Col lg={4}>
        {error && (
          <div>
            <p>{error.message}</p>
          </div>
        )}
        {loading ? (
          <p>loading...</p>
        ) : (
          posts &&
          posts.map((post) => <VideoHorizontal video={post} key={uuid()} />)
        )}
      </Col>
    </Row>
  );
}

export default Video;
