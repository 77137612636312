import React, {useState} from 'react';
import axios from 'axios';
import {Container, Row, Col} from 'react-bootstrap';

function ResendVerifyCode() {
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);
  const [accountNotFound, setAccountNotFound] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setVerificationCodeSent(false);
    setAccountNotFound(false);

    const API_SERVER_URL = process.env.REACT_APP_API_URL;

    try {
      const validationTokenEmail = {email: emailAddress};
      const response = await axios.post(
        `${API_SERVER_URL}/api/user/resendVerifyCode`,
        validationTokenEmail,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200) {
        setVerificationCodeSent(true);
      } else {
        setAccountNotFound(true);
      }
    } catch (e) {
      setAccountNotFound(true);
    }
  };

  return (
    <Container>
      <Row>
        <Col xs={6} className="d-none d-sm-block">
          <h1 className="mb-3">Talented.</h1>
          <h1 className="mb-3">Motivated. </h1>
          <h1 className="mb-3">Affordable.</h1>
        </Col>
        <Col>
          <form onSubmit={handleSubmit}>
            <div>
              <span className="formtext">Resend Verification Code</span>
              {verificationCodeSent === true && accountNotFound !== true ? (
                <div>
                  <p>
                    Your Verification Code was sent. Please check your email
                    account.
                  </p>
                </div>
              ) : (
                <div />
              )}
              {accountNotFound === true && verificationCodeSent !== true ? (
                <div>
                  <p>
                    Could not find a registered email address for this email.{' '}
                  </p>
                  <p>Please register an account using this email.</p>
                </div>
              ) : (
                <div />
              )}
              <div>
                <input
                  className="form-control"
                  type="text"
                  value={emailAddress || ''}
                  onChange={(event) => setEmailAddress(event.target.value)}
                  placeholder="Enter Email Address"
                  required
                />
                <button
                  className="mt-3 px-5 px-5 btn btn-warning rounded-pill"
                  type="submit"
                >
                  Go!
                </button>
              </div>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default ResendVerifyCode;
