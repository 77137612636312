/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  NavLink,
  useLocation,
  useRouteMatch,
  useHistory,
  Switch,
  Route,
} from 'react-router-dom';
import {Container, Row, Col, Card, Alert} from 'react-bootstrap';
import {useRecoilValue} from 'recoil';
import axios from 'axios';
import {userSettings} from '../store/atoms';
import TalentProfile from './TalentProfile';
import TalentProfileAddEdit from './TalentProfileAddEdit';
import TalentProfileDelete from './TalentProfileDelete';

const API_SERVER_URL = process.env.REACT_APP_API_URL;
const MAXIMUM_PROFILES_NUMBER =
  process.env.REACT_APP_TALENT_MVP_MAXIMUM_PROFILES;

function ExceedMaximumProfilesAlert() {
  const [show, setShow] = useState(true);
  if (show) {
    return (
      <Alert variant="warning" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>
          Hello, currently free users only support two profiles.
        </Alert.Heading>
      </Alert>
    );
  }
  return null;
}

function NeedCreateProfileAlert() {
  const [show, setShow] = useState(true);
  if (show) {
    return (
      <Alert variant="warning" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>
          Hello, you will need to create your profile before you can search for
          jobs.
        </Alert.Heading>
      </Alert>
    );
  }
  return null;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ProfileCard(props) {
  const {profile} = props;
  const {url} = useRouteMatch();
  const profileNameEncode = encodeURI(profile.profileName);
  const profileViewUrl = `${url}?action=view&profileID=${profile.entityKey}`;
  const profileUpdateUrl = `${url}/update/${profile.entityKey}`;
  const profileDeleteUrl = `${url}/delete/${profile.entityKey}/${profileNameEncode}`;
  return (
    <Col sm={12} md={6} className="mt-1">
      <Card className="text-white bg-secondary">
        <Card.Body>
          <Card.Title>{profile.profileName}</Card.Title>
          <Card.Text>{profile.profileAttributes.summary}</Card.Text>
          <Card.Link className="text-warning" href={profileViewUrl}>
            {' '}
            View
          </Card.Link>
          <Card.Link className="text-warning" href={profileUpdateUrl}>
            Edit
          </Card.Link>
          <Card.Link className="text-warning" href={profileDeleteUrl}>
            Delete
          </Card.Link>
        </Card.Body>
      </Card>
    </Col>
  );
}

function ProfileCards({path, userInfo, userProfiles}) {
  const showAddProfileLink = userProfiles.length === 0;

  return (
    <>
      {!userProfiles.length && (
        <Row className="py-3">
          <Col>
            <NeedCreateProfileAlert />
          </Col>
        </Row>
      )}
      <Row className="py-3">
        <Col>
          <h3 className="text-center">Profiles</h3>
          <div className="mb-3 text-muted">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              height="80"
              fill="currentColor"
              className="bi bi-person-circle"
              viewBox="0 0 16 16"
            >
              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
              <path
                fillRule="evenodd"
                d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
              />
            </svg>
            {` ${userInfo.firstName} ${userInfo.lastName}`}
            {showAddProfileLink && (
              <NavLink to={`${path}/add`} className="ml-5">
                Add new profile
              </NavLink>
            )}
          </div>
        </Col>
      </Row>
      {userProfiles.length >= parseInt(MAXIMUM_PROFILES_NUMBER, 10) && (
        <Row className="py-3">
          <Col>
            <ExceedMaximumProfilesAlert />
          </Col>
        </Row>
      )}
      <Row>
        {userProfiles &&
          userProfiles.map((item) => (
            <ProfileCard profile={item} key={item.entityKey} />
          ))}
      </Row>
    </>
  );
}

function TalentProfiles() {
  const userInfo = useRecoilValue(userSettings);
  const query = useQuery();
  const {path} = useRouteMatch();
  const history = useHistory();
  const [userProfiles, setUserProfiles] = useState([]);

  useEffect(() => {
    async function fetchTalentInfo() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/user/findByUserKey/${userInfo.userKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          console.log('TalentProfiles found talent info');
        }
      } catch (error) {
        console.error('TalentProfiles found talent info error - ', error);
        history.push(`/talent/info`);
      }
    }

    if (userInfo.userKey) {
      fetchTalentInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchTalentProfilesSummary() {
    try {
      const response = await axios.get(
        `${API_SERVER_URL}/api/talent/profile/findByUserKeyWithFilter?userKey=${userInfo.userKey}&filter=summary`,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (response.status === 200) {
        setUserProfiles(response.data);
        localStorage.setItem('profiles', JSON.stringify(response.data));
      }
    } catch (error) {
      localStorage.setItem('profiles', '');
    }
  }

  useEffect(() => {
    const pageAction = localStorage.getItem('talentPageAction') || '';
    if (pageAction === '') {
      fetchTalentProfilesSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const pageAction = localStorage.getItem('talentPageAction') || '';
    if (pageAction === 'add' || pageAction === 'update') {
      fetchTalentProfilesSummary();
      localStorage.setItem('talentPageAction', '');
    }
  });

  return (
    <Container>
      <ProfileCards
        userInfo={userInfo}
        userProfiles={userProfiles}
        path={path}
      />
      {query.get('profileID') && query.get('action') === 'view' ? (
        <Row className="py-5">
          <Col>
            <TalentProfile profileID={query.get('profileID')} />
          </Col>
        </Row>
      ) : null}
      <Row className="py-5">
        <Col>
          <Switch>
            <Route exact path={path} />
            {/* <Route path={`${path}/view`} component={TalentProfile} /> */}
            <Route path={`${path}/add`} component={TalentProfileAddEdit} />
            <Route
              path={`${path}/update/:profileId`}
              component={TalentProfileAddEdit}
            />
            <Route
              path={`${path}/delete/:profileId/:profileName`}
              component={TalentProfileDelete}
            />
          </Switch>
        </Col>
      </Row>
    </Container>
  );
}

ProfileCard.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default TalentProfiles;
