import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, ButtonGroup, Button, Card} from 'react-bootstrap';
import {useRecoilValue} from 'recoil';
import {BsExclamationCircleFill} from 'react-icons/bs';
import {
  talentFavoriteJobListState,
  talentApplicationListState,
} from '../store/atoms';
import {JobFavoriteInformation} from '../common/JobInformationCard';
import JobApplyCard from './JobApplyCard';
import {FavJobMessageModal} from '../common/message/FavJobMessageModal';

function JobFavoriteCard({job, onAddFavorite, onDeleteFavorite}) {
  const [canViewMore, setCanViewMore] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const talentFavoriteJobList = useRecoilValue(talentFavoriteJobListState);
  const [applyJobShow, setApplyJobShow] = useState(false);
  const [applyButtonText, setApplyButtonText] = useState('Apply');
  const talentApplicationList = useRecoilValue(talentApplicationListState);
  const applyJob = {entityKey: job.jobKey, jobTitle: job.jobTitle};
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (talentFavoriteJobList && talentFavoriteJobList.length > 0) {
      talentFavoriteJobList.forEach((item) => {
        if (item.entityKey === job.entityKey) {
          setIsFavorite(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentFavoriteJobList.length]);

  useEffect(() => {
    if (talentApplicationList) {
      const applyIndex = talentApplicationList.findIndex(
        (listItem) => listItem.jobKey === job.jobKey
      );
      if (applyIndex > -1) {
        if (
          talentApplicationList[applyIndex].appliedJobAttributes?.status ===
          'applied'
        ) {
          setApplyButtonText('Applied');
        } else if (
          talentApplicationList[applyIndex].appliedJobAttributes?.status ===
          'draft'
        ) {
          setApplyButtonText('Draft');
        } else {
          setApplyButtonText('Apply');
        }
      } else {
        setApplyButtonText('Apply');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentApplicationList]);

  return (
    <Col md={12} className="my-3">
      <Card className="bg-light">
        <Card.Header>
          {job?.jobTitle}{' '}
          {job?.jobAttributes?.closed &&
            job.jobAttributes.closed === 'true' && (
              <span
                style={{
                  'margin-left': '20px',
                  'font-size': '13px',
                  color: '#ffba00',
                }}
              >
                {' '}
                <BsExclamationCircleFill /> Closed Job
              </span>
            )}
        </Card.Header>
        <Card.Body>
          <JobFavoriteInformation job={job} full={canViewMore} />
        </Card.Body>
        <Card.Footer className="bg-light">
          <Row>
            {!(
              job?.jobAttributes?.closed && job.jobAttributes.closed === 'true'
            ) ? (
              <Col md={2}>
                {applyButtonText === 'Applied' && (
                  <button
                    type="button"
                    className="btn btn-block btn-success rounded-pill"
                    onClick={() => setApplyJobShow(true)}
                  >
                    {applyButtonText}
                  </button>
                )}
                {applyButtonText === 'Draft' && (
                  <button
                    type="button"
                    className="btn btn-block btn-outline-warning rounded-pill"
                    onClick={() => setApplyJobShow(true)}
                  >
                    {applyButtonText}
                  </button>
                )}
                {applyButtonText === 'Apply' && (
                  <button
                    type="button"
                    className="btn btn-block btn-outline-primary rounded-pill"
                    onClick={() => setApplyJobShow(true)}
                    disabled={
                      job?.jobAttributes?.closed &&
                      job.jobAttributes.closed === 'true'
                    }
                  >
                    {applyButtonText}
                  </button>
                )}
              </Col>
            ) : (
              <Col md={2} />
            )}
            <Col md={9} className="text-right">
              <ButtonGroup>
                <Button
                  variant="light"
                  onClick={() => {
                    setCanViewMore(!canViewMore);
                  }}
                >
                  {canViewMore ? (
                    <i className="bi bi-three-dots-vertical" />
                  ) : (
                    <i className="bi bi-three-dots" />
                  )}
                </Button>

                {isFavorite ? (
                  <Button
                    variant="light"
                    onClick={() => {
                      onDeleteFavorite(`${job.entityKey}`);
                      setIsFavorite(false);
                    }}
                  >
                    <i className="bi bi-heart-fill" style={{color: 'green'}} />
                  </Button>
                ) : (
                  <Button
                    variant="light"
                    onClick={() => onAddFavorite(`${job.entityKey}`)}
                  >
                    <i className="bi bi-heart" />
                  </Button>
                )}

                {/* <Button variant="light" onClick={() => onConnectCompany(`${job.userKey}`)}><i className="bi bi-chat-square-dots"></i></Button> */}
                <Button
                  variant="light"
                  onClick={() => setShowModal(!showModal)}
                >
                  <i className="bi bi-chat-square-dots" />
                </Button>
                {showModal && (
                  <FavJobMessageModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    item={job}
                    toType="COMPANY"
                  />
                )}
              </ButtonGroup>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      {applyJobShow && (
        <JobApplyCard
          job={applyJob}
          showApplyModal={applyJobShow}
          onHideApplyModal={() => setApplyJobShow(false)}
        />
      )}
    </Col>
  );
}

JobFavoriteCard.propTypes = {
  job: PropTypes.object.isRequired,
  onAddFavorite: PropTypes.func.isRequired,
  onDeleteFavorite: PropTypes.func.isRequired,
};

export default JobFavoriteCard;
