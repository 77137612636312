import React from 'react';
import {Container, Navbar} from 'react-bootstrap';
import LogoImage from './LogoImage';

function SinglePageHeader() {
  return (
    <Container>
      <Navbar className="">
        <Navbar.Brand href="/">
          <LogoImage />
        </Navbar.Brand>
      </Navbar>
    </Container>
  );
}

export default SinglePageHeader;
