import React, {useState, useEffect} from 'react';
import {Container, Row, Col, Form, Button} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {useRecoilValue, useRecoilState, useSetRecoilState} from 'recoil';
import axios from 'axios';
import {
  userSettings,
  talentFavoriteJobListState,
  talentProfileListState,
  talentApplicationListState,
  talentJobSearchResultState,
  talentJobSearchStringState,
} from '../store/atoms';
import JobSearchCard from './JobSearchCard';
import TalentMainJobLinks from './TalentMainJobLinks';

function TalentFindJobs() {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const [loading, setLoading] = useState(false);
  const [talentFavoriteJobList, setTalentFavoriteJobList] = useRecoilState(
    talentFavoriteJobListState
  );
  const [talentApplicationList, setTalentApplicationList] = useRecoilState(
    talentApplicationListState
  );
  const [talentJobSearchResult, setTalentJobSearchResult] = useRecoilState(
    talentJobSearchResultState
  );
  const [talentJobSearchString, setTalentJobSearchString] = useRecoilState(
    talentJobSearchStringState
  );
  const [keywords, setKeywords] = useState(talentJobSearchString);
  const setTalentProfileList = useSetRecoilState(talentProfileListState);
  const has = Object.prototype.hasOwnProperty;
  const history = useHistory();

  useEffect(() => {
    async function fetchTalentInfo() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/user/findByUserKey/${userInfo.userKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          console.log('TalentFindJobs found talent info');
        }
      } catch (error) {
        history.push(`/talent/info`);
      }
    }

    if (userInfo.userKey) {
      fetchTalentInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchTalentProfilesSummary() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/profile/findByUserKeyWithFilter?userKey=${userInfo.userKey}&filter=summary`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          if (response.data.length > 0) {
            setTalentProfileList(response.data);
            localStorage.setItem('profiles', JSON.stringify(response.data));
          } else {
            history.push(`/talent/profiles`);
          }
        }
      } catch (error) {
        history.push(`/talent/profiles`);
      }
    }

    if (userInfo.userKey) {
      fetchTalentProfilesSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentProfileListState]);

  useEffect(() => {
    async function fetchFavoriteList() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/favorite/job/findFavoriteDetailByUserKey/${userInfo.userKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setTalentFavoriteJobList(response.data);
        }
      } catch (error) {
        console.error(
          'TalentFindJobs found FavoriteList from remote error - ',
          error
        );
      }
    }

    if (talentFavoriteJobList.length === 0) {
      fetchFavoriteList();
    } else {
      console.log(
        'TalentFindJobs get talentFavoriteJobListState - ',
        talentFavoriteJobList.length
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentFavoriteJobList]);

  useEffect(() => {
    async function fetchTalentApplicationList() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/job/findAppliedJobWithTimeStampByUserKey/${userInfo.userKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setTalentApplicationList(response.data);
        }
      } catch (error) {
        console.error(
          'TalentFindJobs found ApplicationList from remote error - ',
          error
        );
      }
    }

    if (talentApplicationList.length === 0) {
      fetchTalentApplicationList();
    } else {
      console.log(
        'TalentFindJobs get talentApplicationListState - ',
        talentApplicationList.length
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentApplicationList]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const searchOptions = {
        searchString: `${keywords.trim()}`,
        searchAttributes: '',
      };
      setTalentJobSearchString(searchOptions.searchString);

      const findJobsResponse = await axios.post(
        `${API_SERVER_URL}/api/company/job/findDetailsByKeyWord/`,
        searchOptions,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (findJobsResponse.status === 200) {
        if (
          findJobsResponse.data.length > 0 &&
          has.call(findJobsResponse.data[0], 'job')
        ) {
          const jobSearchResult = [];
          findJobsResponse.data.forEach((item) => {
            const jobDetail = item.job;
            jobDetail.companyName = item?.postingCompany;
            jobDetail.numApplicants = item?.numApplicants;
            jobSearchResult.push(jobDetail);
          });
          setTalentJobSearchResult(jobSearchResult);
        } else {
          setTalentJobSearchResult(findJobsResponse.data);
        }
      }
    } catch (error) {
      setTalentJobSearchResult(null);
      setLoading(false);
    }
    setLoading(false);
  };

  const connectCompany = async (companyKey) => {
    console.log('Talent click to connect company - ', companyKey);
  };

  const addToFavorite = async (jobKey) => {
    const newFavorite = {
      userKey: userInfo.userKey,
      jobKey,
    };
    try {
      const response = await axios.post(
        `${API_SERVER_URL}/api/talent/favorite/job/addFavorite`,
        newFavorite,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        setTalentFavoriteJobList((oldFavoriteJobList) => [
          ...oldFavoriteJobList,
          response.data,
        ]);
      }
    } catch (error) {
      console.log('TalentFindJobs add favorite job error -', error);
    }
  };

  const deleteFavorite = async (favoriteEntityKey) => {
    try {
      const response = await axios.delete(
        `${API_SERVER_URL}/api/talent/favorite/job/deleteFavoriteByEntityKey/${favoriteEntityKey}`,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (response.status === 200) {
        const favoriteIndex = talentFavoriteJobList.findIndex(
          (listItem) => listItem.entityKey === favoriteEntityKey
        );
        if (favoriteIndex >= 0) {
          const newFavoriteList = [
            ...talentFavoriteJobList.slice(0, favoriteIndex),
            ...talentFavoriteJobList.slice(favoriteIndex + 1),
          ];
          setTalentFavoriteJobList(newFavoriteList);
        } else {
          console.log('TalentFindJobs delete favorite job error.');
        }
      }
    } catch (error) {
      console.log('TalentFindJobs delete favorite job error -', error);
    }
  };

  return (
    <Container>
      <Row className="my-3">
        <Col>
          <TalentMainJobLinks linkKey="jobFind" />
        </Col>
      </Row>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col lg="10" className="px-lg-0 pr-lg-1 mt-1">
            <div className="input-group">
              <span className="input-group-append bg-white ">
                <span className="input-group-text bg-transparent border-right-0">
                  <i className="bi bi-search" />
                </span>
              </span>
              <Form.Control
                required
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
                type="text"
                className="border-left-0"
                placeholder="Search by job title, keywords, or payment option"
              />
            </div>
          </Col>
          <Col lg="2" className="col-lg-2 px-lg-0 mt-1 text-center">
            <Button
              variant="success"
              disabled={loading}
              size="sm"
              type="submit"
              className="px-4 btn btn-success rounded-pill"
            >
              Find Jobs
            </Button>
          </Col>
        </Row>
      </form>
      <Row className="mt-3">
        <Col>{/* <p>Sort by:Relevance | Date</p> */}</Col>
      </Row>
      <Row className="mt-1">
        {talentJobSearchResult &&
          talentJobSearchResult.map((item) => {
            if (item.jobAttributes.closed === 'true') {
              return null;
            }
            return (
              <JobSearchCard
                key={item.entityKey}
                job={item}
                onAddFavorite={addToFavorite}
                onDeleteFavorite={deleteFavorite}
                onConnectCompany={connectCompany}
              />
            );
          })}
        {!talentJobSearchResult && (
          <span className="mt-3 d-inline-block text-center text-primary">
            no jobs found.
          </span>
        )}
      </Row>
    </Container>
  );
}

export default TalentFindJobs;
