import {useEffect} from 'react';
import {useRecoilState} from 'recoil';
import axios from 'axios';
import {talentApplicationListState} from '../../store/atoms';

const useTalentApplicationList = (userInfo) => {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const [, setTalentApplicationList] = useRecoilState(
    talentApplicationListState
  );

  useEffect(() => {
    async function fetchTalentApplicationList() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/job/findAppliedJobWithTimeStampByUserKey/${userInfo.userKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setTalentApplicationList(response.data);
        }
      } catch (error) {
        console.error('Error fetching talent application list:', error);
      }
    }

    fetchTalentApplicationList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.userKey, userInfo.token]); // Only re-run if userKey or token changes

  // No need to return talentApplicationList if it's always fetched live and not used for conditional rendering
};

export default useTalentApplicationList;
