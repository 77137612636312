import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Container, Row, Col, Form, Button} from 'react-bootstrap';
import {useRecoilValue, useRecoilState} from 'recoil';
import axios from 'axios';
import {
  userSettings,
  investorFavoriteCompanyListState,
  investorCompanySearchResultState,
  investorCompanySearchStringState,
} from '../store/atoms';
import CompanySearchCard from './CompanySearchCard';

function InvestorFindCompanies() {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const userInfo = useRecoilValue(userSettings);
  const [investorFavoriteCompanyList, setInvestorFavoriteCompanyList] =
    useRecoilState(investorFavoriteCompanyListState);
  const [investorCompanySearchResult, setInvestorCompanySearchResult] =
    useRecoilState(investorCompanySearchResultState);
  const [investorCompanySearchString, setInvestorCompanySearchString] =
    useRecoilState(investorCompanySearchStringState);
  const [keywords, setKeywords] = useState(investorCompanySearchString);
  const history = useHistory();
  const has = Object.prototype.hasOwnProperty;

  useEffect(() => {
    async function fetchInvestorUser() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/investor/user/findByUserKey/${userInfo.userKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          console.log(
            'InvestorFindCompanies found investor info - ',
            response.data.investorAttributes.email
          );
        }
      } catch (error) {
        history.push(`/investor/user`);
      }
    }

    if (userInfo.userKey) {
      fetchInvestorUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchFavoriteList() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/investor/favorite/company/findFavoriteDetailByUserKey/${userInfo.userKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setInvestorFavoriteCompanyList(response.data);
        }
      } catch (error) {
        console.error(
          'InvestorFindCompanies found FavoriteList from remote - ',
          error
        );
      }
    }

    if (investorFavoriteCompanyList.length === 0) {
      fetchFavoriteList();
    } else {
      console.log(
        'InvestorFindCompanies load investorFavoriteCompanyListState - ',
        investorFavoriteCompanyList.length
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investorFavoriteCompanyList]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const searchOptions = {
        searchString: `${keywords.trim()}`,
        searchAttributes: '',
      };

      setInvestorCompanySearchString(searchOptions.searchString);

      const findCompaniesResponse = await axios.post(
        `${API_SERVER_URL}/api/company/findDetailsByKeyWord/`,
        searchOptions,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (findCompaniesResponse.status === 200) {
        if (
          findCompaniesResponse.data.length > 0 &&
          has.call(findCompaniesResponse.data[0], 'company')
        ) {
          const companySearchResult = [];
          findCompaniesResponse.data.forEach((item) => {
            const companyDetail = item.company;
            companyDetail.numJobsPosted = item?.numJobsPosted;
            companyDetail.presentationKey = item?.presentationKey;
            companySearchResult.push(companyDetail);
          });
          setInvestorCompanySearchResult(companySearchResult);
        } else {
          setInvestorCompanySearchResult(findCompaniesResponse.data);
        }
      }
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  const connectCompany = async (companyName, companyKey) => {
    console.log(
      'Investor click to connect company - ',
      companyName,
      companyKey
    );
  };

  const addToFavorite = async (companyKey) => {
    const newFavorite = {
      userKey: userInfo.userKey,
      companyKey,
    };
    try {
      const response = await axios.post(
        `${API_SERVER_URL}/api/investor/favorite/company/addFavorite`,
        newFavorite,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        setInvestorFavoriteCompanyList((oldFavoriteCompanyList) => [
          ...oldFavoriteCompanyList,
          response.data,
        ]);
      }
    } catch (error) {
      console.log('InvestorFindCompanies add favorite cpmpany error -', error);
    }
  };

  const deleteFavorite = async (favoriteEntityKey) => {
    try {
      const response = await axios.delete(
        `${API_SERVER_URL}/api/investor/favorite/company/deleteFavoriteByEntityKey/${favoriteEntityKey}`,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (response.status === 200) {
        const favoriteIndex = investorFavoriteCompanyList.findIndex(
          (listItem) => listItem.entityKey === favoriteEntityKey
        );
        if (favoriteIndex >= 0) {
          const newFavoriteList = [
            ...investorFavoriteCompanyList.slice(0, favoriteIndex),
            ...investorFavoriteCompanyList.slice(favoriteIndex + 1),
          ];
          setInvestorFavoriteCompanyList(newFavoriteList);
        } else {
          console.log('InvestorFindCompanies delete favorite company error.');
        }
      }
    } catch (error) {
      console.log(
        'InvestorFindCompanies delete favorite company error -',
        error
      );
    }
  };

  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <p>Find Companies</p>
        </Col>
      </Row>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col lg="10" className="px-lg-0 pr-lg-1 mt-1">
            <div className="input-group">
              <span className="input-group-append bg-white ">
                <span className="input-group-text bg-transparent border-right-0">
                  <i className="bi bi-search" />
                </span>
              </span>
              <Form.Control
                required
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
                type="text"
                className="border-left-0"
                placeholder="Search by company name, city, address or keywords."
              />
            </div>
          </Col>
          <Col lg="2" className="px-lg-0 mt-1 text-center">
            <Button
              variant="success"
              size="sm"
              disabled={loading}
              type="submit"
              className="px-3 btn btn-success rounded-pill"
            >
              Find Companies
            </Button>
          </Col>
        </Row>
      </form>
      <Row className="mt-3">
        <Col>{/* <p>Sort by:Relevance | Date</p> */}</Col>
      </Row>
      <Row className="mt-1">
        {investorCompanySearchResult &&
          investorCompanySearchResult.map((item) => (
            <CompanySearchCard
              key={item.entityKey}
              companyInfo={item}
              onAddFavorite={addToFavorite}
              onDeleteFavorite={deleteFavorite}
              onConnectCompany={connectCompany}
            />
          ))}
      </Row>
    </Container>
  );
}

export default InvestorFindCompanies;
