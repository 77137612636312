import React from 'react';
import {Container} from 'react-bootstrap';

class LogoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({});
    localStorage.removeItem('authenticated');
    localStorage.removeItem('entityKey');
    localStorage.removeItem('userKey');
    localStorage.removeItem('companyKey');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('userTypes');
    localStorage.removeItem('loggedInAsUserType');
    localStorage.removeItem('emailAddress');
    localStorage.removeItem('token');
    window.location = '/';
  };

  render() {
    return (
      <Container className="py-5">
        <h2>Logout</h2>
        <p>Use this page to logout</p>

        <form onSubmit={this.handleSubmit}>
          <button
            type="button"
            className="px-5 px-5 btn btn-warning rounded-pill"
          >
            Logout!
          </button>
        </form>
      </Container>
    );
  }
}

export default LogoutForm;
