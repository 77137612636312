import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Col, ButtonGroup, Button, Card} from 'react-bootstrap';
import {useRecoilValue} from 'recoil';
import axios from 'axios';
import {userSettings, investorFavoriteCompanyListState} from '../store/atoms';
import CompanyFavoriteInformationCard from '../common/CompanyInformationCard';
import {FavMessageModal} from '../common/message/FavMessageModal';

function CompanyFavoriteCard({companyInfo, onAddFavorite, onDeleteFavorite}) {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const [isFavorite, setIsFavorite] = useState(false);
  const investorFavoriteCompanyList = useRecoilValue(
    investorFavoriteCompanyListState
  );
  const [showModal, setShowModal] = useState(false);
  const [companyDetail, setCompanyDetail] = useState({});
  const userInfo = useRecoilValue(userSettings);
  const has = Object.prototype.hasOwnProperty;

  useEffect(() => {
    if (investorFavoriteCompanyList && investorFavoriteCompanyList.length > 0) {
      investorFavoriteCompanyList.forEach((item) => {
        if (item.companyKey === companyInfo.companyKey) {
          setIsFavorite(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investorFavoriteCompanyList]);

  useEffect(() => {
    async function fetchCompanyInfo() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/company/findByEntityKey/${companyInfo.companyKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          setCompanyDetail(response.data);
        }
      } catch (error) {
        console.error('CompanyFavoriteCard fetch company info error - ', error);
      }
    }

    if (has.call(companyInfo, 'companyAttributes')) {
      setCompanyDetail(JSON.parse(JSON.stringify(companyInfo)));
    } else {
      fetchCompanyInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInfo.companyKey]);

  return (
    <Col sm={12} md={6} xl={4} className="my-2 text-center">
      <Card className="bg-light">
        <CompanyFavoriteInformationCard
          companyInfo={companyDetail}
          favoriteInfo={companyInfo}
        />
        <Card.Footer className="bg-light text-right">
          <ButtonGroup>
            {isFavorite ? (
              <Button
                variant="light"
                onClick={() => onDeleteFavorite(`${companyInfo.entityKey}`)}
              >
                <i className="bi bi-heart-fill" style={{color: 'green'}} />
              </Button>
            ) : (
              <Button
                variant="light"
                onClick={() => onAddFavorite(`${companyInfo.companyKey}`)}
              >
                <i className="bi bi-heart" />
              </Button>
            )}
            <Button variant="light" onClick={() => setShowModal(!showModal)}>
              <i className="bi bi-chat-square-dots" />
            </Button>
            {showModal && (
              <FavMessageModal
                showModal={showModal}
                setShowModal={setShowModal}
                item={companyInfo}
                toType="COMPANY"
              />
            )}
          </ButtonGroup>
        </Card.Footer>
      </Card>
    </Col>
  );
}

CompanyFavoriteCard.propTypes = {
  companyInfo: PropTypes.object.isRequired,
  onAddFavorite: PropTypes.func.isRequired,
  onDeleteFavorite: PropTypes.func.isRequired,
};

export default CompanyFavoriteCard;
