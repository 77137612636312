import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {IoMdLink} from 'react-icons/io';
import {useRecoilValue} from 'recoil';
import {userSettings} from '../store/atoms';

const ShareModal = styled.div`
  position: absolute;
  width: 413px;
  background-color: #fff;
  color: #666666;
  font-size: 20px;
  font-weight: 600;
  border: 1px solid #5ca53d;
  box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 15px 30px;
  z-index: 1000;
  transform: translate(-120px, 10px);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CopyLinkButton = styled.button`
  border: 0;
  background: #fff;
  color: #5ca53d;
  font-weight: 300;
  padding: 7px 0;
`;

// eslint-disable-next-line react/prop-types
function Share({buttonStyle, buttonText, companyKey, modalText}) {
  const [ModalOpen, setModalOpen] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const modalRef = useRef(null);

  const handleOpen = () => {
    setModalOpen(true);
    setLinkCopied(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setModalOpen(false);
      }
    };

    window.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ModalOpen]);

  const userInfo = useRecoilValue(userSettings);

  const copyLinkToClipboard = () => {
    const baseURL = window.location.origin;
    const queryParams = new URLSearchParams({
      type: 'TALENT',
    });

    if (companyKey) {
      queryParams.append('company_key', companyKey);
    }

    queryParams.append('referrer_talent_user_key', userInfo.userKey);

    const fullURL = `${baseURL}/signup?${queryParams.toString()}`;

    navigator.clipboard
      .writeText(fullURL)
      .then(() => {
        console.log(fullURL, 'URL copied to clipboard!');
        setLinkCopied(true);
      })
      .catch((error) => {
        console.error('Failed to copy URL to clipboard:', error);
      });
  };

  return (
    <div>
      <button type="button" style={buttonStyle} onClick={handleOpen}>
        {buttonText}
      </button>

      {ModalOpen && (
        <ShareModal ref={modalRef}>
          <p>{modalText}</p>
          <CopyLinkButton onClick={copyLinkToClipboard}>
            <IoMdLink size="1.5em" style={{marginRight: '0.8em'}} />
            Copy Link
          </CopyLinkButton>
          {linkCopied && (
            <p>Link copied for sharing.</p>
          )}
        </ShareModal>
      )}
    </div>
  );
}

export default Share;

Share.propTypes = {
  buttonStyle: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  modalText: PropTypes.string.isRequired,
};
