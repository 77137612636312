import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

function Terms() {
  return (
    <Container>
      <Row className="my-3">
        <Col>
          <h2 className="my-5">Terms and Conditions</h2>
        </Col>
      </Row>
      <Row className="my-3">
        <Col>
          <p>Last Updated: July 15th, 2024</p>
          <div className="my-4">
            <h4>
              1. About this agreement
            </h4>
            <p>
              {' '}
              This User Agreement (this “Agreement”) is a contract between you (“you” or “User”)
              and StartNation Inc.(“we” or “StartNation”). You must read, agree to, and accept
              all of the terms and conditions contained in this Agreement to be a User of our
              service through the website located at www.startnation.live or the mobile
              applications providing this service.
            </p>
          </div>

          <div className="my-4">
            <h4>
              2. Acceptance of Terms
            </h4>
            <p>
              {' '}
              By accessing and using StartNation’s website or mobile application (the “Site” or “Services”),
              you agree to be bound by this Agreement. If you do not agree with any part of this Agreement,
              you must not use the Services.
            </p>
          </div>

          <div className="my-4">
            <h4>
              3. Use of Services
            </h4>
            <p>
              {' '}
              a. <font className="font-weight-bolder" size={4}>Eligibility: </font> You must be at least 18
              years old to use our Services.
              By using the Services, you represent and warrant that you have the right,
              authority, and capacity to enter into this Agreement.
            </p>
            <p>
              {' '}
              b. <font className="font-weight-bolder" size={4}>Account Responsibility: </font> You are
              responsible for maintaining the confidentiality
              of your account information and for all activities that occur under your account.
            </p>
          </div>

          <div className="my-4">
            <h4>
              4. User Responsibilities
            </h4>
            <p>
              {' '}
              a. <font className="font-weight-bolder" size={4}>Talent Users (Users that sign up as “Talent”
              Account Type): </font>
              If you are looking for positions, you are solely responsible for the information you provide
              and for ensuring that your qualifications and representations are accurate and truthful.
              You are also solely responsible for any information you acquire about companies on our site.
              You understand that the positions posted by companies in the Site are for independent
              contractors
              and are not for full-time or part-time employees.
            </p>
            <p>
              {' '}
              b. <font className="font-weight-bolder" size={4}>Company Users (Users that sign up as
              “Company”
              Account Type): </font>
              If you are looking for candidates for helping your startup company, you are solely responsible
              for
              the position postings you create and for complying with all applicable employment and labor
              laws
              and regulations. You are also solely responsible for any information you post on our site or
              any
              information you acquire about Talent users from our site.
            </p>
          </div>

          <div className="my-4">
            <h4>
              5. Prohibited Conduct
            </h4>
            <p>
              {' '}
              You agree not to:
              <ul>
                <li>Use the Services for any unlawful purpose or in violation of any applicable laws.</li>
                <li>Post or transmit any content that is defamatory, obscene, fraudulent, or otherwise
                  objectionable.
                </li>
                <li>Engage in any activity that interferes with or disrupts the Services.</li>
              </ul>
            </p>
          </div>

          <div className="my-4">
            <h4>
              6. Content Ownership
            </h4>
            <p>
              {' '}
              You retain ownership of any content you post or transmit through the Services.
              However, by posting content, you grant StartNation a non-exclusive, worldwide, royalty-free
              license to use, copy, modify, and distribute your content in connection with
              the operation of the Services.
            </p>
          </div>

          <div className="my-4">
            <h4>
              7. Disclaimers and Limitation of Liability
            </h4>
            <p>
              {' '}
              You agree that StartNation will not have to pay you damages relating to your use of the Site
              and Site Services. StartNation is not liable, and you agree not to hold us responsible, for
              any damages or losses arising out of or in connection with the use of the site and its
              services,
              including, but not limited to:
              your use of or your inability to use our Site or Site Services to acquire employment or
              hire workers, delays or disruptions in our Site or Site Services;
              viruses or other malicious software obtained by accessing, or linking to, our Site or Site
              Services;
              glitches, bugs, errors, or inaccuracies of any kind in our Site or Site Services;
              damage to your hardware device from the use of the Site or Site Services;
              the content, actions, or inactions of third parties’ use of the Site or Site Services;
              a suspension or other action taken with respect to your Account;
              your reliance on the quality, accuracy, or reliability of job postings, Profiles, ratings,
              recommendations, and feedback (including their content, order, and display),
              Composite Information, or metrics found on, used on, or made available through the Site; and
              your need to modify practices, content, or behavior or your loss of or inability to do
              business,
              as a result of changes to the agreement.
            </p>
            <p>
              {' '}
              You agree not to hold us responsible for any dispute you may have with another User.
              In addition to the recognition that StartNation is not a party to any contract between Users,
              you hereby release StartNation and our respective officers, directors, agents, subsidiaries,
              joint ventures, employees and service providers from claims, demands, and damages of every
              kind and nature in any way connected with any dispute you have with another User.
            </p>
            <p>
              {' '}
              a. <font className="font-weight-bolder" size={4}>No Warranty: </font>The Services are provided
              “as is” and “as available.” StartNation makes no warranties, express or implied, regarding the
              Services, including but not limited to warranties of merchantability, fitness for a particular
              purpose, or non-infringement.
            </p>
            <p>
              {' '}
              b. <font className="font-weight-bolder" size={4}>Limitation of Liability: </font>In no event
              shall
              StartNation be liable for any indirect, incidental, special, consequential, or punitive
              damages,
              or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of
              data,
              use, goodwill, or other intangible losses, resulting from your use of the Services.
            </p>
          </div>

          <div className="my-4">
            <h4>
              8. Indemnification
            </h4>
            <p>
              {' '}
              You agree to indemnify and hold harmless StartNation, its officers, directors, employees,
              and agents, from and against any claims, liabilities, damages, losses, and expenses,
              including without limitation reasonable legal and accounting fees, arising out of or in
              any way connected with your access to or use of the Services or your violation of this
              Agreement.
            </p>
          </div>

          <div className="my-4">
            <h4>
              9. Termination
            </h4>
            <p>
              {' '}
              StartNation reserves the right to suspend or terminate your account and access to the Services
              at any time, for any reason, without notice or liability.
            </p>
          </div>

          <div className="my-4">
            <h4>
              10. Governing Law
            </h4>
            <p>
              {' '}
              This Agreement shall be governed by and construed in accordance with the laws of
              the United States and the State of California, without regard to its conflict of law
              principles.
            </p>
          </div>

          <div className="my-4 pb-3">
            <h4>
              11. Changes to the Agreement
            </h4>
            <p>
              {' '}
              StartNation may modify this Agreement from time to time. If we make material changes,
              we will notify you by email or by posting a notice on our website or within the mobile
              application. Your continued use of the Services after the changes become effective constitutes
              your acceptance of the new terms.
            </p>
          </div>

          <hr/>

          <p className="text-secondary">
            By using our Services, you acknowledge that you have read, understood,
            and agree to be bound by this Agreement.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Terms;
