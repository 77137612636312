import React from 'react';
import {NavLink} from 'react-router-dom';

function About() {
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col">
          <h1 className="display-4 text-center">What we do</h1>
        </div>
      </div>

      <div className="row my-5">
        <div className="col">
          <blockquote className="blockquote">
            <p className="mb-0">
              StartNation provides an online space where promising startups find
              remote talents with the right qualifications and passions
              to bring their dreams to reality.
            </p>
          </blockquote>
        </div>
      </div>

      <div className="row my-2">
        <div className="col">
          <blockquote className="blockquote">
            <p className="mb-0">
              For businesses, you can present compelling products and services
              to the right audience and get the best talents using only equity compensation.
            </p>
            <p>
              Start building your team by{' '}
              <NavLink to="/signup" className="text-body">
                <span className="text-primary">Signing Up</span>
              </NavLink>{' '}
              as a Company user.
            </p>
          </blockquote>
        </div>
      </div>

      <div className="row my-5">
        <div className="col">
          <blockquote className="blockquote">
            <p className="mb-0">
              For talents, you can find the right business or roles matching your passions;
              you will have the opportunity to acquire ownership in various startups
              you help build from an early stage as well as improve your skills by doing so.
            </p>
            <p>
              Start joining a team by{' '}
              <NavLink to="/signup" className="text-body">
                <span className="text-primary">Signing Up</span>
              </NavLink>{' '}
              as a Talent user.
            </p>
          </blockquote>
        </div>
      </div>
    </div>
  );
}

export default About;
