import React, {useState, useMemo} from 'react';
import {Redirect} from 'react-router-dom';
import {Container, Row, Col, Navbar, Nav, NavDropdown} from 'react-bootstrap';
import axios from 'axios';
import {useRecoilValue} from 'recoil';
import userTypes from '../store/constants';
import LogoImage from './LogoImage';
import {userSettings} from '../store/atoms';
import {FeedbackModal} from '../common/feedback/FeedbackModal';

function InvestorLayoutHeader() {
  const authenticated = localStorage.getItem('authenticated');
  const loggedInAsUserType = localStorage.getItem('loggedInAsUserType');
  const [messageModal, setMessageModel] = useState(false);
  const [isFeedbackReceiver, setIsFeedbackReceiver] = useState(false);
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  let investorHasThePermission = false;

  async function fetchFeedbackUserKey() {
    const FEEDBACK_USER_EMAIL = 'feedback@startnation.live';

    try {
      const response = await axios.get(
        `${API_SERVER_URL}/api/user/findByEmail/${FEEDBACK_USER_EMAIL}`,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        if (response.data.userKey === userInfo.userKey) {
          setIsFeedbackReceiver(true);
        }
      }
    } catch (error) {
      console.error('feedback user not found - ', error);
    }
  }

  const feedbackReceiverChecker = useMemo(() => {
    if (authenticated && loggedInAsUserType === userTypes.INVESTOR.value) {
      fetchFeedbackUserKey();
    }
    // eslint-disable-next-line
  }, [authenticated, loggedInAsUserType, userTypes, fetchFeedbackUserKey]);

  if (authenticated && loggedInAsUserType === userTypes.INVESTOR.value) {
    investorHasThePermission = true;
  }
  if (!investorHasThePermission) {
    return <Redirect to="/" />;
  }

  return (
    <Container fluid>
      <Row>
        <Col xl={{span: 10, offset: 1}}>
          <Navbar collapseOnSelect expand="lg">
            <Navbar.Brand href="/">
              <LogoImage />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link href="/#/investor/company/find">
                  Opportunities
                </Nav.Link>
                <Nav.Link href="/#/investor/favorite">Favorites</Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link href="#">
                  <i className="bi bi-chat-left-fill" />
                </Nav.Link>
                <NavDropdown
                  title={<i className="bi bi-person-fill" />}
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item href="/#/investor/account">
                    <i className="bi bi-person-fill mr-1" />
                    Account Info
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/#/investor/user">
                    <i className="bi bi-file-earmark-person-fill mr-1" />
                    Investor Info
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Message" id="nav-dropdown">
                  <NavDropdown.Item href="/#/investor/message/inbox">
                    Inbox
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/#/investor/message/sent">
                    Sent
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/#/investor/message/draft">
                    Draft
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  href="#"
                  onClick={() => setMessageModel(true)}
                  style={{color: 'grey'}}
                >
                  User Feedback
                </Nav.Link>
                <NavDropdown
                  id="nav-dropdown"
                  style={{margin: '0px 0px 0px -12px'}}
                >
                  <NavDropdown.Item href="/#/investor/user-feedback/inbox">
                    Inbox
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/#/investor/user-feedback/sent">
                    Sent
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/#/investor/user-feedback/draft">
                    Draft
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          {messageModal && !isFeedbackReceiver && (
            <FeedbackModal
              showModal={messageModal}
              setShowModal={setMessageModel}
              feedbackReceiverChecker={feedbackReceiverChecker}
            />
          )}
          <hr />
        </Col>
      </Row>
    </Container>
  );
}

export default InvestorLayoutHeader;
