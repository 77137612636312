/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col} from 'react-bootstrap';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import styled from 'styled-components';

const PresentationStartMediaContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const PresentationStartMediaImageBackground = styled(LazyLoadImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const PresentationStartMediaOverlayText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({textColor}) => textColor};
  text-align: ${({textLocation}) => textLocation.toLowerCase()};
`;

function CompanyPresentationImageCard({presentationInfo}) {
  if (!presentationInfo || !presentationInfo?.presentationAttributes)
    return null;
  const {
    startIntro_backgroundImage,
    startIntro_companyMoreInfo,
    startIntro_overlayText,
    startIntro_textColor,
    startIntro_textLocation,
  } = presentationInfo?.presentationAttributes;
  const renderImage = () => {
    if (startIntro_backgroundImage) {
      const imageFile = startIntro_backgroundImage.includes('images/')
        ? startIntro_backgroundImage
        : `/images/${startIntro_backgroundImage}.png`;
      return (
        <PresentationStartMediaImageBackground
          alt="StartMedia Image"
          src={imageFile}
          effect="blur"
        />
      );
    }
    return null;
  };

  const renderOverlayText = () => {
    if (startIntro_overlayText) {
      return (
        <PresentationStartMediaOverlayText
          textColor={startIntro_textColor}
          textLocation={startIntro_textLocation}
        >
          {startIntro_overlayText}
        </PresentationStartMediaOverlayText>
      );
    }
    return null;
  };

  return (
    <Container className="shadow rounded mb-5">
      {startIntro_backgroundImage && (
        <>
          <Row className="my-3">
            <Col>
              <div className="my-3 text-center">
                <h2>Present: StartMedia</h2>
              </div>
            </Col>
          </Row>
          <PresentationStartMediaContainer>
            {renderImage()}
            {renderOverlayText()}
          </PresentationStartMediaContainer>
          <Row className="my-3">
            <Col>
              <h4 className="text-muted">
                What&apos;s exciting about your company?
              </h4>
              <div>
                <p>{startIntro_companyMoreInfo}</p>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

CompanyPresentationImageCard.propTypes = {
  presentationInfo: PropTypes.object.isRequired,
};

export default CompanyPresentationImageCard;
