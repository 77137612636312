import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Card} from 'react-bootstrap';
import {useRecoilValue} from 'recoil';
import moment from 'moment';
import {BsExclamationCircleFill} from 'react-icons/bs';
import axios from 'axios';
import fp from 'lodash/fp';
import {userSettings, talentApplicationListState} from '../store/atoms';
import JobApplyCard from './JobApplyCard';

function JobApplyHistoryCardCompany({apply}) {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const [applyJobShow, setApplyJobShow] = useState(false);
  const talentApplicationList = useRecoilValue(talentApplicationListState);
  const [companyInfo, setCompanyInfo] = useState(undefined);
  const [webLink, setWebLink] = useState(undefined);
  const [companyName, setCompanyName] = useState(undefined);
  const [canViewPresentation, setCanViewPresentation] = useState(false);

  useEffect(() => {
    async function fetchCompanyLink(searchOptions, entityKey) {
      try {
        const findCompaniesResponse = await axios.post(
          `${API_SERVER_URL}/api/company/findDetailsByKeyWord/`,
          searchOptions,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (findCompaniesResponse.status === 200) {
          const temp = fp.filter((el) => el.company?.entityKey === entityKey)(
            findCompaniesResponse.data
          );
          try {
            const findPresentationResponse = await axios.get(
              `${API_SERVER_URL}/api/company/presentation/findByEntityKey/${temp[0].presentationKey}`,
              {headers: {Authorization: `Bearer ${userInfo.token}`}}
            );
            if (findPresentationResponse.status === 200) {
              setWebLink(
                findPresentationResponse.data.presentationAttributes
                  .videoPresentationLink
              );
            }
          } catch (error) {
            console.error(
              'CompanyPresentationCardModal found company Presentation error - ',
              error
            );
          }
        }
      } catch (error) {
        console.error('Failed to get inbox messages - ', error);
      }
    }

    async function fetchCompanyInfo(entityKey) {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/company/findByEntityKey/${entityKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          setCompanyName(response.data.companyName);
          const searchOptions = {
            searchString: `${response.data.companyName}`,
            searchAttributes: '',
          };
          fetchCompanyLink(searchOptions, response.data.entityKey);
        }
      } catch (error) {
        console.error('Failed to get inbox messages - ', error);
      }
    }

    async function fetchJobDetail() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/company/job/findByEntityKey/${apply[1][0].jobKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          setCompanyInfo(response.data.jobAttributes);
          // console.log("CompanyJobAdd found job - ", response.data);
          fetchCompanyInfo(apply[0]);
        }
      } catch (error) {
        console.error('CompanyJobAdd found job error - ', error);
      }
    }

    fetchJobDetail();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentApplicationList]);

  return (
    <Col md={12} className="my-3">
      <Card className="bg-light">
        <Card.Header>
          {apply.appliedJobAttributes?.jobTitle}{' '}
          {companyInfo &&
            companyInfo?.closed &&
            companyInfo.closed === 'true' && (
              <h style={{'margin-left': '20px', 'font-size': '13px'}}>
                {' '}
                <BsExclamationCircleFill /> Closed Job
              </h>
            )}
        </Card.Header>
        <Card.Body>
          {/* {JSON.stringify(apply)} */}
          <dl className="row my-3">
            <dt className="col-sm-4 text-muted">Company Name:</dt>
            <dd className="col-sm-8">{companyName}</dd>
            <dt className="col-sm-4 text-muted">Video Presentation:</dt>
            <dd className="col-sm-8">
              <a
                href={webLink}
                target="_blank"
                rel="noreferrer"
                style={{color: 'black'}}
              >
                {webLink}
              </a>
            </dd>
          </dl>

          {apply[1] &&
            apply[1].map((item) => (
              <>
                <Card.Header>
                  <Row>
                    <Col xs={10} className="d-none d-sm-block">
                      <button
                        type="button"
                        className="row my-3"
                        onClick={() => {
                          setCanViewPresentation(!canViewPresentation);
                        }}
                      >
                        <dl className="row my-3">
                          <dt className="col-sm-4 text-muted">Job Title:</dt>
                          <dd className="col-sm-8">
                            {item.appliedJobAttributes.jobTitle}
                          </dd>
                          <dt className="col-sm-4 text-muted">Job ID:</dt>
                          <dd className="col-sm-8">{item.jobKey}</dd>
                          <dt className="col-sm-4 text-muted">Applied at:</dt>
                          <dd className="col-sm-8">
                            {moment(item.updatedTimestamp).format(
                              'MMMM Do YYYY, h:mm:ss a'
                            )}
                          </dd>
                        </dl>
                      </button>
                    </Col>
                    <Col xs={1} className="d-none p-5 d-sm-block">
                      {item.appliedJobAttributes.status === 'applied' && (
                        <button
                          type="button"
                          className="btn btn-success rounded-pill"
                          onClick={() => setApplyJobShow(true)}
                        >
                          Applied
                        </button>
                      )}
                      {item.appliedJobAttributes.status === 'draft' && (
                        <button
                          type="button"
                          className="btn btn-outline-warning rounded-pill"
                          onClick={() => setApplyJobShow(true)}
                        >
                          Draft
                        </button>
                      )}
                      {item.appliedJobAttributes.status === 'apply' && (
                        <button
                          type="button"
                          className="btn btn-outline-primary rounded-pill"
                          onClick={() => setApplyJobShow(true)}
                        >
                          Apply
                        </button>
                      )}
                    </Col>
                  </Row>
                </Card.Header>
                {applyJobShow && (
                  <JobApplyCard
                    job={{
                      entityKey: item.jobKey,
                      jobTitle: item.appliedJobAttributes.jobTitle,
                    }}
                    showApplyModal={applyJobShow}
                    onHideApplyModal={() => setApplyJobShow(false)}
                  />
                )}
              </>
            ))}
        </Card.Body>
      </Card>
    </Col>
  );
}

JobApplyHistoryCardCompany.propTypes = {
  apply: PropTypes.array.isRequired,
};

export default JobApplyHistoryCardCompany;
