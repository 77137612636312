import axios from 'axios';

const API_SERVER_URL = process.env.REACT_APP_API_URL;

async function findTalentReferrer(userKey, token, talentUserExist, history) {
  // If talentUserExist is false, don't execute the following logic
  if (talentUserExist) return;

  const incrementVisitCount = async () => {
    try {
      await axios.put(
        `${API_SERVER_URL}/api/talent/user/increaseVisitsByUserKey/${userKey}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error('Error incrementing visit count:', error);
    }
  };

  const getTalentReferrer = async () => {
    try {
      const response = await axios.get(
        `${API_SERVER_URL}/api/talent/user/findTalentReferrerByUserKey/${userKey}`,
        {
          headers: {Authorization: `Bearer ${token}`},
        }
      );
      const {acquiredIsVerified, companyKey, visits} = response.data;

      if (companyKey && visits === 0 && acquiredIsVerified) {
        await incrementVisitCount();
        history.push(`/talent/startmedia/video/${companyKey}`);
      }
    } catch (error) {
      console.error('Error fetching talent referrer:', error);
    }
  };

  getTalentReferrer();
}

export default findTalentReferrer;
