import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col, Button} from 'react-bootstrap';
import {useRecoilValue, useRecoilState} from 'recoil';
import Select from 'react-select';
import axios from 'axios';
import {
  userSettings,
  talentEquityHeldListState,
  talentEquityNotHeldListState,
  talentFavoriteCompanyListState,
} from '../store/atoms';
import CompanyEquityHeldCard from './CompanyEquityHeldCard';

const CustomClearText = () => 'X';

function ClearIndicator(props) {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: {ref, ...restInnerProps},
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props)}
    >
      <div style={{padding: '0px 5px'}}>{children}</div>
    </div>
  );
}

function TalentEquityHeld() {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const [company, setCompany] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const [talentEquityHeldList, setTalentEquityHeldList] = useRecoilState(
    talentEquityHeldListState
  );
  const [talentEquityNotHeldList, setTalentEquityNotHeldList] = useRecoilState(
    talentEquityNotHeldListState
  );
  const [talentFavoriteCompanyList, setTalentFavoriteCompanyList] =
    useRecoilState(talentFavoriteCompanyListState);

  useEffect(() => {
    async function fetchEquityHeldList() {
      try {
        const findEquityHeldListResponse = await axios.get(
          `${API_SERVER_URL}/api/talent/equityheld/company/findEquityHeldByUserKey/${userInfo.userKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (findEquityHeldListResponse.status === 200) {
          setTalentEquityHeldList(findEquityHeldListResponse.data);
        }
      } catch (error) {
        console.error('TalentEquityHeld load Equity Held List error - ', error);
      }
    }

    fetchEquityHeldList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentEquityHeldList.length]);

  useEffect(() => {
    async function fetchEquityNotHeldList() {
      try {
        const findEquityNotHeldListResponse = await axios.get(
          `${API_SERVER_URL}/api/talent/equityheld/company/findNoEquityHeldByUserKey/${userInfo.userKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (findEquityNotHeldListResponse.status === 200) {
          setTalentEquityNotHeldList(findEquityNotHeldListResponse.data);
        }
      } catch (error) {
        console.error(
          'TalentEquityHeld load Equity Not Held List error - ',
          error
        );
      }
    }
    fetchEquityNotHeldList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentEquityNotHeldList.length]);

  useEffect(() => {
    async function fetchFavoriteList() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/favorite/company/findFavoriteDetailByUserKey/${userInfo.userKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setTalentFavoriteCompanyList(response.data);
        }
      } catch (error) {
        console.error(
          'TalentEquityHeld load Favorite Company List error - ',
          error
        );
      }
    }

    if (talentFavoriteCompanyList.length === 0) {
      fetchFavoriteList();
    } else {
      console.log(
        'TalentEquityHeld load Favorite Company List from state - ',
        talentFavoriteCompanyList.length
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentFavoriteCompanyList]);

  const onChangeSelect = (item) => {
    if (!item) return;
    setCompany(item);
    setIsSelected(true);
  };

  const addEquity = async (companyKey) => {
    try {
      const addOptions = {
        companyKey,
        userKey: userInfo.userKey,
      };
      const addCompanyResponse = await axios.post(
        `${API_SERVER_URL}/api/talent/equityheld/company/addEquityHeldCompany/`,
        addOptions,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (addCompanyResponse.status === 200) {
        setTalentEquityHeldList((oldTalentEquityHeldList) => [
          ...oldTalentEquityHeldList,
          addCompanyResponse.data,
        ]);
        const companyIndex = talentEquityNotHeldList.findIndex(
          (listItem) =>
            listItem.companyKey === addCompanyResponse.data.companyKey
        );
        const newEquityNotHeldList = [
          ...talentEquityNotHeldList.slice(0, companyIndex),
          ...talentEquityNotHeldList.slice(companyIndex + 1),
        ];
        setTalentEquityNotHeldList(newEquityNotHeldList);
      }
    } catch (error) {
      console.error('TalentEquityHeld add Equity error - ', error);
    }
  };

  const deleteEquity = async (equityEntityKey) => {
    try {
      const response = await axios.delete(
        `${API_SERVER_URL}/api/talent/equityheld/company/deleteByEntityKey/${equityEntityKey}`,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (response.status === 200) {
        const equityDeleteIndex = talentEquityHeldList.findIndex(
          (listItem) => listItem.entityKey === equityEntityKey
        );
        if (equityDeleteIndex >= 0) {
          setTalentEquityNotHeldList((oldTalentEquityNotHeldList) => [
            ...oldTalentEquityNotHeldList,
            talentEquityHeldList[equityDeleteIndex],
          ]);
          const newTalentEquityHeldList = [
            ...talentEquityHeldList.slice(0, equityDeleteIndex),
            ...talentEquityHeldList.slice(equityDeleteIndex + 1),
          ];
          setTalentEquityHeldList(newTalentEquityHeldList);
        } else {
          console.log('TalentEquityHeld delete Equity error.');
        }
      }
    } catch (error) {
      console.log('TalentEquityHeld delete equity error - ', error);
    }
  };

  const connectCompany = async (companyName, companyKey) => {
    console.log(
      'TalentEquityHeld click to connect company - ',
      companyName,
      companyKey
    );
  };

  const addToFavorite = async (companyKey) => {
    const newFavorite = {
      userKey: userInfo.userKey,
      companyKey,
    };
    try {
      const response = await axios.post(
        `${API_SERVER_URL}/api/talent/favorite/company/addFavorite`,
        newFavorite,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        setTalentFavoriteCompanyList((oldFavoriteCompanyList) => [
          ...oldFavoriteCompanyList,
          response.data,
        ]);
      }
    } catch (error) {
      console.log('TalentEquityHeld add favorite cpmpany error -', error);
    }
  };

  const deleteFavorite = async (favoriteEntityKey) => {
    // console.log("click delete the favorite - ", favoriteEntityKey);
    try {
      const response = await axios.delete(
        `${API_SERVER_URL}/api/talent/favorite/company/deleteFavoriteByEntityKey/${favoriteEntityKey}`,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (response.status === 200) {
        const favoriteIndex = talentFavoriteCompanyList.findIndex(
          (listItem) => listItem.entityKey === favoriteEntityKey
        );
        if (favoriteIndex >= 0) {
          const newFavoriteList = [
            ...talentFavoriteCompanyList.slice(0, favoriteIndex),
            ...talentFavoriteCompanyList.slice(favoriteIndex + 1),
          ];
          setTalentFavoriteCompanyList(newFavoriteList);
        } else {
          console.log('TalentEquityHeld delete favorite company error.');
        }
      }
    } catch (error) {
      console.log('TalentEquityHeld delete favorite company error -', error);
    }
  };

  return (
    <Container>
      <form onSubmit={(e) => e.preventDefault()}>
        <Row>
          <Col md={{span: 8, offset: 1}} className="px-lg-0 pr-lg-1 mt-1">
            <Select
              components={{ClearIndicator}}
              // value={company}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Search by company name"
              isClearable
              isSearchable
              name="companyName"
              onChange={onChangeSelect}
              options={talentEquityNotHeldList}
              getOptionValue={(option) => option.entityKey}
              getOptionLabel={(option) => option.companyName}
            />
          </Col>
          <Col md="1" className="px-lg-0 mt-1 text-center">
            <Button
              variant="success"
              disabled={!isSelected}
              onClick={() => {
                addEquity(company.entityKey);
                setCompany(null);
                setIsSelected(false);
              }}
              size="sm"
              type="submit"
              className="px-3 btn btn-success rounded-pill"
            >
              Add
            </Button>
          </Col>
        </Row>
      </form>
      <Row className="mt-3">
        {talentEquityHeldList &&
          talentEquityHeldList.map((item) => (
            <CompanyEquityHeldCard
              key={item.entityKey}
              companyInfo={item}
              onAddEquity={addEquity}
              onDeleteEquity={deleteEquity}
              onAddFavorite={addToFavorite}
              onDeleteFavorite={deleteFavorite}
              onConnectCompany={connectCompany}
            />
          ))}
      </Row>
    </Container>
  );
}

ClearIndicator.propTypes = {
  children: PropTypes.node.isRequired,
  innerProps: PropTypes.object.isRequired,
  getStyles: PropTypes.func.isRequired,
};

export default TalentEquityHeld;
