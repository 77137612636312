/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import {Container, Row, Col, Tab, Tabs, Alert} from 'react-bootstrap';
import axios from 'axios';
import {useRecoilState} from 'recoil';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {userSettings} from '../store/atoms';

function AccountTypeBadge({loggedInAsUserType, type}) {
  return type === loggedInAsUserType ? (
    <span className="badge badge-success mr-2" key={type}>
      {type}
    </span>
  ) : (
    <span className="badge badge-secondary mr-2" key={type}>
      {type}
    </span>
  );
}

function AccountInfoManagement() {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const [userInfo, setUserInfo] = useRecoilState(userSettings);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [updateFailed, setUpdateFailed] = useState(false);
  const [showFailedTag, setShowFailedTag] = useState(false);
  const [showSuccessTag, setShowSuccessTag] = useState(false);

  const validationSchema = yup.object().shape({
    oldPassword: yup.string().required('Current password is required'),
    newPassword: yup
      .string()
      .required('New password required')
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        'Does not meet the password rules!'
      ),
    newPassword2: yup
      .string()
      .oneOf(
        [yup.ref('newPassword'), null],
        'Does not match the new password entered above!'
      )
      .required('New password again'),
  });

  const {
    formState: {errors},
    handleSubmit,
    register,
    setValue: setPasswordValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const validationSchema2 = yup.object().shape({
    newFirstName: yup.string().required('First Name is required'),
    newLastName: yup.string().required('Last Name is required'),
  });

  const {
    formState: {errors: errors2},
    handleSubmit: handleSubmit2,
    register: register2,
    setValue: setBasicInfoValue,
  } = useForm({
    resolver: yupResolver(validationSchema2),
  });

  useEffect(() => {
    setBasicInfoValue('newFirstName', userInfo.firstName);
    setBasicInfoValue('newLastName', userInfo.lastName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const resetStatus = () => {
    setIsError(false);
    setIsSuccess(false);
    setUpdateFailed(false);
    setShowSuccessTag(false);
    setShowFailedTag(false);
  };

  async function handleSubmitUpdatePassword(data) {
    setLoading(true);
    setIsError(false);
    setIsSuccess(false);
    setUpdateFailed(false);
    setShowSuccessTag(false);
    setShowFailedTag(false);
    try {
      const ResetPassword = {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      };
      const response = await axios.put(
        `${API_SERVER_URL}/api/user/resetPassword/${userInfo.userKey}`,
        ResetPassword,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        setIsSuccess(true);
        setPasswordValue('oldPassword', '');
        setPasswordValue('newPassword', '');
        setPasswordValue('newPassword2', '');
        setShowSuccessTag(true);
      } else {
        setUpdateFailed(true);
        setShowFailedTag(true);
        setIsError(true);
      }
    } catch (e) {
      setUpdateFailed(true);
      setShowFailedTag(true);
      setShowSuccessTag(false);
      setIsSuccess(false);
    }
    setLoading(false);
  }

  async function handleSubmitUpdateBasicInfo(data) {
    setLoading(true);
    setIsError(false);
    setIsSuccess(false);
    setUpdateFailed(false);
    setShowSuccessTag(false);
    setShowFailedTag(false);
    try {
      const newUserName = {
        firstName: data.newFirstName,
        lastName: data.newLastName,
        currentType: userInfo.loggedInAsUserType,
      };
      const response = await axios.put(
        `${API_SERVER_URL}/api/user/updateByKey/${userInfo.userKey}`,
        newUserName,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        setIsSuccess(true);
        setShowSuccessTag(true);
        localStorage.setItem('firstName', response.data.firstName);
        localStorage.setItem('lastName', response.data.lastName);
        const newUserInfo = {...userInfo};
        newUserInfo.firstName = response.data.firstName;
        newUserInfo.lastName = response.data.lastName;
        setUserInfo(newUserInfo);
      } else {
        setUpdateFailed(true);
        setShowFailedTag(true);
        setIsError(true);
      }
    } catch (e) {
      setUpdateFailed(true);
      setShowFailedTag(true);
      setShowSuccessTag(false);
      setIsSuccess(false);
    }
    setLoading(false);
  }

  function GetAccountTypesText() {
    const userAccountTypes = userInfo.userTypes
      ? userInfo.userTypes.split(',')
      : [];

    return userAccountTypes.map((type) => (
      <AccountTypeBadge
        key={type}
        type={type}
        loggedInAsUserType={userInfo.loggedInAsUserType}
      />
    ));
  }

  return (
    <Container>
      <Row>
        <Col>
          <h3 className="text-center py-5">Account Info</h3>
          <div className="mt-5 mb-3">
            {isError && !isSuccess && (
              <small className="mt-3 d-inline-block text-danger">
                Something went wrong. Please try again later.
              </small>
            )}
          </div>
        </Col>
      </Row>
      <Tabs
        defaultActiveKey="info"
        id="account-info-update-tab"
        onSelect={(k) => resetStatus(k)}
      >
        <Tab eventKey="info" title="Account Info">
          <form onSubmit={handleSubmit2(handleSubmitUpdateBasicInfo)}>
            <div className="form-group row my-4 ml-2">
              <label
                htmlFor="staticAccountTypes"
                className="col-sm-4 col-xl-2  col-form-label"
              >
                Account Types
              </label>
              <div className="col-sm-8 col-xl-10" id="staticAccountTypes">
                <GetAccountTypesText />
              </div>
            </div>
            <div className="form-group row my-4 ml-2">
              <label
                htmlFor="staticEmail"
                className="col-sm-4 col-xl-2 col-form-label"
              >
                Email
              </label>
              <div className="col-sm-8 col-xl-4">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="staticEmail"
                  value={userInfo.emailAddress}
                />
              </div>
            </div>
            <div className="form-group row my-4 ml-2">
              <label
                htmlFor="userInputFirstName"
                className="col-sm-4  col-xl-2 col-form-label"
              >
                First Name
              </label>
              <div className="col-sm-8 col-xl-4">
                <input
                  type="text"
                  {...register2('newFirstName')}
                  className={`form-control ${
                    errors2.newFirstName ? 'is-invalid' : ''
                  }`}
                  id="userInputFirstName"
                  aria-describedby="firstNameHelp"
                />
                <div className="invalid-feedback">
                  {errors2.newFirstName?.message}
                </div>
              </div>
            </div>
            <div className="form-group row my-4 ml-2">
              <label
                htmlFor="userInputLastName"
                className="col-sm-4  col-xl-2  col-form-label"
              >
                Last Name
              </label>
              <div className="col-sm-8 col-xl-4">
                <input
                  type="text"
                  {...register2('newLastName')}
                  className={`form-control ${
                    errors2.newLastName ? 'is-invalid' : ''
                  }`}
                  id="userInputLastName"
                  aria-describedby="lastNameHelp"
                />
                <div className="invalid-feedback">
                  {errors2.newLastName?.message}
                </div>
              </div>
            </div>
            <div className="mb-3 ml-3">
              {!isSuccess && (
                <button
                  type="submit"
                  disabled={loading}
                  className="px-5 px-5 btn btn-primary rounded-pill"
                >
                  {loading ? 'Please waiting...' : 'Update'}
                </button>
              )}
            </div>
          </form>
        </Tab>
        <Tab eventKey="profile" title="Change Password">
          <form onSubmit={handleSubmit(handleSubmitUpdatePassword)}>
            <div className="my-4  ml-2">
              <label className="form-label" htmlFor="userInputCurrentPassword">
                Current password
              </label>
              <input
                type="password"
                {...register('oldPassword')}
                className={`form-control ${
                  errors.oldPassword ? 'is-invalid' : ''
                }`}
                id="userInputCurrentPassword"
              />
              <div className="invalid-feedback">
                {errors.oldPassword?.message}
              </div>
            </div>
            <div className="my-4  ml-2">
              <label className="form-label">New password</label>
              <div id="passwordHelp" className="text-primary">
                <small>
                  (Must have at least 8 characters, including uppercase letters,
                  lowercase letters, special letters, and a number)
                </small>
              </div>
              <input
                type="password"
                {...register('newPassword')}
                className={`form-control ${
                  errors.newPassword ? 'is-invalid' : ''
                }`}
                id="userInputNewPassword"
              />
              <div className="invalid-feedback">
                {errors.newPassword?.message}
              </div>
            </div>
            <div className="my-4  ml-2">
              <label className="form-label">New password again</label>
              <input
                type="password"
                {...register('newPassword2')}
                className={`form-control ${
                  errors.newPassword2 ? 'is-invalid' : ''
                }`}
                id="userInputNewPasswordAgain"
              />
              <div className="invalid-feedback">
                {errors.newPassword2?.message}
              </div>
            </div>
            <div className="mb-4  ml-3">
              {!isSuccess && (
                <button
                  type="submit"
                  disabled={loading}
                  className="px-5 btn btn-primary rounded-pill"
                >
                  {loading ? 'Please waiting...' : 'Update'}
                </button>
              )}
              {isSuccess && (
                <a href="/signin" className="px-5 btn btn-success rounded-pill">
                  Re-Signin
                </a>
              )}
            </div>
          </form>
        </Tab>
      </Tabs>
      {isSuccess && showSuccessTag && (
        <Alert
          variant="success"
          onClose={() => setShowSuccessTag(false)}
          dismissible
          style={{width: '270px'}}
        >
          Update success!
        </Alert>
      )}
      {updateFailed && showFailedTag && (
        <Alert
          variant="danger"
          onClose={() => setShowFailedTag(false)}
          dismissible
          style={{width: '270px'}}
        >
          Update failed!
        </Alert>
      )}
    </Container>
  );
}

export default AccountInfoManagement;
