import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Card, ButtonGroup, Button} from 'react-bootstrap';
import {useRecoilValue} from 'recoil';
import {
  talentFavoriteJobListState,
  talentApplicationListState,
} from '../store/atoms';
import {JobSearchInformation} from '../common/JobInformationCard';
import JobApplyCard from './JobApplyCard';
import {FindJobMessageModal} from '../common/message/FindJobMessageModal';

function JobSearchCard({job, onAddFavorite, onDeleteFavorite}) {
  const [canViewMore, setCanViewMore] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteKey, setFavoriteKey] = useState('');
  const [showModal, setShowModal] = useState(false);
  const talentFavoriteJobList = useRecoilValue(talentFavoriteJobListState);
  const talentApplicationList = useRecoilValue(talentApplicationListState);
  const [applyJobShow, setApplyJobShow] = useState(false);
  const [applyButtonText, setApplyButtonText] = useState('Apply');

  useEffect(() => {
    if (talentFavoriteJobList && talentFavoriteJobList.length > 0) {
      talentFavoriteJobList.forEach((item) => {
        if (item.jobKey === job.entityKey) {
          setIsFavorite(true);
          setFavoriteKey(item.entityKey);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentFavoriteJobList]);

  useEffect(() => {
    if (talentApplicationList) {
      const applyIndex = talentApplicationList.findIndex(
        (listItem) => listItem.jobKey === job.entityKey
      );
      if (applyIndex > -1) {
        if (
          talentApplicationList[applyIndex].appliedJobAttributes?.status ===
          'applied'
        ) {
          setApplyButtonText('Applied');
        } else if (
          talentApplicationList[applyIndex].appliedJobAttributes?.status ===
          'draft'
        ) {
          setApplyButtonText('Draft');
        } else {
          setApplyButtonText('Apply');
        }
      } else {
        setApplyButtonText('Apply');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentApplicationList]);

  return (
    <Col md={12} className="my-3">
      <Card className="bg-light">
        <Card.Header>{job?.jobTitle}</Card.Header>
        <Card.Body>
          <JobSearchInformation job={job} full={canViewMore} />
        </Card.Body>

        <Card.Footer className="bg-light">
          <Row>
            <Col md={2}>
              {applyButtonText === 'Applied' && (
                <button
                  type="button"
                  className="btn btn-block btn-success rounded-pill"
                  onClick={() => setApplyJobShow(true)}
                >
                  <i className="bi bi-clipboard-check mr-1" />
                  {applyButtonText}
                </button>
              )}
              {applyButtonText === 'Draft' && (
                <button
                  type="button"
                  className="btn btn-block btn-outline-warning rounded-pill"
                  onClick={() => setApplyJobShow(true)}
                >
                  <i className="bi bi-clipboard-plus mr-1" />
                  {applyButtonText}
                </button>
              )}
              {applyButtonText === 'Apply' && (
                <button
                  type="button"
                  className="btn btn-block btn-outline-primary rounded-pill"
                  onClick={() => setApplyJobShow(true)}
                >
                  {applyButtonText}
                </button>
              )}
            </Col>
            <Col md={2} className="text-left">
              {job?.numApplicants && job?.numApplicants > 0 ? (
                <Button variant="light">
                  <span className="badge badge-default">
                    <i className="bi bi-lightbulb mr-1" />
                    {job?.numApplicants} applicant
                    {job?.numApplicants > 1 ? 's' : ''}
                  </span>
                </Button>
              ) : (
                <Button variant="light">
                  <span className="badge badge-warning">
                    <i className="bi bi-lightbulb mr-1" />
                    Be the first applicant
                  </span>
                </Button>
              )}
            </Col>
            <Col md={8} className="text-right">
              <ButtonGroup>
                <Button
                  variant="light"
                  onClick={() => {
                    setCanViewMore(!canViewMore);
                  }}
                >
                  {canViewMore ? (
                    <i className="bi bi-three-dots-vertical" />
                  ) : (
                    <i className="bi bi-three-dots" />
                  )}
                </Button>

                {isFavorite ? (
                  <Button
                    variant="light"
                    onClick={() => {
                      onDeleteFavorite(`${favoriteKey}`);
                      setIsFavorite(false);
                    }}
                  >
                    <i className="bi bi-heart-fill" style={{color: 'green'}} />
                  </Button>
                ) : (
                  <Button
                    variant="light"
                    onClick={() => onAddFavorite(`${job.entityKey}`)}
                  >
                    <i className="bi bi-heart" />
                  </Button>
                )}
                <Button
                  variant="light"
                  onClick={() => setShowModal(!showModal)}
                >
                  <i className="bi bi-chat-square-dots" />
                </Button>
                {showModal && (
                  <FindJobMessageModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    item={job}
                    toType="COMPANY"
                  />
                )}
              </ButtonGroup>
            </Col>
          </Row>
        </Card.Footer>
      </Card>

      {applyJobShow && (
        <JobApplyCard
          job={job}
          showApplyModal={applyJobShow}
          onHideApplyModal={() => setApplyJobShow(false)}
        />
      )}
    </Col>
  );
}

JobSearchCard.propTypes = {
  job: PropTypes.object.isRequired,
  onAddFavorite: PropTypes.func.isRequired,
  onDeleteFavorite: PropTypes.func.isRequired,
};

export default JobSearchCard;
