import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Card, Badge} from 'react-bootstrap';
import moment from 'moment';
import {useRecoilValue} from 'recoil';
import axios from 'axios';
import fp from 'lodash/fp';
import {userSettings} from '../store/atoms';
import CompanyPresentationCardModal from './CompanyPresentationCardModal';

function CompanyFavoriteTimestamp({timeStamp}) {
  if (timeStamp) {
    return (
      <Card.Subtitle className="mb-2 text-muted">
        Favorite at {moment(timeStamp).format('MMMM Do YYYY')}
      </Card.Subtitle>
    );
  }
  return null;
}

export function CompanyInformationBrief({companyInfo}) {
  return (
    <>
      <Card.Title>{companyInfo?.companyName}</Card.Title>
      {companyInfo?.favoriteTimestamp && (
        <CompanyFavoriteTimestamp timeStamp={companyInfo?.favoriteTimestamp} />
      )}
    </>
  );
}

function CompanyFavoriteInformationCard({companyInfo, favoriteInfo}) {
  const userInfo = useRecoilValue(userSettings);
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const [webLink, setWebLink] = useState('');

  async function fetchCompanyInfo(searchOptions) {
    try {
      const findCompaniesResponse = await axios.post(
        `${API_SERVER_URL}/api/company/findDetailsByKeyWord/`,
        searchOptions,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (findCompaniesResponse.status === 200) {
        const temp = fp.filter(
          (el) => el.company?.entityKey === companyInfo.entityKey
        )(findCompaniesResponse.data);
        try {
          const findPresentationResponse = await axios.get(
            `${API_SERVER_URL}/api/company/presentation/findByEntityKey/${temp[0].presentationKey}`,
            {headers: {Authorization: `Bearer ${userInfo.token}`}}
          );
          if (findPresentationResponse.status === 200) {
            setWebLink(
              findPresentationResponse.data.presentationAttributes
                .videoPresentationLink
            );
          }
        } catch (error) {
          console.error(
            'CompanyPresentationCardModal found company Presentation error - ',
            error
          );
        }
      }
    } catch (error) {
      console.error('Failed to get inbox messages - ', error);
    }
  }
  if (companyInfo?.companyName) {
    const searchOptions = {
      searchString: `${companyInfo.companyName.trim()}`,
      searchAttributes: '',
    };
    fetchCompanyInfo(searchOptions);
  }
  return (
    <>
      <Card.Header> {companyInfo?.companyName}</Card.Header>
      <Card.Body>
        <Card.Text>
          <a
            href={webLink}
            target="_blank"
            rel="noreferrer"
            style={{color: 'black'}}
          >
            {webLink}
          </a>
        </Card.Text>
        <Card.Title>{companyInfo.companyAttributes?.city}</Card.Title>
        <Card.Text>
          {companyInfo.companyAttributes?.state},{' '}
          {companyInfo.companyAttributes?.zip}
        </Card.Text>
        <h5>
          <Badge pill variant="dark">
            {companyInfo.companyAttributes?.EINType}
          </Badge>
        </h5>
        {companyInfo.companyAttributes?.fundingStatus && (
          <h5>
            <Badge pill variant="dark">
              Funding: {companyInfo.companyAttributes?.fundingStatus}
            </Badge>
          </h5>
        )}
        <Card.Text className="mt-4">
          {favoriteInfo?.favoriteTimestamp && (
            <CompanyFavoriteTimestamp
              timeStamp={favoriteInfo?.favoriteTimestamp}
            />
          )}
        </Card.Text>
      </Card.Body>
    </>
  );
}

export function CompanyInformationCard({companyInfo}) {
  const userInfo = useRecoilValue(userSettings);
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const [canViewPresentation, setCanViewPresentation] = useState(false);
  const [webLink, setWebLink] = useState('');

  async function fetchCompanyInfo(presentationKey) {
    try {
      const findPresentationResponse = await axios.get(
        `${API_SERVER_URL}/api/company/presentation/findByEntityKey/${presentationKey}`,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (findPresentationResponse.status === 200) {
        setWebLink(
          findPresentationResponse.data.presentationAttributes
            .videoPresentationLink
        );
      }
    } catch (error) {
      console.error(
        'CompanyPresentationCardModal found company Presentation error - ',
        error
      );
    }
  }

  if (companyInfo?.presentationKey) {
    fetchCompanyInfo(companyInfo.presentationKey);
  }

  return (
    <>
      <Card.Header>
        <Card.Title>
          {companyInfo && companyInfo?.presentationKey ? (
            <span
              onClick={() => {
                setCanViewPresentation(!canViewPresentation);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setCanViewPresentation(!canViewPresentation);
                }
              }}
              role="button"
              tabIndex={0}
            >
              <u>{companyInfo?.companyName}</u>
              <i className="ml-1 bi bi-info-circle" />{' '}
            </span>
          ) : (
            companyInfo?.companyName
          )}
        </Card.Title>
        <Card.Text>
          <a
            href={webLink}
            target="_blank"
            rel="noreferrer"
            style={{color: 'black'}}
          >
            {webLink}
          </a>
        </Card.Text>
        <Card.Text>{companyInfo.companyAttributes?.city}</Card.Text>
        {(companyInfo.companyAttributes?.state ||
          companyInfo.companyAttributes?.zip) && (
          <Card.Text>
            {companyInfo.companyAttributes?.state},{' '}
            {companyInfo.companyAttributes?.zip}
          </Card.Text>
        )}
        <h5>
          <Badge pill variant="dark">
            {companyInfo.companyAttributes?.EINType}
          </Badge>
        </h5>
        {companyInfo.companyAttributes?.fundingStatus && (
          <h5>
            <Badge pill variant="dark">
              Funding: {companyInfo.companyAttributes?.fundingStatus}
            </Badge>
          </h5>
        )}
      </Card.Header>

      {canViewPresentation && companyInfo?.presentationKey && (
        <CompanyPresentationCardModal
          presentationKey={companyInfo?.presentationKey}
          showPresentationModal={canViewPresentation}
          onHidePresentationModal={() => setCanViewPresentation(false)}
        />
      )}
    </>
  );
}

CompanyFavoriteTimestamp.propTypes = {
  timeStamp: PropTypes.string.isRequired,
};

CompanyInformationBrief.propTypes = {
  companyInfo: PropTypes.object.isRequired,
};

CompanyFavoriteInformationCard.propTypes = {
  companyInfo: PropTypes.object.isRequired,
  favoriteInfo: PropTypes.object.isRequired,
};

CompanyInformationCard.propTypes = {
  companyInfo: PropTypes.object.isRequired,
};

export default CompanyFavoriteInformationCard;
