import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link, useParams, useHistory} from 'react-router-dom';
import {Alert} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {useRecoilValue} from 'recoil';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import {userSettings} from '../store/atoms';

function AlertDismissibleText(props) {
  const {userProfileName} = props;

  const [show, setShow] = useState(true);
  if (show) {
    return (
      <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Delete</Alert.Heading>
        <p>Please input the profile name({userProfileName}).</p>
      </Alert>
    );
  }

  return null;
}

function TalentProfileDelete() {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const {profileId, profileName} = useParams();
  const [isError, setIsError] = useState(false);
  const userInfo = useRecoilValue(userSettings);
  const history = useHistory();
  const userProfileName = decodeURI(profileName);

  useEffect(() => {
    async function fetchTalentProfile() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/profile/findByEntityKey/${profileId}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          console.log(
            'TalentProfileDelete found talent profile - ',
            response.data.profileName
          );
        }
      } catch (error) {
        history.push(`/talent/profiles?from=update`);
      }
    }

    fetchTalentProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId, userProfileName]);

  const validationSchema = yup.object().shape({
    profileName: yup
      .string()
      .oneOf([userProfileName], 'Profile name must be matched'),
  });

  const {
    formState: {errors, isSubmitting},
    handleSubmit,
    register,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  async function onSubmit() {
    try {
      const response = await axios.delete(
        `${API_SERVER_URL}/api/talent/profile/deleteByEntityKey/${profileId}`,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        localStorage.setItem('talentPageAction', 'update');
        window.location = '/talent/profiles/';
        window.location.reload();
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AlertDismissibleText userProfileName={userProfileName} />
      {isError && (
        <small className="mt-3 d-inline-block text-danger">
          Something went wrong. Please try again later.
        </small>
      )}
      <div className="form-row">
        <div className="form-group col-6">
          <label>Profile Name</label>
          <input
            name="profileName"
            type="text"
            {...register('profileName')}
            className={`form-control ${errors.profileName ? 'is-invalid' : ''}`}
          />
          <div className="invalid-feedback">{errors.profileName?.message}</div>
        </div>
      </div>
      <div className="form-group">
        <button
          type="submit"
          disabled={isSubmitting}
          className="btn btn-primary"
        >
          {isSubmitting && (
            <span className="spinner-border spinner-border-sm mr-1" />
          )}
          Submit
        </button>
        <Link to="/talent/profiles" className="btn btn-link ml-3">
          Cancel
        </Link>
      </div>
    </form>
  );
}

AlertDismissibleText.propTypes = {
  userProfileName: PropTypes.string.isRequired,
};

export default TalentProfileDelete;
