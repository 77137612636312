import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {useRecoilValue} from 'recoil';
import TalentProfileDetail from './TalentProfileDetail';
import {userSettings} from '../store/atoms';

function TalentProfile({profileID}) {
  const userInfo = useRecoilValue(userSettings);
  const [userProfile, setUserProfile] = useState({});
  const API_SERVER_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    async function fetchTalentProfile() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/profile/findByEntityKey/${profileID}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          setUserProfile(response.data);
        }
      } catch (error) {
        // console.log("TalentProfile found talent profile  error - ", error);
      }
    }

    fetchTalentProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileID]);

  return userProfile && <TalentProfileDetail profile={userProfile} />;
}

TalentProfile.propTypes = {
  profileID: PropTypes.string.isRequired,
};

export default TalentProfile;
