import {useEffect} from 'react';
import axios from 'axios';
import {useRecoilState} from 'recoil';
import {talentFavoriteJobListState} from '../../store/atoms';

const useTalentFavoriteJobList = (userInfo) => {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const [, setTalentFavoriteJobList] = useRecoilState(
    talentFavoriteJobListState
  );

  useEffect(() => {
    async function fetchFavoriteList() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/favorite/job/findFavoriteDetailByUserKey/${userInfo.userKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setTalentFavoriteJobList(response.data);
        }
      } catch (error) {
        console.error('Error fetching favorite job list:', error);
      }
    }

    fetchFavoriteList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.userKey, userInfo.token]); // Only re-run if userKey or token changes
};

export default useTalentFavoriteJobList;
