import React from 'react';
import PropTypes from 'prop-types';

function TalentProfileLinks({profile}) {
  const otherLinksArray = profile?.profileAttributes?.otherLinks
    ? JSON.parse(profile.profileAttributes.otherLinks)
    : [];
  return (
    <>
      {profile.profileAttributes?.linkedInLink && (
        <>
          <dt className="text-muted" />
          <dd className="mb-2">
            <a
              href={profile.profileAttributes?.linkedInLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="bi bi-linkedin mr-1" />
              LinkedIn Profile Link
            </a>
          </dd>
        </>
      )}
      {profile.profileAttributes?.resumeLink && (
        <>
          <dt className="text-muted" />
          <dd className="mb-2">
            <a
              href={profile.profileAttributes?.resumeLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="bi bi-file-earmark-person mr-1" />
              Resume Link
            </a>
          </dd>
        </>
      )}
      {profile.profileAttributes?.videoLink && (
        <>
          <dt className="text-muted" />
          <dd className="mb-2">
            <a
              href={profile.profileAttributes?.videoLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="bi bi-file-play-fill mr-1" />
              Video Link
            </a>
          </dd>
        </>
      )}
      {otherLinksArray.length > 0 && (
        <>
          {otherLinksArray.map((index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              <dt className="text-muted" />
              <dd className="mb-2">
                <a
                  href={otherLinksArray[index]?.linkUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bi bi-link mr-1" />
                  {otherLinksArray[index]?.linkName}{' '}
                </a>
              </dd>
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );
}

TalentProfileLinks.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default TalentProfileLinks;
