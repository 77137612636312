const userTypes = {
  COMPANY: {
    key: 'T1',
    value: 'COMPANY',
    desc: 'COMPANY',
  },
  INVESTOR: {
    key: 'T2',
    value: 'INVESTOR',
    desc: 'INVESTOR',
  },
  TALENT: {
    key: 'T3',
    value: 'TALENT',
    desc: 'TALENT',
  },
};

export default userTypes;
