import React from 'react';
import {Modal, Button} from 'react-bootstrap';

import PropTypes from 'prop-types';

function CompanyPresentationUpdateCancelModal({
  handleCancel,
  setShowModal,
  showModal,
}) {
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      centered
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>Save change before leaving?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>You will lose your changes with cancelling the editing.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Yes
        </Button>
        <Button variant="primary" onClick={handleClose}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

CompanyPresentationUpdateCancelModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default CompanyPresentationUpdateCancelModal;
