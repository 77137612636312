import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Modal, Form} from 'react-bootstrap';
import axios from 'axios';
import {useRecoilValue} from 'recoil';
import {userSettings} from '../store/atoms';

function MessageReplyModal({message, setShowModal, showModal}) {
  const handleClose = () => setShowModal(false);
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const getSubject = message.entityKey
    ? message.receivedMessageAttributes?.subject
    : message[1][0].receivedMessageAttributes?.subject;
  const [subject, setSubject] = useState(getSubject);
  const [messageContent, setMessageContent] = useState('');

  const getRequestTalentWithJob = () => ({
    fromUserKey: userInfo.userKey,
    toUserKey: message.fromUserKey,
    toUserType: message.fromUserType,
    sentMessageAttributes: {
      messageKey: message.receivedMessageAttributes?.messageKey,
      subject,
      body: messageContent,
      modifyTime: new Date(),
      id: message.receivedMessageAttributes?.id,
      jobTitle: message.receivedMessageAttributes?.jobTitle,
      userType: userInfo.loggedInAsUserType,
      originMsgSub: message.receivedMessageAttributes.originMsgSub,
      originMsgBody: message.receivedMessageAttributes.originMsgSub,
    },
  });

  const getRequest = () => ({
    fromUserKey: userInfo.userKey,
    toUserKey: message.fromUserKey,
    toUserType: message.fromUserType,
    sentMessageAttributes: {
      messageKey: message.receivedMessageAttributes?.messageKey,
      subject,
      body: messageContent,
      modifyTime: new Date(),
      id: message.receivedMessageAttributes?.id,
      userType: userInfo.loggedInAsUserType,
      originMsgSub: message.receivedMessageAttributes.originMsgSub,
      originMsgBody: message.receivedMessageAttributes.originMsgSub,
    },
  });

  const handleSend = () => {
    async function sendMessageReply(request) {
      try {
        const response = await axios.post(
          `${API_SERVER_URL}/api/${userInfo.loggedInAsUserType.toLowerCase()}/message/send/create`,
          request,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          try {
            const sendResponse = await axios.get(
              `${API_SERVER_URL}/api/${userInfo.loggedInAsUserType.toLowerCase()}/message/send/sendByEntityKey/${
                response.data.entityKey
              }`,
              {headers: {Authorization: `Bearer ${userInfo.token}`}}
            );
            if (sendResponse.status === 200) {
              console.log('Sent feedback - ', sendResponse.data.length);
            }
          } catch (error) {
            console.error('Failed to get sent feedback - ', error);
          }
        }
      } catch (error) {
        console.error('Failed to get unsent feedback - ', error);
      }
    }

    if (userInfo.userKey) {
      if (message.entityKey) {
        if (message.fromUserType === 'TALENT') {
          if (message.receivedMessageAttributes?.jobTitle) {
            sendMessageReply(
              getRequestTalentWithJob(
                userInfo,
                message,
                subject,
                messageContent
              )
            );
          } else {
            sendMessageReply(
              getRequest(userInfo, message, subject, messageContent)
            );
          }
        } else {
          sendMessageReply(
            getRequest(userInfo, message, subject, messageContent)
          );
        }
      } else if (message.fromUserType === 'TALENT') {
        if (message[1][0].receivedMessageAttributes?.jobTitle) {
          sendMessageReply(
            getRequestTalentWithJob(
              userInfo,
              message[1][0],
              subject,
              messageContent
            )
          );
        } else {
          sendMessageReply(
            getRequest(userInfo, message[1][0], subject, messageContent)
          );
        }
      } else {
        sendMessageReply(
          getRequest(userInfo, message[1][0], subject, messageContent)
        );
      }
    }
    setShowModal(false);
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Reply:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="validationCustom01">
          <Form.Label>Re:</Form.Label>
          <Form.Control
            type="textarea"
            value={subject}
            onChange={(event) => setSubject(event.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="validationCustom02">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={messageContent}
            onChange={(event) => setMessageContent(event.target.value)}
            required
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-md btn-outline-success rounded-pill"
          onClick={handleSend}
        >
          Send
        </button>
      </Modal.Footer>
    </Modal>
  );
}

MessageReplyModal.propTypes = {
  message: PropTypes.object.isRequired,
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default MessageReplyModal;
