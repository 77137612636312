import React from 'react';
import PropTypes from 'prop-types';
import {Nav} from 'react-bootstrap';

function TalentMainCompanyLinks({linkKey}) {
  return (
    <Nav variant="pills" activeKey={linkKey}>
      <Nav.Item>
        <Nav.Link eventKey="companyFind" href="/talent/companies">
          Find
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="companyFavorited" href="/talent/favorite/company">
          Favorited
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="companyApplied" href="/talent/history/company">
          Applied
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
}

TalentMainCompanyLinks.propTypes = {
  linkKey: PropTypes.string.isRequired,
};

export default TalentMainCompanyLinks;
