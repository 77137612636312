import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import ShowMoreText from 'react-show-more-text';
import {FaGlobe} from 'react-icons/fa';
import {useRecoilValue} from 'recoil';
import axios from 'axios';
import Share from '../common/Share';
import GetCompanyAttributes from '../common/utils/GetCompanyAttributes';
import {PresentationTypes} from './Utils/PresentationTypes';
import JobCard from '../common/JobCard';

import {userSettings, searchCompanyResultState} from '../store/atoms';
import './videos.css';

const companyShareButtonStyle = {
  width: '248px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  padding: '8px 45px',
  borderRadius: '32px',
  border: 'none',
  background: '#f1881a',
  color: '#fff',
  cursor: 'pointer',
  fontWeight: 'bold',
};

function VideoInfo({video}) {
  const {companyKey} = video;
  const [companyInfo, setCompanyInfo] = useState({});
  const [companyJobsInfo, setCompanyJobsInfo] = useState([]);
  const [error, setError] = useState('');
  const userInfo = useRecoilValue(userSettings);
  const searchCompanyResult = useRecoilValue(searchCompanyResultState);
  const aboutUs = (video.presentationAttributes?.lastSaved === PresentationTypes.ClientIntro 
  ? video.presentationAttributes?.clientIntro_videoIntro
  : video.presentationAttributes?.startIntro_companyMoreInfo) || 'We are building content. Welcome back later.';

  useEffect(() => {
    async function fetchCompanyInfo(companyUId) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/company/findByEntityKey/${companyUId}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setCompanyInfo(response.data);
        }
      } catch (e) {
        setError('Server error, please try again later.');
      }
    }

    if (companyKey) {
      const company = GetCompanyAttributes(
        searchCompanyResult,
        'company',
        companyKey
      );
      if (company) {
        setCompanyInfo(company);
      } else {
        fetchCompanyInfo(companyKey);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyKey]);

  useEffect(() => {
    async function fetchCompanyJobsInfo(companyUId) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/company/job/findByCompanyKey/${companyUId}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          const jobsWithCompanyName = response.data.map(job => ({
            ...job,
            companyName: companyInfo.companyName,
          }));
          setCompanyJobsInfo(jobsWithCompanyName);
        }
      } catch (e) {
        setError('Server error, please try again later.');
      }
    }

    if (companyKey) {
      const jobs = GetCompanyAttributes(
        searchCompanyResult,
        'jobs',
        companyKey
      );
      const jobsWithCompanyName = jobs?.map(job => ({
        ...job,
        companyName: companyInfo.companyName
      }));

      if (jobs && jobs.length > 0) {
        setCompanyJobsInfo(jobsWithCompanyName);
      } else {
        fetchCompanyJobsInfo(companyKey);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyKey, companyInfo.companyName]);

  return (
    <div className="videoMetaData py-2">
      {error && (
        <div>
          <p>{error.message}</p>
        </div>
      )}
      <div className="videoMetaData_top d-flex justify-content-between align-items-center">
        <h5>{companyInfo?.companyName || 'Company Name'}</h5>
        {companyInfo?.companyAttributes?.websiteLink && (
          <a
            href={companyInfo?.companyAttributes?.websiteLink}
            target="_blank"
            rel="noopener noreferrer"
            className="d-flex align-items-center"
          >
            <FaGlobe />
            <span className="ml-2">Website</span>
          </a>
        )}
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <Share
          buttonStyle={companyShareButtonStyle}
          buttonText="Share Company"
          modalText={`Share ${
            companyInfo?.companyName || 'Company Name'
          } with your friends`}
          companyKey={video.companyKey}
          referrerTalentUserKey={userInfo.userKey}
        />
      </div>
      <div className="d-flex flex-column justify-content-left align-items-start pt-5">
        <h2 className="text-success font-weight-bold">About Us</h2>
        <h5>
          <ShowMoreText
            lines={10}
            more="->Show More"
            less="<-Show Less"
            anchorClass="showMoreText"
            expanded={false}
          >
            {aboutUs}
          </ShowMoreText>
        </h5>
      </div>
      <div className="d-flex flex-column justify-content-left align-items-start pt-5">
        <h2 className="text-success font-weight-bold">Roles</h2>
        {companyJobsInfo &&
          companyJobsInfo.map((item) => {
            if (item.jobAttributes.closed === 'true') {
              return null;
            }
            return (
              <JobCard
                key={item.entityKey}
                job={item}
              />
            );
          })}
        {!companyJobsInfo && (
          <span className="mt-3 d-inline-block text-center text-primary">
            no jobs found.
          </span>
        )}
      </div>
    </div>
  );
}

VideoInfo.propTypes = {
  video: PropTypes.object.isRequired,
};

export default VideoInfo;
