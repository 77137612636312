import React from 'react';
import {Modal, Button} from 'react-bootstrap';

import PropTypes from 'prop-types';

function CompanyPresentationUpdateSavePublishedModal({
  handleSubmit,
  setShowModal,
  showModal,
}) {
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      centered
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>Save change without publishing?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Your content will be saved without being published. To have it
          published, please go to tab “Preview” or “Publish” accordingly.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

CompanyPresentationUpdateSavePublishedModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default CompanyPresentationUpdateSavePublishedModal;
