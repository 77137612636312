// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';

function checkTokenValid(token) {
  try {
    const {exp} = jwt_decode(token);
    const now = Date.now().valueOf() / 1000;
    if (typeof exp !== 'undefined' && exp < now) {
      localStorage.removeItem('authenticated');
      localStorage.removeItem('entityKey');
      localStorage.removeItem('userKey');
      localStorage.removeItem('companyKey');
      localStorage.removeItem('firstName');
      localStorage.removeItem('lastName');
      localStorage.removeItem('userTypes');
      localStorage.removeItem('loggedInAsUserType');
      localStorage.removeItem('emailAddress');
      localStorage.removeItem('token');
      window.location = '/signin/';
      throw new Error(`token expired: ${JSON.stringify(exp)} < ${now}`);
    } else {
      console.log(`The token is still valid: ${JSON.stringify(exp)} >= ${now}`);
    }
  } catch (error) {
    window.location = '/signin/';
  }
}

export default checkTokenValid;
