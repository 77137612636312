import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {Row, Col, Card} from 'react-bootstrap';
import moment from 'moment';
import {userSettings, systemAlertInfoState} from '../../store/atoms';
import SystemAlert from '../SystemAlert';

function UserFeedbackDraft() {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const [feedbackList, setFeedbackList] = useState([]);
  const systemAlertInfo = useRecoilValue(systemAlertInfoState);

  useEffect(() => {
    setFeedbackList([]);
    async function fetchUnsentFeedback() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/${userInfo.loggedInAsUserType.toLowerCase()}/feedback/send/findByUnsent/${
            userInfo.userKey
          }`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setFeedbackList(response.data);
        }
      } catch (error) {
        console.error('Failed to get sent feedback - ', error);
      }
    }
    if (userInfo.userKey) {
      fetchUnsentFeedback();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {systemAlertInfo.show && (
        <Col>
          <SystemAlert info={systemAlertInfo} />
        </Col>
      )}
      <p>User Feedback Draft</p>
      <Row className="mt-1">
        {feedbackList &&
          feedbackList.map((item) => (
            <FeedbackCard
              key={item.entityKey}
              feedback={item}
              userInfo={userInfo}
              apiServer={API_SERVER_URL}
              feedbackList={feedbackList}
              setFeedbackList={setFeedbackList}
            />
          ))}
      </Row>
    </>
  );
}

function FeedbackCard({
  apiServer,
  feedback,
  feedbackList,
  setFeedbackList,
  userInfo,
}) {
  const setSystemAlertInfoState = useSetRecoilState(systemAlertInfoState);
  const handleDelete = () => {
    async function deleteFeedback() {
      try {
        const response = await axios.delete(
          `${apiServer}/api/${userInfo.loggedInAsUserType.toLowerCase()}/feedback/send/deleteByEntityKey/${
            feedback.entityKey
          }`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setFeedbackList(
            feedbackList.filter((msg) => msg.entityKey !== feedback.entityKey)
          );
        }
      } catch (error) {
        console.error('Failed to delete feedback - ', error);
      }
    }
    if (userInfo.userKey) {
      deleteFeedback();
    }
  };

  const handleSend = () => {
    async function sendWithDraft() {
      try {
        const sendResponse = await axios.get(
          `${apiServer}/api/${userInfo.loggedInAsUserType.toLowerCase()}/feedback/send/sendByEntityKey/${
            feedback.entityKey
          }`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (sendResponse.status === 200) {
          setSystemAlertInfoState({
            show: 'true',
            status: 'success',
            title: 'Send feedback success',
            body: '',
          });
          setFeedbackList(
            feedbackList.filter((msg) => msg.entityKey !== feedback.entityKey)
          );
        }
      } catch (error) {
        console.error('Failed to sent feedback - ', error);
      }
    }

    if (userInfo.userKey) {
      sendWithDraft();
    }
  };

  return (
    <Col md={12} className="my-3">
      <Card className="bg-light">
        <Card.Header />
        <Card.Body>
          <dl className="row my-3">
            <dt className="col-sm-4 text-muted">Subject:</dt>
            <dd className="col-sm-8">
              {feedback.sentFeedbackAttributes?.subject}
            </dd>

            <dt className="col-sm-4 text-muted">Message:</dt>
            <dd className="col-sm-8">
              {feedback.sentFeedbackAttributes?.body}
            </dd>

            <dt className="col-sm-4 text-muted">Time:</dt>
            <dd className="col-sm-8">
              {moment(feedback.sentFeedbackAttributes?.modifyTime).format(
                'MMMM Do YYYY, h:mm:ss a'
              )}
            </dd>
          </dl>
        </Card.Body>
        <Card.Footer>
          <button
            type="button"
            className="btn btn-md btn-outline-success rounded-pill"
            onClick={handleDelete}
          >
            Delete
          </button>
          <button
            type="button"
            className="btn btn-md btn-outline-success rounded-pill"
            onClick={handleSend}
            style={{margin: '0px 0px 0px 20px'}}
          >
            Send
          </button>
        </Card.Footer>
      </Card>
    </Col>
  );
}

FeedbackCard.propTypes = {
  feedback: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  apiServer: PropTypes.string.isRequired,
  feedbackList: PropTypes.array.isRequired,
  setFeedbackList: PropTypes.func.isRequired,
};

export default UserFeedbackDraft;
