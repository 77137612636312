import React, {useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {useRecoilValue, useRecoilState} from 'recoil';
import axios from 'axios';
import {userSettings, talentFavoriteCompanyListState} from '../store/atoms';
import CompanyFavoriteCard from './CompanyFavoriteCard';
import TalentMainCompanyLinks from './TalentMainCompanyLinks';

function TalentFavoriteCompany() {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const [talentFavoriteCompanyList, setTalentFavoriteCompanyList] =
    useRecoilState(talentFavoriteCompanyListState);

  useEffect(() => {
    async function fetchFavoriteList() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/favorite/company/findFavoriteDetailByUserKey/${userInfo.userKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setTalentFavoriteCompanyList(response.data);
        }
      } catch (error) {
        console.error(
          'TalentFavoriteCompany found FavoriteList from remote error - ',
          error
        );
      }
    }

    fetchFavoriteList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentFavoriteCompanyList.length]);

  const connectCompany = async (companyName, companyKey) => {
    console.log('Talent click to connect company - ', companyName, companyKey);
  };

  const addToFavorite = async (companyKey) => {
    const newFavorite = {
      userKey: userInfo.userKey,
      companyKey,
    };
    try {
      const response = await axios.post(
        `${API_SERVER_URL}/api/talent/favorite/company/addFavorite`,
        newFavorite,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        console.log(
          'TalentFavoriteCompany add new favorite cpmpany success -',
          response.data.companyKey
        );
        setTalentFavoriteCompanyList((oldFavoriteCompanyList) => [
          ...oldFavoriteCompanyList,
          response.data,
        ]);
      }
    } catch (error) {
      console.log(
        'TalentFavoriteCompany add new favorite cpmpany error -',
        error
      );
    }
  };

  const deleteFavorite = async (favoriteEntityKey) => {
    try {
      const response = await axios.delete(
        `${API_SERVER_URL}/api/talent/favorite/company/deleteFavoriteByEntityKey/${favoriteEntityKey}`,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (response.status === 200) {
        const favoriteIndex = talentFavoriteCompanyList.findIndex(
          (listItem) => listItem.entityKey === favoriteEntityKey
        );
        const newFavoriteList = [
          ...talentFavoriteCompanyList.slice(0, favoriteIndex),
          ...talentFavoriteCompanyList.slice(favoriteIndex + 1),
        ];
        setTalentFavoriteCompanyList(newFavoriteList);
      }
    } catch (error) {
      console.log(
        'TalentFavoriteCompany delete favorite company error -',
        error
      );
    }
  };

  return (
    <Container>
      <Row className="my-3">
        <Col>
          <TalentMainCompanyLinks linkKey="companyFavorited" />
        </Col>
      </Row>
      <Row className="mt-1">
        {/* {JSON.stringify(talentFavoriteCompanyList)} */}
        {talentFavoriteCompanyList &&
          talentFavoriteCompanyList.map((item) => (
            <CompanyFavoriteCard
              companyInfo={item}
              key={item.entityKey}
              onAddFavorite={addToFavorite}
              onDeleteFavorite={deleteFavorite}
              onConnectCompany={connectCompany}
            />
          ))}
      </Row>
    </Container>
  );
}

export default TalentFavoriteCompany;
