import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import {useRecoilValue} from 'recoil';
import axios from 'axios';
import {userSettings} from '../store/atoms';
import CompanyPresentationPreviewCard from './CompanyPresentationPreviewCard';

function CompanyPresentationCardModal({
  onHidePresentationModal,
  presentationKey,
  showPresentationModal,
}) {
  const userInfo = useRecoilValue(userSettings);
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const [presentationInfo, setPresentationInfo] = useState({});

  useEffect(() => {
    async function fetchCompanyPresentation() {
      try {
        const findPresentationResponse = await axios.get(
          `${API_SERVER_URL}/api/company/presentation/findByEntityKey/${presentationKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (findPresentationResponse.status === 200) {
          setPresentationInfo(findPresentationResponse.data);
        }
      } catch (error) {
        console.error(
          'CompanyPresentationCardModal found company Presentation error - ',
          error
        );
      }
    }

    if (presentationKey) {
      fetchCompanyPresentation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <form>
      <Modal
        centered
        size="lg"
        show={showPresentationModal}
        onHide={onHidePresentationModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-muted"
          >
            Company Presentation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CompanyPresentationPreviewCard presentation={presentationInfo} />
        </Modal.Body>
        <Modal.Footer>
          <fieldset className="w-100">
            <button
              type="button"
              className="mx-2 btn btn-outline-secondary px-5 my-3 rounded-pill float-right "
              onClick={onHidePresentationModal}
            >
              Close
            </button>
          </fieldset>
        </Modal.Footer>
      </Modal>
    </form>
  );
}

CompanyPresentationCardModal.propTypes = {
  presentationKey: PropTypes.string.isRequired,
  showPresentationModal: PropTypes.bool.isRequired,
  onHidePresentationModal: PropTypes.func.isRequired,
};

export default CompanyPresentationCardModal;
