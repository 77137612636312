import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Card, ButtonGroup, Button} from 'react-bootstrap';
import {useRecoilValue} from 'recoil';
import moment from 'moment';
import axios from 'axios';
import {userSettings} from '../store/atoms';
import ApplicantInformationModal from '../common/ApplicantInformationModal';
import {ApplicantMessageModal} from './ApplicantMessageModal';

function JobApplicant({applicant}) {
  const [canViewMore, setCanViewMore] = useState(false);
  const [showModal, setShowModal] = useState(false);

  return (
    <Card border="success" className="mt-3">
      <Card.Header>{applicant.appliedJobAttributes?.userName}</Card.Header>
      <Card.Body>
        <dl className="row my-3">
          <dt className="col-sm-4 text-muted">Applied Job Title:</dt>
          <dd className="col-sm-8">
            {applicant.appliedJobAttributes?.jobTitle}
          </dd>

          <dt className="col-sm-4 text-muted">User ID:</dt>
          <dd className="col-sm-8">{applicant.userKey}</dd>

          <dt className="col-sm-4 text-muted">Applied Time:</dt>
          <dd className="col-sm-8">
            {moment(applicant.appliedJobAttributes?.updatedTimestamp).format(
              'MMMM Do YYYY, h:mm:ss a'
            )}
          </dd>
        </dl>
      </Card.Body>
      <Card.Footer className="bg-light">
        <Row>
          <Col md={8}>
            <button
              type="button"
              className="btn btn-outline-success rounded-pill mr-3"
              onClick={() => {
                setCanViewMore(!canViewMore);
              }}
            >
              View Applicant
            </button>
          </Col>
          <Col md={4} className="text-right">
            <ButtonGroup>
              <Button variant="light" onClick={() => setShowModal(!showModal)}>
                <i className="bi bi-chat-square-dots" />
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        {showModal && (
          <ApplicantMessageModal
            showModal={showModal}
            setShowModal={setShowModal}
            item={applicant}
          />
        )}
      </Card.Footer>
      {canViewMore && (
        <ApplicantInformationModal
          applicant={applicant}
          showApplyModal={canViewMore}
          onHideApplyModal={() => setCanViewMore(false)}
        />
      )}
    </Card>
  );
}

function CompanyJobApplicants({job}) {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const [jobApplicants, setJobApplicants] = useState([]);
  const userInfo = useRecoilValue(userSettings);

  useEffect(() => {
    async function fetchTalentApplicantsList() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/job/findAppliedJobWithTimeStampByJobKey/${job.entityKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setJobApplicants(response.data);
        }
      } catch (error) {
        console.error(
          'CompanyJobApplicants found ApplicationList from remote error - ',
          error
        );
      }
    }

    if (job.entityKey) {
      fetchTalentApplicantsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      <Col md={{span: 10, offset: 1}} className="my-2 shadow rounded">
        {jobApplicants &&
          jobApplicants.map((item) => (
            <JobApplicant key={item.entityKey} applicant={item} />
          ))}
      </Col>
    </Row>
  );
}

JobApplicant.propTypes = {
  applicant: PropTypes.object.isRequired,
};

CompanyJobApplicants.propTypes = {
  job: PropTypes.object.isRequired,
};

export default CompanyJobApplicants;
