import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Modal, Form} from 'react-bootstrap';
import axios from 'axios';
import {useRecoilValue} from 'recoil';
import fp from 'lodash/fp';
import {userSettings} from '../../store/atoms';

export function FindMessageModal({item, setShowModal, showModal, toType}) {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const handleClose = () => setShowModal(false);
  const [subject, setSubject] = useState('');
  const [messageContent, setMessageContent] = useState('');
  const [availableDelete, setAvailableDelete] = useState(false);
  const [entityKey, setEntityKey] = useState('');
  const [existDraft, setExistDraft] = useState(false);
  const [companyUserKey, setCompanyUserKey] = useState(undefined);

  useEffect(() => {
    async function fetchUnsentMessage() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/${userInfo.loggedInAsUserType.toLowerCase()}/message/send/findByUnsent/${
            userInfo.userKey
          }`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          const savedMsg = fp.filter((el) => {
            if (userInfo.userTypes === 'INVESTOR') {
              return el.sentMessageAttributes.name === item.companyName;
            }
            return (
              el.sentMessageAttributes.name === item.companyName &&
              !el.sentMessageAttributes?.jobTitle
            );
          })(response.data);

          if (savedMsg.length !== 0) {
            setAvailableDelete(true);
            setSubject(savedMsg[0].sentMessageAttributes?.subject);
            setMessageContent(savedMsg[0].sentMessageAttributes?.body);
            setEntityKey(savedMsg[0].entityKey);
            setExistDraft(true);
          }
        }
      } catch (error) {
        console.error('Constructor Failed to get draft messages - ', error);
      }
    }

    async function fetchCompanyInfo() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/company/user/findByCompanyKey/${item.entityKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          setCompanyUserKey(response.data[0].userKey);
        }
      } catch (error) {
        console.error('Failed to get inbox messages - ', error);
      }
    }

    if (userInfo.userKey) {
      if (toType === 'COMPANY') {
        fetchCompanyInfo();
      }
      fetchUnsentMessage();
    }
    // eslint-disable-next-line
  }, []);

  const handleSave = () => {
    async function saveToDraftFirst() {
      try {
        const request = {
          fromUserKey: userInfo.userKey,
          toUserKey: companyUserKey,
          toUserType: toType,
          sentMessageAttributes: {
            key: item.entityKey,
            name: item.companyName,
            subject,
            body: messageContent,
            modifyTime: new Date(),
            id: new Date() + userInfo.userKey,
            userType: userInfo.loggedInAsUserType,
            originMsgSub: subject,
            originMsgBody: messageContent,
          },
        };

        const response = await axios.post(
          `${API_SERVER_URL}/api/${userInfo.loggedInAsUserType.toLowerCase()}/message/send/create`,
          request,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setEntityKey(response.data.entityKey);
          setExistDraft(true);
          setAvailableDelete(true);
        }
      } catch (error) {
        console.error('Failed to save draft messages - ', error);
      }
    }

    async function saveToDraftNotFirst() {
      try {
        const request = {
          fromUserKey: userInfo.userKey,
          toUserKey: companyUserKey,
          toUserType: toType,
          sentMessageAttributes: {
            key: item.entityKey,
            name: item.companyName,
            subject,
            body: messageContent,
            modifyTime: new Date(),
            id: new Date() + userInfo.userKey,
            userType: userInfo.loggedInAsUserType,
            originMsgSub: subject,
            originMsgBody: messageContent,
          },
        };
        const response = await axios.put(
          `${API_SERVER_URL}/api/${userInfo.loggedInAsUserType.toLowerCase()}/message/send/updateByEntityKey/${entityKey}`,
          request,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setAvailableDelete(true);
        }
      } catch (error) {
        console.error('Failed to update draft message - ', error);
      }
    }

    if (userInfo.userKey) {
      if (existDraft) {
        saveToDraftNotFirst();
      } else {
        saveToDraftFirst();
      }
    }
  };

  const handleSend = () => {
    async function sendWithDraft() {
      try {
        const sendResponse = await axios.get(
          `${API_SERVER_URL}/api/${userInfo.loggedInAsUserType.toLowerCase()}/message/send/sendByEntityKey/${entityKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (sendResponse.status === 200) {
          console.log('Sent messages - ', sendResponse.data.length);
        }
      } catch (error) {
        console.error('Failed to get sent messages - ', error);
      }
    }

    async function sendWithoutDraft() {
      try {
        const request = {
          fromUserKey: userInfo.userKey,
          toUserKey: companyUserKey,
          toUserType: toType,
          sentMessageAttributes: {
            key: item.companyKey,
            name: item.companyName,
            subject,
            body: messageContent,
            modifyTime: new Date(),
            id: new Date() + userInfo.userKey,
            userType: userInfo.loggedInAsUserType,
            originMsgSub: subject,
            originMsgBody: messageContent,
          },
        };
        const response = await axios.post(
          `${API_SERVER_URL}/api/${userInfo.loggedInAsUserType.toLowerCase()}/message/send/create`,
          request,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          try {
            const sendResponse = await axios.get(
              `${API_SERVER_URL}/api/${userInfo.loggedInAsUserType.toLowerCase()}/message/send/sendByEntityKey/${
                response.data.entityKey
              }`,
              {headers: {Authorization: `Bearer ${userInfo.token}`}}
            );
            if (sendResponse.status === 200) {
              console.log('Sent messages - ', sendResponse.data);
            }
          } catch (error) {
            console.error('Failed to get sent messages - ', error);
          }
        }
      } catch (error) {
        console.error('Failed to get unsent messages - ', error);
      }
    }

    if (userInfo.userKey) {
      if (existDraft) {
        sendWithDraft();
      } else {
        sendWithoutDraft();
      }
      setShowModal(false);
    }
  };

  const handleDelete = () => {
    async function fetchUnsentMessage() {
      try {
        const response = await axios.delete(
          `${API_SERVER_URL}/api/${userInfo.loggedInAsUserType.toLowerCase()}/message/send/deleteByEntityKey/${entityKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setSubject('');
          setMessageContent('');
          setAvailableDelete(false);
        }
      } catch (error) {
        console.error('Failed to get draft messages - ', error);
      }
    }
    if (userInfo.userKey) {
      fetchUnsentMessage();
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Send a message</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <dl className="row my-2">
          <dt className="col-sm-1">To:</dt>
          <dd className="col-sm-9">{item.companyName}</dd>
        </dl>
        <Form>
          <Form.Group className="mb-3" controlId="validationCustom01">
            <Form.Label>Subject Re:</Form.Label>
            <Form.Control
              toType="textarea"
              value={subject}
              onChange={(event) => setSubject(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="validationCustom02">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={messageContent}
              onChange={(event) => setMessageContent(event.target.value)}
              required
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-md btn-outline-success rounded-pill"
          disabled={!availableDelete}
          onClick={handleDelete}
        >
          Delete
        </button>
        <button
          type="button"
          className="btn btn-md btn-outline-success rounded-pill"
          onClick={handleSave}
        >
          Save
        </button>
        <button
          type="button"
          className="btn btn-md btn-outline-success rounded-pill"
          onClick={handleSend}
        >
          Send
        </button>
      </Modal.Footer>
    </Modal>
  );
}

FindMessageModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  toType: PropTypes.string.isRequired,
};

export default FindMessageModal;
