import React from 'react';
import axios from 'axios';
import {Container} from 'react-bootstrap';

class AddTypeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (localStorage.getItem('authenticated') === 'true') {
      this.setState({authenticated: true});
      this.setState({userTypes: localStorage.getItem('userTypes')});
    }
  }

  onChangeRadioValue = async (event) => {
    const currentTypeFromRadio = event.target.value;
    if (currentTypeFromRadio !== undefined) {
      this.setState({currentType: currentTypeFromRadio});
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const {currentType, loggedInAsUserType, userTypes} = this.state;

    const API_SERVER_URL = process.env.REACT_APP_API_URL;
    const ENABLE_INVESTOR_USER =
      process.env.REACT_APP_ENABLE_INVESTOR_USER === 'true';

    this.setState({userTypes: localStorage.getItem('userTypes')});
    this.setState({
      loggedInAsUserType: localStorage.getItem('loggedInAsUserType'),
    });
    this.setState({isError: false});
    this.setState({enableInvestor: ENABLE_INVESTOR_USER});

    if (currentType !== undefined) {
      const userEntityKey = localStorage.getItem('entityKey');
      const userToken = localStorage.getItem('token');
      const user = {
        userTypes: `${userTypes},${currentType}`,
      };

      // console.log("AddNewType post update:", JSON.stringify(user), userEntityKey, userToken);
      try {
        const [response1] = await Promise.all([
          axios.put(
            `${API_SERVER_URL}/api/user/updateByKey/${userEntityKey}`,
            user,
            {
              headers: {Authorization: `Bearer ${userToken}`},
            }
          ),
        ]);
        // console.log("AddNewType post update result:" + JSON.stringify(response1.data));
        localStorage.setItem('userTypes', response1.data.userTypes);
      } catch (e) {
        this.setState({isError: true});
        console.log(e);
        return;
      }

      if (loggedInAsUserType === 'TALENT') {
        window.location = '/talent/jobs';
        window.location.reload();
      } else if (loggedInAsUserType === 'INVESTOR') {
        window.location = '/investor/';
        window.location.reload();
      } else if (loggedInAsUserType === 'COMPANY') {
        window.location = '/company/';
        window.location.reload();
      } else {
        window.location = '/';
      }
    }
  };

  render() {
    const {authenticated, enableInvestor, isError, userTypes} = this.state;

    return (
      <Container className="py-5">
        <form onSubmit={this.handleSubmit}>
          <div>
            <div>
              <h2>Add Type</h2>
              <p>Use this page to add login types</p>
              {isError && (
                <small className="mt-3 d-inline-block text-danger">
                  Something went wrong. Please try again later.
                </small>
              )}
            </div>

            <div>
              <p>
                <b>Types Available</b>
              </p>
            </div>

            <div onChange={this.onChangeRadioValue}>
              {authenticated === true ? (
                <span>
                  {!userTypes.includes('COMPANY') ? (
                    <span className="mr-3">
                      <input type="radio" value="COMPANY" name="currentType" />{' '}
                      COMPANY
                    </span>
                  ) : (
                    <span />
                  )}
                  {!userTypes.includes('TALENT') ? (
                    <span className="mr-3">
                      <input type="radio" value="TALENT" name="currentType" />{' '}
                      TALENT
                    </span>
                  ) : (
                    <span />
                  )}
                  {!userTypes.includes('INVESTOR') && enableInvestor ? (
                    <span className="mr-3">
                      <input type="radio" value="INVESTOR" name="currentType" />{' '}
                      INVESTOR
                    </span>
                  ) : (
                    <span />
                  )}
                </span>
              ) : (
                <span />
              )}
            </div>

            <div>
              <p />
            </div>

            <button
              type="button"
              className="px-5 px-5 btn btn-warning rounded-pill"
            >
              Add Type!
            </button>
          </div>
        </form>
      </Container>
    );
  }
}

export default AddTypeForm;
