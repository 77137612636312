import React, {useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {useRecoilValue, useRecoilState} from 'recoil';
import axios from 'axios';
import {userSettings, talentApplicationListState} from '../store/atoms';
import JobApplyHistoryCard from './JobApplyHistoryCard';
import TalentMainJobLinks from './TalentMainJobLinks';

function TalentJobApplyHistory() {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const [talentApplicationList, setTalentApplicationList] = useRecoilState(
    talentApplicationListState
  );

  useEffect(() => {
    async function fetchTalentApplicationList() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/job/findAppliedJobWithTimeStampByUserKey/${userInfo.userKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setTalentApplicationList(response.data);
        }
      } catch (error) {
        console.error(
          'TalentJobApplyHistory found ApplicationList from remote error - ',
          error
        );
      }
    }

    const talentApplyJob = localStorage.getItem('talentApplyJob');
    if (talentApplicationList.length === 0 || talentApplyJob === 'update') {
      localStorage.setItem('talentApplyJob', '');
      fetchTalentApplicationList();
    } else {
      console.log(
        'TalentJobApplyHistory get talentApplicationListState - ',
        talentApplicationList.length
      );
    }
  }, [
    API_SERVER_URL,
    setTalentApplicationList,
    talentApplicationList,
    userInfo.token,
    userInfo.userKey,
  ]);

  const deleteAppliedJob = async (jobEntityKey) => {
    try {
      const response = await axios.delete(
        `${API_SERVER_URL}/api/talent/job/deleteAppliedJobByEntityKey/${jobEntityKey}`,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (response.status === 200) {
        const applyIndex = talentApplicationList.findIndex(
          (listItem) => listItem.entityKey === jobEntityKey
        );
        const newApplyList = [
          ...talentApplicationList.slice(0, applyIndex),
          ...talentApplicationList.slice(applyIndex + 1),
        ];
        setTalentApplicationList(newApplyList);
      }
    } catch (error) {
      console.log('JobSearchApplyCard delete apply error - ', error);
    }
  };

  return (
    <Container>
      <Row className="my-3">
        <Col>
          <TalentMainJobLinks linkKey="jobApplied" />
        </Col>
      </Row>
      <Row className="mt-1">
        {/* {JSON.stringify(talentApplicationList)} */}
        {talentApplicationList &&
          talentApplicationList
            .filter((item) => item.appliedJobAttributes.status === 'applied')
            .map((item) => (
              <JobApplyHistoryCard
                apply={item}
                key={item.entityKey}
                onDeleteFavorite={deleteAppliedJob}
              />
            ))}
      </Row>
    </Container>
  );
}

export default TalentJobApplyHistory;
