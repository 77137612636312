import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

function InvestorMessage() {
  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <h2>Investor Message</h2>
        </Col>
      </Row>
    </Container>
  );
}

export default InvestorMessage;
