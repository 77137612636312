import React, {useState, useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import axios from 'axios';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AccountNotVerified from './AccountNotVerified';

function ForgotPassword() {
  const [isNotVerified, setIsNotVerified] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSubmitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [submitButtonText, setSubmitButtonText] = useState('Submit');
  const API_SERVER_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const timer = setInterval(() => {
      if (countdown > 0 && isSubmitButtonDisabled) {
        setCountdown(prevCountdown => prevCountdown - 1);
        setSubmitButtonText(`Please wait ${countdown} seconds...`);
      } else {
        setSubmitted(false);
        setSubmitButtonDisabled(false);
        setSubmitButtonText('Submit');
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [countdown, isSubmitButtonDisabled]);

  const validationSchema = yup.object().shape({
    email: yup.string().required('Email is required').email(),
  });

  const {
    formState: {errors},
    handleSubmit,
    register,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  async function checkAccountVerified(email) {
    try {
      const fetchAPI = `${API_SERVER_URL}/api/user/public/checkIsVerified/${email}`;
      const response = await axios.get(fetchAPI);
      if (response.status === 200) {
        if (response.data === true) {
          return true;
        }

        setIsNotVerified(true);
        setSubmitButtonDisabled(false);
        return false;
      }
    } catch (error) {
      setIsError(true);
      setSubmitButtonDisabled(false);
    }

    return false;
  }

  const handleForgotSubmit = async (data) => {
    setSubmitted(false);
    setIsError(false);
    setSubmitButtonDisabled(true);
    setCountdown(60);
    const accountVerified = await checkAccountVerified(data.email);
    if (accountVerified) {
      try {
        const userEmail = {email: data.email};
        const sendUserEmailResponse = await axios.post(
          `${API_SERVER_URL}/api/user/sendResetPasswordEmail`,
          userEmail
        );
        if (sendUserEmailResponse.status === 200) {
          setSubmitted(true);
        } else {
          setIsError(true);
        }
      } catch (e) {
        setIsError(true);
      }
    }
  };

  if (isNotVerified) {
    return <AccountNotVerified />;
  }

  return (
    <Container className="">
      <Row>
        <Col className="order-md-1">
          <h3 className="mt-5 w-100">
            <span>
              The place where remote talents and American startup companies 
              join forces to build the future.
            </span>
          </h3>
        </Col>
        <Col className="order-md-2">
          <form onSubmit={handleSubmit(handleForgotSubmit)}>
            <div className="my-4">
              <label className="form-label">
                Please enter your email to reset your password
              </label>
              <input
                type="text"
                {...register('email')}
                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                id="userInputEmail"
                aria-describedby="userInputEmailHelp"
              />
              <div className="invalid-feedback">{errors.email?.message}</div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                disabled={isSubmitButtonDisabled}
                className="px-5 px-5 btn btn-success rounded-pill"
              >
                {submitButtonText}
              </button>
            </div>
            <div className="mb-3">
              {isError && (
                <small className="mt-3 d-inline-block text-danger">
                  Account doesn’t exist.
                </small>
              )}
              {submitted && (
                <small className="mt-3 d-inline-block text-success">
                  Please check your email to reset password. You may need to
                  check your spam folder.
                </small>
              )}
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default ForgotPassword;
