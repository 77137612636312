import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {Col, Row} from 'react-bootstrap';
import {userVideoWatchingState} from '../store/atoms';
import VideoPresentationBackground from './VideoPresentationImage';
import './videos.css';

function VideoHorizontal({searchScreen, video}) {
  const history = useHistory();
  const [userSelected, setUserSelected] = useState(false);
  const [userVideoWatching, setUserVideoWatching] = useRecoilState(
    userVideoWatchingState
  );
  const handleVideoClick = () => {
    const userWatching = {...userVideoWatching, watchingId: video.entityKey};
    setUserVideoWatching(userWatching);
    history.push(`/talent/startmedia/video/${video.entityKey}`);
  };

  useEffect(() => {
    if (userVideoWatching?.watchingId === video.entityKey) {
      setUserSelected(true);
    }
  }, [video.entityKey, userVideoWatching.watchingId]);

  return (
    <Row
      className={`videoHorizontal ${
        userSelected ? 'videoSelected' : ''
      } m-1 py-2 algin-algin-item-center `}
    >
      <Col
        xs={6}
        md={searchScreen ? 4 : 6}
        className="videoHorizontal_left"
        onClick={handleVideoClick}
      >
        <VideoPresentationBackground
          video={video.presentationAttributes}
          howOverlayText="false"
        />
      </Col>
      <Col
        xs={6}
        md={searchScreen ? 8 : 6}
        className="videoHorizontal_right p-0"
      >
        <p className="videoHorizontal_title mb-1">
          {video?.presentationAttributes?.companyName || 'StartNation Partner'}
        </p>
      </Col>
    </Row>
  );
}

VideoHorizontal.propTypes = {
  searchScreen: PropTypes.bool.isRequired,
  video: PropTypes.object.isRequired,
};

export default VideoHorizontal;
