import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Row, Col, Card, ButtonGroup, Button, Modal} from 'react-bootstrap';
import {useRecoilValue} from 'recoil';
import axios from 'axios';
import {BsExclamationCircleFill} from 'react-icons/bs';
import CompanyJobDelete from './CompanyJobDelete';
import CompanyJobApplicants from './CompanyJobApplicants';
import {userSettings} from '../store/atoms';
import {JobInformationCard} from '../common/JobInformationCard';

function JobDetail({closed, job}) {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const [canViewMore, setCanViewMore] = useState(false);
  const [authDelete, setAuthDelete] = useState(false);
  const [canViewApplicants, setCanViewApplicants] = useState(false);
  const [applicants, setApplicants] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const userInfo = useRecoilValue(userSettings);
  const [jobClosed, setJobClosed] = useState(closed === 'true');

  useEffect(() => {
    async function fetchTalentApplicantsList() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/job/findAppliedJobWithTimeStampByJobKey/${job.entityKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setApplicants(response.data.length);
        }
      } catch (error) {
        console.error(
          'JobCard found ApplicationList from remote error - ',
          error
        );
      }
    }

    if (job.entityKey) {
      fetchTalentApplicantsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    const newJob = {
      jobTitle: job.jobTitle,
      jobAttributes: {
        acceptTrainingApplication:
          job.jobAttributes.acceptTrainingApplication === 'true',
        acceptVolunteerApplication:
          job.jobAttributes.acceptVolunteerApplication === 'true',
        description: job.jobAttributes.description,
        displayPresentation: job.jobAttributes.displayPresentation === 'true',
        education: job.jobAttributes.education,
        equityPay: job.jobAttributes.equityPay,
        other: job.jobAttributes.other,
        payment: job.jobAttributes.payment,
        skills: job.jobAttributes.skills,
        title: job.jobAttributes.title,
        closed: true,
      },
    };

    try {
      const response = axios.put(
        `${API_SERVER_URL}/api/company/job/updateByEntityKey/${job.entityKey}`,
        newJob,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        console.log('CompanyJobAdd update job success - ', response.data);
      }
    } catch (error) {
      console.log('CompanyJobAdd update job error - ', error);
    }
  };

  return (
    <Col className="my-3">
      <Card className="">
        <Card.Header>
          {job?.jobTitle} {job.jobAttributes?.jobTitle}{' '}
          {jobClosed && (
            <span
              style={{
                'margin-left': '20px',
                'font-size': '13px',
                color: '#ffba00',
              }}
            >
              {' '}
              <BsExclamationCircleFill /> Closed Job
            </span>
          )}
        </Card.Header>
        <Card.Body>
          <JobInformationCard job={job} full={canViewMore} />
        </Card.Body>
        <Card.Footer className="bg-light">
          <Row>
            <Col md={8}>
              <Link
                to={`/company/create/job/${job.entityKey}`}
                className="btn btn-outline-warning rounded-pill mr-3"
              >
                {jobClosed ? 'Copy and recreate' : 'Edit'}
              </Link>
              {!jobClosed && (
                <button
                  type="button"
                  className="btn btn-outline-warning rounded-pill mr-3"
                  onClick={() => {
                    setShowConfirmModal(!showConfirmModal);
                  }}
                >
                  Close
                </button>
              )}
            </Col>
            <Col md={4} className="text-right">
              <ButtonGroup>
                {applicants > 0 ? (
                  <Button
                    variant="light"
                    onClick={() => {
                      setCanViewApplicants(!canViewApplicants);
                    }}
                  >
                    <span className="badge badge-primary">
                      <i className="bi bi-people mr-1" /> {applicants} applicant
                      {applicants > 1 ? 's' : ''}
                    </span>
                  </Button>
                ) : (
                  <Button variant="light">
                    <span className="badge badge-secondary">0</span>
                  </Button>
                )}
                <Button
                  variant="light"
                  onClick={() => {
                    setCanViewMore(!canViewMore);
                  }}
                >
                  {canViewMore ? (
                    <i className="bi bi-three-dots-vertical" />
                  ) : (
                    <i className="bi bi-three-dots" />
                  )}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      {authDelete && (
        <Card border="danger" className="mt-3">
          <Card.Body>
            <CompanyJobDelete
              job={job}
              onCancelClick={() => {
                setAuthDelete(!authDelete);
              }}
            />
          </Card.Body>
        </Card>
      )}
      {canViewApplicants && (
        <CompanyJobApplicants
          job={job}
          onCancelClick={() => {
            setCanViewApplicants(!canViewApplicants);
          }}
        />
      )}
      <div>
        {showConfirmModal && (
          <Modal
            show={showConfirmModal}
            onHide={() => setShowConfirmModal(!showConfirmModal)}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm Close Posted Job</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              The posted job is no longer open for application by talent. User
              won’t be able to find the job posting in their search.
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowConfirmModal(!showConfirmModal)}
              >
                Back
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  setJobClosed(true);
                  setShowConfirmModal(!showConfirmModal);
                  handleClose();
                }}
              >
                OK
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </Col>
  );
}

JobDetail.propTypes = {
  job: PropTypes.object.isRequired,
  closed: PropTypes.bool.isRequired,
};

export default JobDetail;
