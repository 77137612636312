import React from 'react';
import {Modal, Form} from 'react-bootstrap';
import PropTypes from 'prop-types';

function JobListModal({item, setShowModal, showModal}) {
  const handleClose = () => setShowModal(false);
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Job List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {item.map((el, index) => (
          // eslint-disable-next-line react/jsx-key
          <dl className="row my-2">
            <dd className="col-sm-1">{index + 1}.</dd>
            <dd className="col-sm-4">{el.jobTitle}</dd>
          </dl>
        ))}
        <Form />
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
}

JobListModal.propTypes = {
  item: PropTypes.object.isRequired,
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default JobListModal;
