import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Card} from 'react-bootstrap';
import moment from 'moment';
import {useRecoilValue} from 'recoil';
import axios from 'axios';
import fp from 'lodash/fp';
import {userSettings} from '../store/atoms';
import CompanyInformationCardModal from './CompanyInformationCardModal';

export function JobFavoriteInformation({full, job}) {
  const userInfo = useRecoilValue(userSettings);
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const [canViewCompanyInfo, setCanViewCompanyInfo] = useState(false);
  const [webLink, setWebLink] = useState(undefined);

  async function fetchCompanyInfo(searchOptions) {
    try {
      const findCompaniesResponse = await axios.post(
        `${API_SERVER_URL}/api/company/findDetailsByKeyWord/`,
        searchOptions,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (findCompaniesResponse.status === 200) {
        const temp = fp.filter(
          (el) => el.company?.entityKey === job.companyKey
        )(findCompaniesResponse.data);
        try {
          const findPresentationResponse = await axios.get(
            `${API_SERVER_URL}/api/company/presentation/findByEntityKey/${temp[0].presentationKey}`,
            {headers: {Authorization: `Bearer ${userInfo.token}`}}
          );
          if (findPresentationResponse.status === 200) {
            setWebLink(
              findPresentationResponse.data.presentationAttributes
                .videoPresentationLink
            );
          }
        } catch (error) {
          console.error(
            'CompanyPresentationCardModal found company Presentation error - ',
            error
          );
        }
      }
    } catch (error) {
      console.error('Failed to get inbox messages - ', error);
    }
  }
  if (job?.companyName) {
    const searchOptions = {
      searchString: `${job.companyName.trim()}`,
      searchAttributes: '',
    };
    fetchCompanyInfo(searchOptions);
  }

  return (
    <>
      <dl className="row my-3">
        <dt className="col-sm-4 text-muted">Company Name:</dt>
        <dd
          className="col-sm-8"
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role="button"
          onClick={() => {
            setCanViewCompanyInfo(!canViewCompanyInfo);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setCanViewCompanyInfo(!canViewCompanyInfo);
            }
          }}
        >
          <u>{job?.companyName}</u>
          <i className="ml-1 bi bi-info-circle" />
        </dd>
      </dl>
      {canViewCompanyInfo && (
        <CompanyInformationCardModal companyKey={job.companyKey} />
      )}
      <dl className="row my-3">
        <dt className="col-sm-4 text-muted">Video Presentation:</dt>
        <dd className="col-sm-8">
          <a
            href={webLink}
            target="_blank"
            rel="noreferrer"
            style={{color: 'black'}}
          >
            {webLink}
          </a>
        </dd>
        <dt className="col-sm-4 text-muted">Skills Required:</dt>
        <dd className="col-sm-8">{job.jobAttributes?.skills}</dd>
        <dt className="col-sm-4 text-muted">Job ID:</dt>
        <dd className="col-sm-8">{job?.jobKey}</dd>
      </dl>
      {full && (
        <dl className="row my-3">
          <dt className="col-sm-4 text-muted">Years of Experience Required:</dt>
          <dd className="col-sm-8">{job.jobAttributes?.years}</dd>

          <dt className="col-sm-4 text-muted">Skills Required:</dt>
          <dd className="col-sm-8">{job.jobAttributes?.skills}</dd>

          <dt className="col-sm-4 text-muted">Education Required:</dt>
          <dd className="col-sm-8">{job.jobAttributes?.education}</dd>

          <dt className="col-sm-4 text-muted">Payment Type:</dt>
          <dd className="col-sm-8">{job.jobAttributes?.payment}</dd>

          {job.jobAttributes?.payment ===
            'Paid with partial cash and partial equity' && (
            <>
              <dt className="col-sm-4 text-muted">Cash payment amount:</dt>
              <dd className="col-sm-8">
                {job.jobAttributes?.cashPaymentAmount}
              </dd>
            </>
          )}

          {(job.jobAttributes?.payment ===
            'Paid with partial cash and partial equity' ||
            job.jobAttributes?.payment === 'Paid with equity only') && (
            <>
              <dt className="col-sm-4 text-muted">Equity payment amount:</dt>
              <dd className="col-sm-8">
                {job.jobAttributes?.equityPaymentAmount}
              </dd>
            </>
          )}

          {job.jobAttributes?.equityPay === 'Agree' && (
            <>
              <dt className="col-sm-4 text-muted">Equity Pay:</dt>
              <dd className="col-sm-8">
                Company provide award info and business status info for talents
                to manage equity pays.
              </dd>
            </>
          )}

          <dt className="col-sm-4 text-muted">
            Accept practical training application:
          </dt>
          <dd className="col-sm-8">
            {job.jobAttributes?.acceptTrainingApplication === true
              ? 'YES'
              : 'NO'}
          </dd>

          {job.jobAttributes?.displayPresentation === true && (
            <>
              <dt className="col-sm-4 text-muted">Company Profile Link:</dt>
              <dd className="col-sm-8" />
            </>
          )}

          {job.jobAttributes?.Other && (
            <>
              <dt className="col-sm-4 text-muted">Other:</dt>
              <dd className="col-sm-8">{job.jobAttributes?.Other}</dd>
            </>
          )}
        </dl>
      )}

      <Card.Text className="text-muted">
        Favorited at{' '}
        {moment(job?.favoriteTimestamp).format('MMMM Do YYYY, h:mm:ss a')}
      </Card.Text>
    </>
  );
}

export function JobSearchInformation({full, job}) {
  const userInfo = useRecoilValue(userSettings);
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const [canViewCompanyInfo, setCanViewCompanyInfo] = useState(false);
  const [webLink, setWebLink] = useState(undefined);

  async function fetchCompanyInfo(searchOptions) {
    try {
      const findCompaniesResponse = await axios.post(
        `${API_SERVER_URL}/api/company/findDetailsByKeyWord/`,
        searchOptions,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (findCompaniesResponse.status === 200) {
        const temp = fp.filter(
          (el) => el.company?.entityKey === job.companyKey
        )(findCompaniesResponse.data);
        try {
          const findPresentationResponse = await axios.get(
            `${API_SERVER_URL}/api/company/presentation/findByEntityKey/${temp[0].presentationKey}`,
            {headers: {Authorization: `Bearer ${userInfo.token}`}}
          );
          if (findPresentationResponse.status === 200) {
            setWebLink(
              findPresentationResponse.data.presentationAttributes
                .videoPresentationLink
            );
          }
        } catch (error) {
          console.error(
            'CompanyPresentationCardModal found company Presentation error - ',
            error
          );
        }
      }
    } catch (error) {
      console.error('Failed to get inbox messages - ', error);
    }
  }
  if (job?.companyName) {
    const searchOptions = {
      searchString: `${job.companyName.trim()}`,
      searchAttributes: '',
    };
    fetchCompanyInfo(searchOptions);
  }
  return (
    <>
      {job?.companyName && (
        <dl className="row my-3">
          <dt className="col-sm-4 text-muted">Company Name:</dt>
          <dd
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role="button"
            tabIndex={0}
            className="col-sm-8"
            onClick={() => {
              setCanViewCompanyInfo(!canViewCompanyInfo);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setCanViewCompanyInfo(!canViewCompanyInfo);
              }
            }}
          >
            <u>{job?.companyName}</u>
            <i className="ml-1 bi bi-info-circle" />
          </dd>
        </dl>
      )}
      {canViewCompanyInfo && (
        <CompanyInformationCardModal companyKey={job?.companyKey} />
      )}

      <dl className="row my-3">
        <dt className="col-sm-4 text-muted">Video Presentation:</dt>
        <dd className="col-sm-8">
          <a
            href={webLink}
            target="_blank"
            rel="noreferrer"
            style={{color: 'black'}}
          >
            {webLink}
          </a>
        </dd>
        <dt className="col-sm-4 text-muted">Skills Required:</dt>
        <dd className="col-sm-8">{job?.jobAttributes?.skills}</dd>
        <dt className="col-sm-4 text-muted">Job ID:</dt>
        <dd className="col-sm-8">{job?.entityKey}</dd>
      </dl>
      {full && (
        <dl className="row my-3">
          <dt className="col-sm-4 text-muted">Video Presentation:</dt>
          <dd className="col-sm-8">
            <a
              href={webLink}
              target="_blank"
              rel="noreferrer"
              style={{color: 'black'}}
            >
              {webLink}
            </a>
          </dd>

          <dt className="col-sm-4 text-muted">Years of Experience Required:</dt>
          <dd className="col-sm-8">{job?.jobAttributes?.years}</dd>

          <dt className="col-sm-4 text-muted">Skills Required:</dt>
          <dd className="col-sm-8">{job?.jobAttributes?.skills}</dd>

          <dt className="col-sm-4 text-muted">Education Required:</dt>
          <dd className="col-sm-8">{job?.jobAttributes?.education}</dd>

          <dt className="col-sm-4 text-muted">Payment Type:</dt>
          <dd className="col-sm-8">{job?.jobAttributes?.payment}</dd>

          {job.jobAttributes?.payment ===
            'Paid with partial cash and partial equity' && (
            <>
              <dt className="col-sm-4 text-muted">Cash payment amount:</dt>
              <dd className="col-sm-8">
                {job.jobAttributes?.cashPaymentAmount}
              </dd>
            </>
          )}

          {(job.jobAttributes?.payment ===
            'Paid with partial cash and partial equity' ||
            job.jobAttributes?.payment === 'Paid with equity only') && (
            <>
              <dt className="col-sm-4 text-muted">Equity payment amount:</dt>
              <dd className="col-sm-8">
                {job.jobAttributes?.equityPaymentAmount}
              </dd>
            </>
          )}

          {job?.jobAttributes?.equityPay === 'Agree' && (
            <>
              <dt className="col-sm-4 text-muted">Equity Pay:</dt>
              <dd className="col-sm-8">
                Company provide award info and business status info for talents
                to manage equity pays.
              </dd>
            </>
          )}

          <dt className="col-sm-4 text-muted">
            Accept practical training application:
          </dt>
          <dd className="col-sm-8">
            {job?.jobAttributes?.acceptTrainingApplication === true
              ? 'YES'
              : 'NO'}
          </dd>

          {job?.jobAttributes?.displayPresentation === true && (
            <>
              <dt className="col-sm-4 text-muted">Company Profile Link:</dt>
              <dd className="col-sm-8" />
            </>
          )}

          {job?.jobAttributes?.Other && (
            <>
              <dt className="col-sm-4 text-muted">Other:</dt>
              <dd className="col-sm-8">{job?.jobAttributes?.Other}</dd>
            </>
          )}
        </dl>
      )}
    </>
  );
}

export function JobInformationCard({full, job}) {
  return (
    <>
      <dl className="row my-3">
        {job?.companyName && (
          <>
            <dt className="col-sm-4 text-muted">Company Name:</dt>
            <dd className="col-sm-8">{job?.companyName}</dd>
          </>
        )}
        <dt className="col-sm-4 text-muted">Job ID:</dt>
        <dd className="col-sm-8">{job?.entityKey}</dd>

        <dt className="col-sm-4 text-muted">Skills Required:</dt>
        <dd className="col-sm-8">{job.jobAttributes?.skills}</dd>
      </dl>

      {full && (
        <>
          <dl className="row my-3">
            <dt className="col-sm-4 text-muted">
              Years of Experience Required:
            </dt>
            <dd className="col-sm-8">{job.jobAttributes?.years}</dd>

            <dt className="col-sm-4 text-muted">Skills Required:</dt>
            <dd className="col-sm-8">{job.jobAttributes?.skills}</dd>

            <dt className="col-sm-4 text-muted">Education Required:</dt>
            <dd className="col-sm-8">{job.jobAttributes?.education}</dd>

            <dt className="col-sm-4 text-muted">Payment Type:</dt>
            <dd className="col-sm-8">{job.jobAttributes?.payment}</dd>

            {job.jobAttributes?.payment ===
              'Paid with partial cash and partial equity' && (
              <>
                <dt className="col-sm-4 text-muted">Cash payment amount:</dt>
                <dd className="col-sm-8">
                  {job.jobAttributes?.cashPaymentAmount}
                </dd>
              </>
            )}

            {(job.jobAttributes?.payment ===
              'Paid with partial cash and partial equity' ||
              job.jobAttributes?.payment === 'Paid with equity only') && (
              <>
                <dt className="col-sm-4 text-muted">Equity payment amount:</dt>
                <dd className="col-sm-8">
                  {job.jobAttributes?.equityPaymentAmount}
                </dd>
              </>
            )}

            {job.jobAttributes?.equityPay === 'Agree' && (
              <>
                <dt className="col-sm-4 text-muted">Equity Pay:</dt>
                <dd className="col-sm-8">
                  Company provide award info and business status info for
                  talents to manage equity pays.
                </dd>
              </>
            )}

            <dt className="col-sm-4 text-muted">
              Accept practical training application:
            </dt>
            <dd className="col-sm-8">
              {job.jobAttributes?.acceptTrainingApplication === true
                ? 'YES'
                : 'NO'}
            </dd>

            {job.jobAttributes?.displayPresentation === true && (
              <>
                <dt className="col-sm-4 text-muted">Company Profile Link:</dt>
                <dd className="col-sm-8" />
              </>
            )}

            {job.jobAttributes?.Other && (
              <>
                <dt className="col-sm-4 text-muted">Other:</dt>
                <dd className="col-sm-8">{job.jobAttributes?.Other}</dd>
              </>
            )}
          </dl>

          <Card.Text className="text-muted" style={{whiteSpace: 'pre-line'}}>
            Job Description
          </Card.Text>
          <Card.Text>{job.jobAttributes?.description}</Card.Text>
        </>
      )}
    </>
  );
}

JobFavoriteInformation.propTypes = {
  job: PropTypes.object.isRequired,
  full: PropTypes.bool.isRequired,
};

JobInformationCard.propTypes = {
  job: PropTypes.object.isRequired,
  full: PropTypes.bool.isRequired,
};

JobSearchInformation.propTypes = {
  job: PropTypes.object.isRequired,
  full: PropTypes.bool.isRequired,
};

export default JobInformationCard;
