import React from 'react';
import {Navbar, Nav, Dropdown, DropdownButton} from 'react-bootstrap';

function MainFooter() {
  return (
    <footer className="bg-white">
      <div className="container my-0">
        <div className="row">
          <Navbar collapseOnSelect expand="lg" bg="medium">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto" />
              <Nav>
                <DropdownButton
                  title="About Us"
                  id="collasible-nav-dropdown"
                  drop="up"
                  variant="white"
                >
                  <Dropdown.Item href="/startout/about">
                    What we do
                  </Dropdown.Item>
                  <Dropdown.Item href="/startout/team">Our team</Dropdown.Item>
                  <Dropdown.Item href="/startout/vision">
                    Our vision
                  </Dropdown.Item>
                </DropdownButton>
                <Nav.Link href="/startout/contact">
                  <p className="text-dark">Contact us</p>
                </Nav.Link>
                <Nav.Link href="/startout/terms">
                  <p className="text-dark">User agreement</p>
                </Nav.Link>
                <p className="mt-2" style={{color: '#585757'}}>
                  &nbsp;&nbsp;&copy; 2022 StartNation, Inc. All Rights Reserved.
                </p>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    </footer>
  );
}

export default MainFooter;
