import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col, Modal, Alert} from 'react-bootstrap';
import {useRecoilValue, useRecoilState} from 'recoil';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import TalentProfile from './TalentProfile';
import TalentProfileDetail from './TalentProfileDetail';
import {
  userSettings,
  talentProfileListState,
  talentApplicationListState,
} from '../store/atoms';

function InfoTextForAlreadyApplied() {
  const [show, setShow] = useState(true);
  if (show) {
    return (
      <Alert variant="warning" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>
          Your application has been successfully submitted. 
          If you need to reapply, please delete it and then resubmit!
        </Alert.Heading>
      </Alert>
    );
  }

  return null;
}

function JobApplyCard({job, onHideApplyModal, showApplyModal}) {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const [talentProfileList, setTalentProfileList] = useRecoilState(
    talentProfileListState
  );
  const [talentApplicationList, setTalentApplicationList] = useRecoilState(
    talentApplicationListState
  );
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [applyStatus, setApplyStatus] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const has = Object.prototype.hasOwnProperty;
  const newApplicationRef = useRef({});
  let profile = {};

  const validationSchema = yup.object().shape({
    applyUserName: yup.string().required('Name is required'),
    applyUserEmail: yup.string().required('Email is required').email(),
    additional: yup
      .string()
      .max(512, 'Additional has a maximum limit of 512 characters'),
  });

  const applicationAttributesDefaultValues = {
    profileKey: `${talentProfileList[0]?.entityKey}`,
    provideProfile: true,
    applyUserName: `${userInfo.firstName} ${userInfo.lastName}`,
    applyUserEmail: `${userInfo.emailAddress}`,
  };

  const {
    formState: {errors, isSubmitting},
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: applicationAttributesDefaultValues,
    mode: 'onBlur',
  });

  useEffect(() => {
    async function fetchTalentProfilesSummary() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/profile/findByUserKeyWithFilter?userKey=${userInfo.userKey}&filter=summary`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          if (response.data.length > 0) {
            setTalentProfileList(response.data);
            setValue('profileKey', response.data[0]?.entityKey);
            localStorage.setItem('profiles', JSON.stringify(response.data));
            console.log(
              'JobApplyCard fetchTalentProfilesSummary - ',
              response.data.length
            );
          } else {
            setIsError(true);
            setIsSuccess(false);
          }
        }
      } catch (error) {
        console.log('JobApplyCard fetchTalentProfilesSummary error - ', error);
        setIsError(true);
        setIsSuccess(false);
      }
    }

    if (talentProfileList.length === 0) {
      fetchTalentProfilesSummary();
    } else {
      console.log(
        'JobApplyCard fetchTalentProfilesSummaryfrom state - ',
        talentProfileList.length
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentProfileList]);

  useEffect(() => {
    async function fetchJobApplyByUserKeyAndJobKey() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/job/findAppliedJobWithTimeStampByJobKeyAndUserKey?jobKey=${job.entityKey}&userKey=${userInfo.userKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          newApplicationRef.current = response.data;
          if (response.data.appliedJobAttributes?.status === 'applied') {
            setApplyStatus(true);
            const applyProfile = {
              profileName: response.data.appliedJobAttributes.profileName,
              profileAttributes: {
                isPublic: false,
                summary:
                  response.data.appliedJobAttributes
                    ?.profileAttributes__summary,
                workExperience:
                  response.data.appliedJobAttributes
                    ?.profileAttributes__workExperience,
                skills:
                  response.data.appliedJobAttributes?.profileAttributes__skills,
                certifications:
                  response.data.appliedJobAttributes
                    ?.profileAttributes__certifications,
                patents:
                  response.data.appliedJobAttributes
                    ?.profileAttributes__patents,
                publications:
                  response.data.appliedJobAttributes
                    ?.profileAttributes__publications,
                languages:
                  response.data.appliedJobAttributes
                    ?.profileAttributes__languages,
                education:
                  response.data.appliedJobAttributes
                    ?.profileAttributes__education,
                linkedInLink:
                  response.data.appliedJobAttributes
                    ?.profileAttributes__linkedInLink,
                resumeLink:
                  response.data.appliedJobAttributes
                    ?.profileAttributes__resumeLink,
                videoLink:
                  response.data.appliedJobAttributes
                    ?.profileAttributes__videoLink,
                otherLinks:
                  response.data.appliedJobAttributes
                    ?.profileAttributes__otherLinks,
                additionalInfo:
                  response.data.appliedJobAttributes
                    ?.profileAttributes__additionalInfo,
              },
            };
            setUserProfile(applyProfile);
          }
          setValue(
            'profileKey',
            response.data.appliedJobAttributes?.profileKey
          );
          setValue(
            'applyUserName',
            response.data.appliedJobAttributes?.userName
          );
          setValue(
            'applyUserEmail',
            response.data.appliedJobAttributes?.userEmail
          );
          setValue(
            'additional',
            response.data.appliedJobAttributes?.additional
          );
        }
      } catch (error) {
        console.log(
          'JobSearchApplyCard found job apply history error - ',
          error
        );
      }
    }

    if (job.entityKey) {
      fetchJobApplyByUserKeyAndJobKey();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyStatus]);

  async function fetchTalentProfile(talentProfileID) {
    try {
      const response = await axios.get(
        `${API_SERVER_URL}/api/talent/profile/findByEntityKey/${talentProfileID}`,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        profile = response.data;
      } else {
        setIsError(true);
        setIsSuccess(false);
      }
    } catch (error) {
      setIsError(true);
      setIsSuccess(false);
    }
  }

  const watchProfile = watch('profileKey', '');

  async function createApplication(data) {
    await fetchTalentProfile(data.profileKey);
    if (
      Object.keys(profile).length === 0 ||
      !has.call(profile, 'profileAttributes')
    ) {
      return;
    }

    const profileIndex = talentProfileList.findIndex(
      (listItem) => listItem.entityKey === data.profileKey
    );

    const newJob = {
      userKey: userInfo.userKey,
      jobKey: job.entityKey,
      appliedJobAttributes: {
        status: 'draft',
        profileName: talentProfileList[profileIndex].profileName,
        jobTitle: job.jobTitle,
        profileKey: data.profileKey,
        additional: data.additional,
        userName: data.applyUserName,
        userEmail: data.applyUserEmail,
        profileAttributes__summary: profile.profileAttributes?.summary,
        profileAttributes__workExperience:
          profile.profileAttributes?.workExperience,
        profileAttributes__skills: profile.profileAttributes?.skills,
        profileAttributes__certifications:
          profile.profileAttributes?.certifications,
        profileAttributes__patents: profile.profileAttributes?.patents,
        profileAttributes__publications:
          profile.profileAttributes?.publications,
        profileAttributes__languages: profile.profileAttributes?.languages,
        profileAttributes__education: profile.profileAttributes?.education,
        profileAttributes__linkedInLink:
          profile.profileAttributes?.linkedInLink,
        profileAttributes__resumeLink: profile.profileAttributes?.resumeLink,
        profileAttributes__videoLink: profile.profileAttributes?.videoLink,
        profileAttributes__otherLinks: profile.profileAttributes?.otherLinks,
        profileAttributes__additionalInfo:
          profile.profileAttributes?.additionalInfo,
        companyKey: job?.companyKey,
      },
    };

    try {
      const response = await axios.post(
        `${API_SERVER_URL}/api/talent/job/create`,
        newJob,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        setTalentApplicationList((oldTalentApplicationList) => [
          ...oldTalentApplicationList,
          response.data,
        ]);
        localStorage.setItem('talentApplyJob', 'update');
        setIsSuccess(true);
        newApplicationRef.current = response.data;
      }
    } catch (error) {
      setIsSuccess(false);
      setIsError(true);
    }
  }

  async function updateApplication(data) {
    await fetchTalentProfile(data.profileKey);
    if (
      Object.keys(profile).length === 0 ||
      !has.call(profile, 'profileAttributes')
    ) {
      return;
    }
    const profileIndex = talentProfileList.findIndex(
      (listItem) => listItem.entityKey === data.profileKey
    );
    const newJob = {
      appliedJobAttributes: {
        status: 'draft',
        profileName: talentProfileList[profileIndex].profileName,
        jobTitle: job.jobTitle,
        profileKey: data.profileKey,
        additional: data.additional,
        userName: data.applyUserName,
        userEmail: data.applyUserEmail,
        profileAttributes__summary: profile.profileAttributes?.summary,
        profileAttributes__workExperience:
          profile.profileAttributes?.workExperience,
        profileAttributes__skills: profile.profileAttributes?.skills,
        profileAttributes__certifications:
          profile.profileAttributes?.certifications,
        profileAttributes__patents: profile.profileAttributes?.patents,
        profileAttributes__publications:
          profile.profileAttributes?.publications,
        profileAttributes__languages: profile.profileAttributes?.languages,
        profileAttributes__education: profile.profileAttributes?.education,
        profileAttributes__linkedInLink:
          profile.profileAttributes?.linkedInLink,
        profileAttributes__resumeLink: profile.profileAttributes?.resumeLink,
        profileAttributes__videoLink: profile.profileAttributes?.videoLink,
        profileAttributes__otherLinks: profile.profileAttributes?.otherLinks,
        profileAttributes__additionalInfo:
          profile.profileAttributes?.additionalInfo,
        companyKey: job?.companyKey,
      },
    };
    try {
      const response = await axios.put(
        `${API_SERVER_URL}/api/talent/job/updateAppliedJobByEntityKey/${newApplicationRef.current.entityKey}`,
        newJob,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (response.status === 200) {
        const applyIndex = talentApplicationList.findIndex(
          (listItem) =>
            listItem.entityKey === newApplicationRef.current.entityKey
        );
        const newApplyList = [
          ...talentApplicationList.slice(0, applyIndex),
          response.data,
          ...talentApplicationList.slice(applyIndex + 1),
        ];
        setTalentApplicationList(newApplyList);
        localStorage.setItem('talentApplyJob', 'update');
        setIsSuccess(true);
        newApplicationRef.current = response.data;
      }
    } catch (error) {
      setIsSuccess(false);
      setIsError(true);
    }
  }

  async function onSave(data) {
    setIsError(false);
    setIsSuccess(false);
    if (Object.keys(newApplicationRef.current).length === 0) {
      await createApplication(data);
    } else {
      await updateApplication(data);
    }
    onHideApplyModal();
  }

  async function applyJob() {
    try {
      const response = await axios.get(
        `${API_SERVER_URL}/api/talent/job/apply/${newApplicationRef.current.entityKey}`,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (response.status === 200) {
        const applyIndex = talentApplicationList.findIndex(
          (listItem) =>
            listItem.entityKey === newApplicationRef.current.entityKey
        );
        if (applyIndex >= 0) {
          newApplicationRef.current = talentApplicationList[applyIndex];
        }
        const newApply = JSON.parse(JSON.stringify(newApplicationRef.current));
        newApply.appliedJobAttributes.status = 'applied';
        const newApplyList = [
          ...talentApplicationList.slice(0, applyIndex),
          newApply,
          ...talentApplicationList.slice(applyIndex + 1),
        ];
        setTalentApplicationList(newApplyList);
        setIsSuccess(true);
        setApplyStatus(true);
      }
    } catch (error) {
      setIsSuccess(false);
      setIsError(true);
    }
  }

  async function onApply(data) {
    setIsError(false);
    setIsSuccess(false);
    if (
      Object.keys(newApplicationRef.current).length === 0 ||
      !has.call(newApplicationRef.current, 'entityKey')
    ) {
      await createApplication(data);
    } else {
      await updateApplication(data);
    }
    return applyJob();
  }

  async function deleteApply() {
    try {
      const response = await axios.delete(
        `${API_SERVER_URL}/api/talent/job/deleteAppliedJobByEntityKey/${newApplicationRef.current.entityKey}`,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (response.status === 200) {
        const applyIndex = talentApplicationList.findIndex(
          (listItem) =>
            listItem.entityKey === newApplicationRef.current.entityKey
        );
        const newApplyList = [
          ...talentApplicationList.slice(0, applyIndex),
          ...talentApplicationList.slice(applyIndex + 1),
        ];
        setTalentApplicationList(newApplyList);
        setIsSuccess(true);
        setApplyStatus(false);
        onHideApplyModal();
      }
    } catch (error) {
      setIsSuccess(false);
      setIsError(true);
    }
  }

  const onDelete = () => {
    setIsError(false);
    setIsSuccess(false);
    return deleteApply();
  };

  return (
    <form>
      <Modal
        centered
        size="lg"
        show={showApplyModal}
        onHide={onHideApplyModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Apply - {job?.jobTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                {applyStatus && <InfoTextForAlreadyApplied />}
                <div>
                  {isError && !isSuccess && (
                    <small className="my-3 d-inline-block text-danger">
                    Failed for the role’s application. Please make sure a profile exists 
                    (check under “Profiles” menu for the user account) before applying and try again later.
                    </small>
                  )}
                  {isSuccess && (
                    <small className="my-3 d-inline-block text-success">
                      Submit success!
                    </small>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="shadow rounded">
                {applyStatus ? (
                  newApplicationRef.current.updatedTimestamp && (
                    <form className="form-group my-4">
                      <label
                        htmlFor="staticApplyTime"
                        className="col-form-label"
                      >
                        <i className="bi bi-calendar3 mr-1" />
                        Apply Time
                      </label>
                      <input
                        type="text"
                        readOnly
                        className="form-control-plaintext"
                        id="staticApplyTime"
                        value={new Date(
                          newApplicationRef.current.updatedTimestamp
                        ).toString()}
                      />
                    </form>
                  )
                ) : (
                  <div className="form-group my-4">
                    <label htmlFor="userProfile" className="col-form-label">
                      <i className="bi bi-file-earmark-person-fill mr-1" />
                      Profile<abbr className="text-danger">*</abbr>
                    </label>

                    <select
                      {...register('profileKey')}
                      className={`form-control ${
                        errors.profileKey ? 'is-invalid' : ''
                      }`}
                      id="userProfile"
                    >
                      {talentProfileList.length > 0 ? (
                        talentProfileList.map((item) => (
                          <option key={item?.entityKey} value={item?.entityKey}>
                            {item?.profileName}
                          </option>
                        ))
                      ) : (
                        <option value="">Choose...</option>
                      )}
                    </select>
                    <div className="invalid-feedback">
                      {errors.profileKey?.message}
                    </div>
                  </div>
                )}

                <div className="form-group my-4 ">
                  <label
                    htmlFor="userInputPersonalWebsiteUrl"
                    className="col-form-label"
                  >
                    <i className="bi bi-person-fill mr-1" />
                    Your Name
                    <abbr className="text-danger">*</abbr>
                  </label>
                  <input
                    type="text"
                    {...register('applyUserName')}
                    readOnly
                    className={`form-control ${
                      errors.applyUserName ? 'is-invalid' : ''
                    }`}
                    id="userInputPersonalWebsiteUrl"
                  />
                  <div className="invalid-feedback">
                    {errors.applyUserName?.message}
                  </div>
                </div>
                <div className="form-group my-4 ">
                  <label
                    htmlFor="userInputPersonalMediaUrl"
                    className="col-form-label"
                  >
                    <i className="bi bi-envelope-fill mr-1" />
                    Contact Email
                    <abbr className="text-danger">*</abbr>
                  </label>
                  <input
                    type="text"
                    {...register('applyUserEmail')}
                    readOnly
                    className={`form-control ${
                      errors.applyUserEmail ? 'is-invalid' : ''
                    }`}
                    id="userInputPersonalMediaUrl"
                  />
                  <div className="invalid-feedback">
                    {errors.applyUserEmail?.message}
                  </div>
                </div>
                <div className="form-group my-4">
                  <label htmlFor="userInputOther" className="col-form-label">
                    <i className="bi bi-info-square-fill mr-1" />
                    Additional information for recruiter
                  </label>
                  <textarea
                    rows="5"
                    name="additional"
                    type="text"
                    id="userInputOther"
                    {...register('additional')}
                    readOnly={!!applyStatus}
                    className={`form-control ${
                      errors.additional ? 'is-invalid' : ''
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.additional?.message}
                  </div>
                </div>
              </Col>
            </Row>

            {watchProfile && !applyStatus && (
              <>
                <Row className="pt-5">
                  <Col>
                    <p>
                      Your profile info
                    </p>
                  </Col>
                </Row>
                <Row className="pb-5">
                  <Col>
                    <TalentProfile profileID={watchProfile} />
                  </Col>
                </Row>
              </>
            )}

            {applyStatus && (
              <>
                <Row className="pt-5">
                  <Col>
                    <p>
                      Profile
                    </p>
                  </Col>
                </Row>
                <Row className="pb-5">
                  <Col>
                    <TalentProfileDetail profile={userProfile} />
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <fieldset className="w-100">
            {applyStatus && (
              <>
                <button
                  type="submit"
                  onClick={handleSubmit(onDelete)}
                  disabled={isSubmitting}
                  className="mx-2 btn btn-warning px-5 my-3 rounded-pill float-left"
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1" />
                  )}
                  Delete
                </button>

                <button
                  type="button"
                  className="mx-2 btn btn-outline-secondary px-5 my-3 rounded-pill float-right "
                  onClick={onHideApplyModal}
                >
                  Close
                </button>
              </>
            )}
            {!applyStatus && (
              <>
                {Object.keys(newApplicationRef.current).length !== 0 && (
                  <button
                    type="submit"
                    onClick={handleSubmit(onDelete)}
                    disabled={isSubmitting}
                    className="mx-2 btn btn-warning px-5 my-3 rounded-pill"
                  >
                    {isSubmitting && (
                      <span className="spinner-border spinner-border-sm mr-1" />
                    )}
                    Delete
                  </button>
                )}
                <button
                  type="submit"
                  onClick={handleSubmit(onSave)}
                  disabled={isSubmitting}
                  className="mx-2 btn btn-primary px-5 my-3 rounded-pill"
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1" />
                  )}
                  Save and apply later
                </button>
                <button
                  type="submit"
                  onClick={handleSubmit(onApply)}
                  disabled={isSubmitting}
                  className="float-right mx-2 btn btn-success px-5 my-3 rounded-pill"
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1" />
                  )}
                  Apply Now
                </button>
              </>
            )}
          </fieldset>
        </Modal.Footer>
      </Modal>
    </form>
  );
}

JobApplyCard.propTypes = {
  job: PropTypes.object.isRequired,
  showApplyModal: PropTypes.bool.isRequired,
  onHideApplyModal: PropTypes.func.isRequired,
};

export default JobApplyCard;
