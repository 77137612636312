import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Col, ButtonGroup, Button, Card} from 'react-bootstrap';
import {useRecoilValue, useRecoilState} from 'recoil';
import axios from 'axios';
import {
  userSettings,
  talentFavoriteCompanyListState,
  talentEquityHeldListState,
  talentCompanyInfoListState,
} from '../store/atoms';
import {CompanyInformationCard} from '../common/CompanyInformationCard';
import CompanyMessageModal from '../common/CompanyMessageModal';

function CompanyEquityHeldCard({
  companyInfo,
  onAddEquity,
  onAddFavorite,
  onDeleteEquity,
  onDeleteFavorite,
}) {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteKey, setFavoriteKey] = useState('');
  const [isHeld, setIsHeld] = useState(false);
  const [equityHeldKey, setEquityHeldKey] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [companyDetail, setCompanyDetail] = useState({});
  const talentFavoriteCompanyList = useRecoilValue(
    talentFavoriteCompanyListState
  );
  const talentEquityHeldList = useRecoilValue(talentEquityHeldListState);
  const [talentCompanyInfoList, setTalentCompanyInfoList] = useRecoilState(
    talentCompanyInfoListState
  );
  const has = Object.prototype.hasOwnProperty;

  useEffect(() => {
    if (talentFavoriteCompanyList && talentFavoriteCompanyList.length > 0) {
      talentFavoriteCompanyList.forEach((item) => {
        if (has.call(companyInfo, 'companyKey')) {
          if (item.companyKey === companyInfo.companyKey) {
            setIsFavorite(true);
            setFavoriteKey(item.entityKey);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentFavoriteCompanyList]);

  useEffect(() => {
    if (talentEquityHeldList && talentEquityHeldList.length > 0) {
      talentEquityHeldList.forEach((item) => {
        if (has.call(companyInfo, 'companyKey')) {
          if (item.companyKey === companyInfo.companyKey) {
            setIsHeld(true);
            setEquityHeldKey(item.entityKey);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentEquityHeldList]);

  useEffect(() => {
    async function fetchCompanyInfo() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/company/findByEntityKey/${companyInfo.companyKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          setCompanyDetail(response.data);
          const companyIndex = talentCompanyInfoList.findIndex(
            (listItem) => listItem.entityKey === companyInfo.companyKey
          );
          if (companyIndex < 0) {
            setTalentCompanyInfoList((oldTalentCompanyInfoList) => [
              ...oldTalentCompanyInfoList,
              response.data,
            ]);
          }
        }
      } catch (error) {
        console.error(
          'CompanyEquityHeldCard fetch company info error - ',
          error
        );
      }
    }

    if (has.call(companyInfo, 'companyName')) {
      setCompanyDetail(JSON.parse(JSON.stringify(companyInfo)));
    } else if (talentCompanyInfoList.length > 0) {
      const companyIndex = talentCompanyInfoList.findIndex(
        (listItem) => listItem.entityKey === companyInfo.companyKey
      );
      if (companyIndex >= 0) {
        setCompanyDetail(
          JSON.parse(JSON.stringify(talentCompanyInfoList[companyIndex]))
        );
      } else {
        fetchCompanyInfo();
      }
    } else {
      fetchCompanyInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInfo.companyKey]);

  return (
    <Col sm={12} md={6} xl={4} className="my-2">
      <Card bg="light" text="dark" className="text-center">
        <CompanyInformationCard companyInfo={companyDetail} />
        <Card.Footer className="bg-light text-right">
          <ButtonGroup>
            {isHeld ? (
              <Button
                variant="light"
                onClick={() => {
                  onDeleteEquity(`${equityHeldKey}`);
                  setIsFavorite(false);
                }}
              >
                <i className="bi bi-trash-fill" style={{color: 'orange'}} />
              </Button>
            ) : (
              <Button
                variant="light"
                onClick={() => onAddEquity(`${companyInfo.companyKey}`)}
              >
                <i className="bi bi-trash" />
              </Button>
            )}
            {isFavorite ? (
              <Button
                variant="light"
                onClick={() => {
                  onDeleteFavorite(`${favoriteKey}`);
                  setIsFavorite(false);
                }}
              >
                <i className="bi bi-heart-fill" style={{color: 'green'}} />
              </Button>
            ) : (
              <Button
                variant="light"
                onClick={() => onAddFavorite(`${companyInfo.companyKey}`)}
              >
                <i className="bi bi-heart" />
              </Button>
            )}
            <Button variant="light" onClick={() => setShowModal(!showModal)}>
              <i className="bi bi-chat-square-dots" />
            </Button>
            {showModal && (
              <CompanyMessageModal
                showModal={showModal}
                setShowModal={setShowModal}
                companyInfo={{
                  companyName: companyDetail.companyName,
                  ...companyInfo,
                }}
                type="equityHeldCompany"
              />
            )}
          </ButtonGroup>
        </Card.Footer>
      </Card>
    </Col>
  );
}

CompanyEquityHeldCard.propTypes = {
  companyInfo: PropTypes.object.isRequired,
  onAddFavorite: PropTypes.func.isRequired,
  onDeleteFavorite: PropTypes.func.isRequired,
  onAddEquity: PropTypes.func.isRequired,
  onDeleteEquity: PropTypes.func.isRequired,
};

export default CompanyEquityHeldCard;
