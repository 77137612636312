/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import uuid from 'react-uuid';
import {Container, Row, Col, Form, Button} from 'react-bootstrap';
import {useRecoilValue, useRecoilState, useSetRecoilState} from 'recoil';
import {
  userSettings,
  talentCompanySearchStringState,
  searchCompanyResultState,
  searchCompanyResultVideosState,
} from '../store/atoms';
import useMultiKeywordSearch from '../common/utils/UseMultiKeywordSearch';
import Post from '../media/post';

function TalentSearch() {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const SEARCH_URL = `${API_SERVER_URL}/api/company/findCompanyPresentationsAndJobsByKeyWordList`;
  const userInfo = useRecoilValue(userSettings);
  const [talentCompanySearchString, setTalentCompanySearchString] =
    useRecoilState(talentCompanySearchStringState);
  const [inputValue, setInputValue] = useState('');
  const [keywords, setKeywords] = useState(talentCompanySearchString);
  const {error, loading, presentations, resultData} = useMultiKeywordSearch(
    keywords,
    SEARCH_URL,
    userInfo.token
  );
  const has = Object.prototype.hasOwnProperty;
  const setSearchResult = useSetRecoilState(searchCompanyResultState);
  const setVideos = useSetRecoilState(searchCompanyResultVideosState);

  const handleSubmit = (event) => {
    event.preventDefault();
    const searchWords = inputValue.split(/\s+/);
    setKeywords(searchWords);
    setTalentCompanySearchString(searchWords);
  };

  useEffect(() => {
    if (resultData && presentations && !loading) {
      setSearchResult(resultData);
      setVideos(presentations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultData, presentations, loading]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Search Error, please try again</p>;

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col lg="10" className="px-lg-0 pr-lg-1 mt-1">
            <div className="input-group">
              <span className="input-group-append bg-white ">
                <span className="input-group-text bg-transparent border-right-0">
                  <i className="bi bi-search" />
                </span>
              </span>
              <Form.Control
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                type="text"
                className="border-left-0"
                placeholder="Job title, Keywords or Company"
              />
            </div>
          </Col>
          <Col lg="2" className="px-lg-0 mt-1">
            <Button
              variant="success"
              size="sm"
              disabled={loading}
              type="submit"
              className="px-2 btn btn-success rounded-pill"
            >
              Search
            </Button>
          </Col>
        </Row>
      </form>
      <Row className="mt-3">
        <Col>{/* <p>Sort by:Relevance | Date</p> */}</Col>
      </Row>
      <Row className="mt-1">
        {presentations &&
          presentations.map((video) => (
            <Col lg="4" key={uuid()}>
              <Post video={video} />
            </Col>
          ))}
      </Row>
    </Container>
  );
}

export default TalentSearch;
