import React, {useState, useEffect} from 'react';
import {Link, useParams, useHistory} from 'react-router-dom';
import {Container, Row, Col, Alert} from 'react-bootstrap';
import axios from 'axios';
import {useRecoilValue, useRecoilState} from 'recoil';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {userSettings, companyJobListState} from '../store/atoms';

function AlertDismissibleText() {
  const [show, setShow] = useState(true);
  if (show) {
    return (
      <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Equity Info</Alert.Heading>
        <p>
          If you agree to provide business status info for talents to manage
          equity pays, please provide equity Info in the company info, thanks!
        </p>
        <p>
          <Link to="/company/create/equity" className="">
            <small>Add equity Info now!</small>
          </Link>
        </p>
      </Alert>
    );
  }

  return null;
}

function CompanyJobAdd() {
  const userInfo = useRecoilValue(userSettings);
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isCompanyInfoExist, setIsCompanyInfoExist] = useState(false);
  const [enableEquityPay, setEnableEquityPay] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({});
  const [companyJobList, setCompanyJobList] =
    useRecoilState(companyJobListState);
  const [jobKey, setJobKey] = useState('');
  const {jobId} = useParams();
  const [backUpData, setBackUpData] = useState(undefined);
  const [showNoChange, setShowNoChange] = useState(false);
  const [isClosedJob, setIsClosedJob] = useState(false);
  const history = useHistory();

  const jobAttributes = [
    'title',
    'description',
    'years',
    'education',
    'skills',
    'other',
    'payment',
    'displayPresentation',
    'equityPay',
    'acceptTrainingApplication',
    'acceptVolunteerApplication',
    'cashPaymentAmount',
    'equityPaymentAmount',
  ];

  const PaymentTypes = {
    // TypeA: 'Paid with cash only',
    TypeB: 'Paid with equity only',
    // TypeC: 'Paid with partial cash and partial equity',
  };

  const validationSchema = yup.object().shape({
    title: yup
      .string()
      .required('Job Title is required')
      .max(512, 'Job Title has a maximum limit of 128 characters'),
    description: yup
      .string()
      .required('Job description is required')
      .max(512, 'Job description has a maximum limit of 512 characters'),
    years: yup
      .number('Please input Years number.')
      .required('Years of Experience is required')
      .integer(),
    education: yup
      .string()
      .required('Education is required')
      .max(128, 'Education has a maximum limit of 128 characters'),
    skills: yup
      .string()
      .required('Skills is required')
      .max(512, 'Skills has a maximum limit of 512 characters'),
    other: yup.string().max(512, 'Other has a maximum limit of 512 characters'),
    payment: yup.string().required('Payment Type is required'),
    equityPay: yup.string().required('Equity Pay is required'),
  });

  const jobAttributesDefaultValues = {
    equityPay: 'NotApply',
    acceptTrainingApplication: true,
    displayPresentation: true,
    acceptVolunteerApplication: true,
  };

  const {
    formState: {errors, isSubmitting},
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: jobAttributesDefaultValues,
  });

  useEffect(() => {
    async function fetchCompanyInfo() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/company/findByEntityKey/${userInfo.companyKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          setIsCompanyInfoExist(true);
          setCompanyInfo(response.data);
          if (!jobId) {
            if (
              response.data?.companyAttributes?.enableEquityPay &&
              response.data?.companyAttributes?.fundingStatus
            ) {
              setValue('equityPay', 'Agree');
            } else {
              setValue('equityPay', 'NotApply');
            }
          }
        }
      } catch (error) {
        setIsCompanyInfoExist(false);
      }
    }

    async function fetchJobDetails(jobEntityKey) {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/company/job/findByEntityKey/${jobEntityKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          jobAttributes.forEach((element) => {
            setValue(element, response.data.jobAttributes[element]);
          });
          if (response.data?.jobAttributes?.equityPay !== 'Agree') {
            setEnableEquityPay(false);
          } else if (
            isCompanyInfoExist &&
            !companyInfo?.companyAttributes?.fundingStatus
          ) {
            setEnableEquityPay(true);
          }
          if (response.data?.jobAttributes?.closed === 'true') {
            setIsClosedJob(true);
          }
        }
      } catch (error) {
        console.error('CompanyJobAdd found job error - ', error);
      }
    }

    if (userInfo.companyKey) {
      fetchCompanyInfo();
      if (jobId) {
        setJobKey(jobId);
        if (companyJobList.length >= 0) {
          const index = companyJobList.findIndex(
            (listItem) => listItem.entityKey === jobId
          );
          if (index >= 0) {
            setBackUpData(companyJobList[index]);
            jobAttributes.forEach((element) => {
              setValue(element, companyJobList[index].jobAttributes[element]);
            });
            setValue(
              'displayPresentation',
              companyJobList[index].jobAttributes.displayPresentation !==
                'false'
            );
            setValue(
              'acceptTrainingApplication',
              companyJobList[index].jobAttributes.acceptTrainingApplication !==
                'false'
            );
            setValue(
              'acceptVolunteerApplication',
              companyJobList[index].jobAttributes.acceptVolunteerApplication !==
                'false'
            );

            if (companyJobList[index].jobAttributes?.equityPay !== 'Agree') {
              setEnableEquityPay(false);
            } else if (
              isCompanyInfoExist &&
              !companyInfo?.companyAttributes?.fundingStatus
            ) {
              setEnableEquityPay(true);
            }
          } else {
            fetchJobDetails(jobId);
          }
        } else {
          fetchJobDetails(jobId);
        }
      }
    } else {
      setIsCompanyInfoExist(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // form validation rules

  const paymentType = watch('payment');

  async function updateCompanyEquityStatus(equityPay) {
    const userEnableEquityPay = equityPay === 'Agree';
    if (userEnableEquityPay) {
      if (!companyInfo?.companyAttributes?.fundingStatus) {
        setEnableEquityPay(true);
      }
      const newCompanyInfo = JSON.parse(JSON.stringify(companyInfo));
      newCompanyInfo.companyAttributes.enableEquityPay = userEnableEquityPay;
      try {
        const response = await axios.put(
          `${API_SERVER_URL}/api/company/updateByEntityKey/${userInfo.companyKey}`,
          newCompanyInfo,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          console.log(
            'CompanyJobAdd update company equity info success - ',
            response.data.companyAttributes.enableEquityPay
          );
        }
      } catch (error) {
        console.log('CompanyJobAdd update company equity info error- ', error);
      }
    } else if (!companyInfo?.companyAttributes?.fundingStatus) {
      setEnableEquityPay(false);
    }
  }

  async function createJob(data) {
    const newJob = {
      jobTitle: data.title,
      userKey: userInfo.userKey,
      companyKey: userInfo.companyKey,
      jobAttributes: {},
    };
    jobAttributes.forEach((element) => {
      newJob.jobAttributes[element] = data[element];
    });

    try {
      const response = await axios.post(
        `${API_SERVER_URL}/api/company/job/create`,
        newJob,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        updateCompanyEquityStatus(data.equityPay);
        setCompanyJobList((oldCompanyJobList) => [
          ...oldCompanyJobList,
          response.data,
        ]);
        setJobKey(response.data.entityKey);
        setIsSuccess(true);
      }
    } catch (error) {
      setIsSuccess(false);
      setIsError(true);
      console.log('CompanyJobAdd add new job error -', error);
    }
  }

  async function updateJob(inputData) {
    const data = {...inputData, years: `${inputData.years}`};
    let findChange = false;
    findChange = Object.keys(data).some(
      (key) => backUpData?.jobAttributes[key] !== data[key]
    );
    if (findChange) {
      const newJob = {
        jobTitle: data.title,
        jobAttributes: {},
      };
      jobAttributes.forEach((element) => {
        newJob.jobAttributes[element] = data[element];
      });
      try {
        // console.log("CompanyJobAdd update job start - ", newJob);
        const response = await axios.put(
          `${API_SERVER_URL}/api/company/job/updateByEntityKey/${jobKey}`,
          newJob,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          updateCompanyEquityStatus(data.equityPay);
          setIsSuccess(true);
          const jobIndex = companyJobList.findIndex(
            (listItem) => listItem.entityKey === jobKey
          );
          const newCompanyJobList = [
            ...companyJobList.slice(0, jobIndex),
            response.data,
            ...companyJobList.slice(jobIndex + 1),
          ];
          setCompanyJobList(newCompanyJobList);
          history.push(`/company/talents/find`);
        }
      } catch (error) {
        setIsSuccess(false);
        setIsError(true);
        console.log('CompanyJobAdd update job error - ', error);
      }
    } else {
      setShowNoChange(true);
    }
  }

  const onSubmit = (data) => {
    setIsError(false);
    setIsSuccess(false);
    return jobKey && !isClosedJob ? updateJob(data) : createJob(data);
  };

  if (!isCompanyInfoExist) {
    return (
      <Container className="shadow rounded">
        <Row className="py-5 m-5">
          <Col>
            <h2>Please input company info first.</h2>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="mb-5">
      <Row className="mt-3">
        <Col>
          <h3>Find Talents</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6 className="mt-3">
            Post | <Link to="/company/talents/find">Posted Jobs </Link>
          </h6>
          {isClosedJob && (
            <h6 className="mt-3 text-info">
              COPY content from a previously closed job.
            </h6>
          )}
        </Col>
      </Row>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12} md={8} className="shadow rounded">
            <div className="form-group my-4">
              <label htmlFor="userInputJobTitle" className="col-form-label">
                Job Title<abbr className="text-danger">*</abbr>
              </label>
              <input
                type="text"
                {...register('title')}
                className={`form-control ${errors.title ? 'is-invalid' : ''}`}
                id="userInputJobTitle"
              />
              <div className="invalid-feedback">{errors.title?.message}</div>
            </div>
            <div className="form-group my-3">
              <label
                htmlFor="userInputJobDescription"
                className="col-form-label"
              >
                Job Description<abbr className="text-danger">*</abbr>
              </label>
              <textarea
                rows="5"
                type="text"
                {...register('description')}
                className={`form-control ${
                  errors.description ? 'is-invalid' : ''
                }`}
                id="userInputJobDescription"
              />
              <div className="invalid-feedback">
                {errors.description?.message}
              </div>
            </div>
            <div className="form-group my-4">
              <label
                htmlFor="userInputExperienceYears"
                className="col-form-label"
              >
                Years of Experience<abbr className="text-danger">*</abbr>
              </label>
              <input
                type="number" min="0"
                {...register('years')}
                className={`form-control ${errors.years ? 'is-invalid' : ''}`}
                id="userInputExperienceYears"
              />
              <div className="invalid-feedback">{errors.years?.message}</div>
            </div>
            <div className="form-group my-4">
              <label htmlFor="userInputEducation" className="col-form-label">
                Education<abbr className="text-danger">*</abbr>
              </label>
              <input
                type="text"
                {...register('education')}
                className={`form-control ${
                  errors.education ? 'is-invalid' : ''
                }`}
                id="userInputEducation"
              />
              <div className="invalid-feedback">
                {errors.education?.message}
              </div>
            </div>
            <div className="form-group my-4">
              <label htmlFor="userInputSkills" className="col-form-label">
                Skills<abbr className="text-danger">*</abbr>
              </label>
              <input
                type="text"
                {...register('skills')}
                className={`form-control ${errors.skills ? 'is-invalid' : ''}`}
                id="userInputSkills"
              />
              <div className="invalid-feedback">{errors.skills?.message}</div>
            </div>
            <div className="form-group my-4">
              <label htmlFor="userInputOther" className="col-form-label">
                Other
              </label>
              <input
                type="text"
                {...register('other')}
                className={`form-control ${errors.other ? 'is-invalid' : ''}`}
                id="userInputOther"
              />
              <div className="invalid-feedback">{errors.other?.message}</div>
            </div>
            <div className="form-group my-4">
              <label htmlFor="userInputPayment" className="col-form-label">
                Payment Type<abbr className="text-danger">*</abbr>
              </label>
              <select
                {...register('payment')}
                className={`form-control ${errors.payment ? 'is-invalid' : ''}`}
                id="userInputPayment"
              >
                <option value="">Choose...</option>
                {Object.entries(PaymentTypes).map(([key, value]) => (
                  <option key={key} value={value}>
                    {value}
                  </option>
                ))}
              </select>
              <div className="invalid-feedback">{errors.payment?.message}</div>
            </div>
            {paymentType === 'TypeC' && (
              <div className="form-group my-4">
                <label
                  htmlFor="userInputCashPaymentAmount"
                  className="col-form-label text-info"
                >
                  Cash payment amount
                </label>
                <input
                  type="text"
                  {...register('cashPaymentAmount')}
                  className={`form-control ${
                    errors.cashPaymentAmount ? 'is-invalid' : ''
                  }`}
                  id="userInputCashPaymentAmount"
                />
                <div id="userInputCashPaymentAmountHelp" className="form-text">
                  <small>(Examples: $10K or up to $10K.)</small>
                </div>
                <div className="invalid-feedback">
                  {errors.cashPaymentAmount?.message}
                </div>
              </div>
            )}
            {(paymentType === 'TypeB' || paymentType === 'TypeC') && (
              <div className="form-group my-4">
                <label
                  htmlFor="userInputEquityPaymentAmount"
                  className="col-form-label text-info"
                >
                  Equity payment amount
                </label>
                <input
                  type="text"
                  {...register('equityPaymentAmount')}
                  className={`form-control ${
                    errors.equityPaymentAmount ? 'is-invalid' : ''
                  }`}
                  id="userInputEquityPaymentAmount"
                />
                <div
                  id="userInputEquityPaymentAmountHelp"
                  className="form-text"
                >
                  <small>
                    (Examples: 10K shares, up to 10K shares, 1%, up to 1%.)
                  </small>
                </div>
                <div className="invalid-feedback">
                  {errors.equityPaymentAmount?.message}
                </div>
              </div>
            )}
            <div className="form-group my-4 form-check">
              <input
                name="acceptTrainingApplication"
                type="checkbox"
                {...register('acceptTrainingApplication')}
                id="acceptTrainingApplication"
                className={`form-check-input ${
                  errors.acceptTrainingApplication ? 'is-invalid' : ''
                }`}
              />
              <label
                htmlFor="acceptTrainingApplication"
                className="form-check-label"
              >
                Accept practical training applications
              </label>
              <div className="invalid-feedback">
                {errors.acceptTrainingApplication?.message}
              </div>
            </div>
            <div>
              {showNoChange && (
                <Alert
                  variant="success"
                  onClose={() => setShowNoChange(!showNoChange)}
                  dismissible
                  style={{width: '470px'}}
                >
                  You haven&apos;t make any change
                </Alert>
              )}
            </div>
            <div className="mb-3">
              {enableEquityPay && <AlertDismissibleText />}
              {isError && (
                <Alert
                  variant="danger"
                  onClose={() => {
                    setIsError(!isError);
                  }}
                  dismissible
                  style={{width: '500px'}}
                >
                  Something went wrong. Please try again later.
                </Alert>
              )}
              {isSuccess && (
                <small className="mt-3 d-inline-block text-success">
                  Submit success!
                </small>
              )}
            </div>
            <div className="row my-4">
              <button
                type="submit"
                disabled={isSuccess}
                className="mx-2 btn btn-success px-5 my-3 rounded-pill"
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-1" />
                )}
                Post Job
              </button>
              <Link
                to="/company/talents/find"
                className="mx-2 px-5 my-3 btn btn-outline-success rounded-pill"
              >
                Back
              </Link>
            </div>
          </Col>
        </Row>
      </form>
    </Container>
  );
}

export default CompanyJobAdd;
