import React from 'react';
import {Nav, OverlayTrigger, Tooltip} from 'react-bootstrap';
import PropTypes from 'prop-types';

function CompanyPresentationLinksInstaIntro({linkKey}) {
  const getTooltipText = (eventKey) => {
    switch (eventKey) {
      case 'linkUpdate':
        return 'Input or update content fields';
      case 'linkPreview':
        return 'Preview the presentation';
      case 'linkPublish':
        return 'Publish or un-publish the presentation';
      default:
        return 'Unknown';
    }
  };
  return (
    <Nav
      variant="pills"
      className="justify-content-end bg-light text-dark"
      activeKey={linkKey}
    >
      <Nav.Item>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>{getTooltipText('linkUpdate')}</Tooltip>}
        >
        <Nav.Link
          eventKey="linkUpdate"
          href="/company/presentation/update_InstaIntro"
        >
          Edit
        </Nav.Link>
        </OverlayTrigger>
      </Nav.Item>
      <Nav.Item>
      <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>{getTooltipText('linkPreview')}</Tooltip>}
        >
        <Nav.Link
          eventKey="linkPreview"
          href="/company/presentation/preview?type=image"
        >
          Preview
        </Nav.Link>
        </OverlayTrigger>
      </Nav.Item>
      <Nav.Item>
      <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>{getTooltipText('linkPublish')}</Tooltip>}
        >
        <Nav.Link
          eventKey="linkPublish"
          href="/company/presentation/publish?type=image"
        >
          Publish
        </Nav.Link>
        </OverlayTrigger>
      </Nav.Item>
      
    </Nav>
  );
}

CompanyPresentationLinksInstaIntro.propTypes = {
  linkKey: PropTypes.string.isRequired,
};

export default CompanyPresentationLinksInstaIntro;
