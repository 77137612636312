import React from 'react';
import PropTypes from 'prop-types';
import {Accordion, Card, Button} from 'react-bootstrap';
import TalentProfileLinks from './TalentProfileLinks';

function TalentProfileDetail({profile}) {
  const openToPublic =
    profile.profileAttributes?.isPublic !== undefined
      ? profile.profileAttributes.isPublic
      : 'false';
  const additionalInfoArray = profile?.profileAttributes?.additionalInfo
    ? JSON.parse(profile.profileAttributes.additionalInfo)
    : [];

  const getUserProfileURI = () => {
    const userName =
      profile.profileAttributes?.userName !== undefined
        ? profile.profileAttributes.userName.split(/\s+/)
        : [];
    const profileLinkWords =
      userName.length >= 2 ? userName[0] + userName[1][0] : 'talentprofile';
    const baseUrl = `${window.location.href.substring(
      0,
      window.location.href.indexOf('/talent/')
    )}/public/profile/talent/${profileLinkWords}/${profile.entityKey}`;
    return baseUrl;
  };

  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            Profile Summary
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="ml-3" style={{whiteSpace: 'pre-line'}}>
            {openToPublic === 'true' && (
              <h6>
                <a
                  href={getUserProfileURI()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bi bi-link-45deg mr-1" />
                  Public Profile Link
                </a>
              </h6>
            )}
            <dl>
              <dt className="text-muted">Profile Name</dt>
              <dd className="mb-2">{profile.profileName}</dd>
              {profile.profileAttributes?.summary && (
                <>
                  <dt className="text-muted">Summary</dt>
                  <dd className="mb-2">{profile.profileAttributes?.summary}</dd>
                </>
              )}
              <TalentProfileLinks profile={profile} />
              {/* <dt className="text-muted">Other</dt>
                                <dd className="mb-2">{profile.profileAttributes?.other}</dd> */}
            </dl>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {profile.profileAttributes?.workExperience && (
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="1">
              Work Experience
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body className="ml-3" style={{whiteSpace: 'pre-line'}}>
              <p>{profile.profileAttributes?.workExperience}</p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      )}
      {profile.profileAttributes?.skills && (
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="2">
              Skills
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="2">
            <Card.Body className="ml-3" style={{whiteSpace: 'pre-line'}}>
              {profile.profileAttributes?.skills}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      )}
      {profile.profileAttributes?.certifications && (
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="3">
              Certifications
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="3">
            <Card.Body className="ml-3" style={{whiteSpace: 'pre-line'}}>
              {profile.profileAttributes?.certifications}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      )}
      {profile.profileAttributes?.patents && (
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="4">
              Patents
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="4">
            <Card.Body className="ml-3" style={{whiteSpace: 'pre-line'}}>
              {profile.profileAttributes?.patents}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      )}
      {profile.profileAttributes?.publications && (
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="5">
              Publications
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="5">
            <Card.Body className="ml-3" style={{whiteSpace: 'pre-line'}}>
              {profile.profileAttributes?.publications}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      )}
      {profile.profileAttributes?.languages && (
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="6">
              Languages
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="6">
            <Card.Body className="ml-3" style={{whiteSpace: 'pre-line'}}>
              {profile.profileAttributes?.languages}
            </Card.Body>
          </Accordion.Collapse>
        </Card>  
      )}     
      {profile.profileAttributes?.education && (
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="7">
              education
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="7">
            <Card.Body className="ml-3" style={{whiteSpace: 'pre-line'}}>
              {profile.profileAttributes?.education}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      )}

      {additionalInfoArray.length > 0 && (
        <>
          {additionalInfoArray.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Card key={`1${index}`}>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey={`1${index}`}
                >
                  Additional: {additionalInfoArray[index].infoName}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={`1${index}`}>
                <Card.Body className="ml-3" style={{whiteSpace: 'pre-line'}}>
                  {additionalInfoArray[index].infoBody}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
        </>
      )}
    </Accordion>
  );
}

TalentProfileDetail.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default TalentProfileDetail;
