const industriesListForClient = [
  {value: "AI & Machine Learning", label: "AI & Machine Learning"},
  {value: "B2B Software", label: "B2B Software"},
  {value: "Big Data", label: "Big Data"},
  {value: "BioTech", label: "BioTech"},
  {value: "Blockchain, Crypto, Defi and Web3", label: "Blockchain, Crypto, Defi and Web3"},
  {value: "Cybersecurity", label: "Cybersecurity"},
  {value: "Delivery Services", label: "Delivery Services"},
  {value: "Drone Technology", label: "Drone Technology"},
  {value: "Ecommerce", label: "Ecommerce"},
  {value: "Edtech", label: "Edtech"},
  {value: "Fintech", label: "Fintech"},
  {value: "FoodTech", label: "FoodTech"},
  {value: "HealthTech", label: "HealthTech"},
  {value: "Internet of Things", label: "Internet of Things"},
  {value: "MedTech", label: "MedTech"},
  {value: "Real Estate", label: "Real Estate"},
  {value: "Shared Mobility", label: "Shared Mobility"},
  {value: "Supply Chain Management & Logistics", label: "Supply Chain Management & Logistics"},
  {value: "VR / AR", label: "VR / AR"},
  {value: 'Other', label: 'Other'},
];

export default industriesListForClient;
