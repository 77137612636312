import React from 'react';
import PropTypes from 'prop-types';
import JobDetail from './JobDetail';

function JobCards({jobList}) {
  return (
    <>
      {jobList.map((item) => (
        <JobDetail
          job={item}
          key={item.entityKey}
          closed={item.jobAttributes?.closed}
        />
      ))}
    </>
  );
}

JobCards.propTypes = {
  jobList: PropTypes.array.isRequired,
};

export default JobCards;
