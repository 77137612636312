import React, {useEffect, useState} from 'react';
import {Container, Row, Col, Nav} from 'react-bootstrap';
import {useHistory, useParams} from 'react-router-dom';
import {useRecoilValue, useRecoilState, useSetRecoilState} from 'recoil';
import axios from 'axios';
import {
  userSettings,
  talentFavoriteJobListState,
  talentProfileListState,
  talentApplicationListState,
} from '../store/atoms';
import JobSearchCard from './JobSearchCard';

function TalentFindCompanyJobs() {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const [talentFavoriteJobList, setTalentFavoriteJobList] = useRecoilState(
    talentFavoriteJobListState
  );
  const [talentApplicationList, setTalentApplicationList] = useRecoilState(
    talentApplicationListState
  );
  const [talentJobSearchResult, setTalentJobSearchResult] = useState([]);
  const setTalentProfileList = useSetRecoilState(talentProfileListState);

  const {companyKey} = useParams();
  const history = useHistory();

  useEffect(() => {
    async function fetchTalentInfo() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/user/findByUserKey/${userInfo.userKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          console.log('TalentFindCompanyJobs found talent info');
        }
      } catch (error) {
        history.push(`/talent/info`);
      }
    }

    if (userInfo.userKey) {
      fetchTalentInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchTalentProfilesSummary() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/profile/findByUserKeyWithFilter?userKey=${userInfo.userKey}&filter=summary`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          if (response.data.length > 0) {
            setTalentProfileList(response.data);
            localStorage.setItem('profiles', JSON.stringify(response.data));
          } else {
            history.push(`/talent/profiles`);
          }
        }
      } catch (error) {
        history.push(`/talent/profiles`);
      }
    }

    if (userInfo.userKey) {
      fetchTalentProfilesSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentProfileListState]);

  useEffect(() => {
    async function fetchFavoriteList() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/favorite/job/findFavoriteDetailByUserKey/${userInfo.userKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setTalentFavoriteJobList(response.data);
        }
      } catch (error) {
        console.error(
          'TalentFindCompanyJobs found FavoriteList from remote error - ',
          error
        );
      }
    }

    if (talentFavoriteJobList.length === 0) {
      fetchFavoriteList();
    } else {
      console.log(
        'TalentFindCompanyJobs get talentFavoriteJobListState - ',
        talentFavoriteJobList.length
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentFavoriteJobList]);

  useEffect(() => {
    async function fetchTalentApplicationList() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/job/findAppliedJobWithTimeStampByUserKey/${userInfo.userKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setTalentApplicationList(response.data);
        }
      } catch (error) {
        console.error(
          'TalentFindCompanyJobs found ApplicationList from remote error - ',
          error
        );
      }
    }

    if (talentApplicationList.length === 0) {
      fetchTalentApplicationList();
    } else {
      console.log(
        'TalentFindCompanyJobs get talentApplicationListState - ',
        talentApplicationList.length
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentApplicationList]);

  useEffect(() => {
    async function fetchJobsList() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/company/job/findByCompanyKey/${companyKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          setTalentJobSearchResult(response.data);
        }
      } catch (error) {
        console.error('TalentFindCompanyJobs found jobs error - ', error);
      }
    }
    if (companyKey) {
      fetchJobsList(companyKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const connectCompany = async () => {
    console.log('Talent click to connect company - ', companyKey);
  };

  const addToFavorite = async (jobKey) => {
    // console.log("Talent click add to favorite - ", jobKey);
    const newFavorite = {
      userKey: userInfo.userKey,
      jobKey,
    };
    try {
      console.log('TalentFindCompanyJobs add favorite job - ', jobKey);
      const response = await axios.post(
        `${API_SERVER_URL}/api/talent/favorite/job/addFavorite`,
        newFavorite,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        setTalentFavoriteJobList((oldFavoriteJobList) => [
          ...oldFavoriteJobList,
          response.data,
        ]);
      }
    } catch (error) {
      console.log('TalentFindCompanyJobs add favorite job error -', error);
    }
  };

  const deleteFavorite = async (favoriteEntityKey) => {
    try {
      const response = await axios.delete(
        `${API_SERVER_URL}/api/talent/favorite/job/deleteFavoriteByEntityKey/${favoriteEntityKey}`,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (response.status === 200) {
        const favoriteIndex = talentFavoriteJobList.findIndex(
          (listItem) => listItem.entityKey === favoriteEntityKey
        );
        if (favoriteIndex >= 0) {
          const newFavoriteList = [
            ...talentFavoriteJobList.slice(0, favoriteIndex),
            ...talentFavoriteJobList.slice(favoriteIndex + 1),
          ];
          setTalentFavoriteJobList(newFavoriteList);
        } else {
          console.log('TalentFindCompanyJobs delete favorite job error.');
        }
      }
    } catch (error) {
      console.log('TalentFindCompanyJobs delete favorite job error -', error);
    }
  };

  return (
    <Container>
      <Row>
        <Col md={2} className="px-lg-0 pr-lg-1 mt-1">
          <Nav.Link href="/talent/companies">Back</Nav.Link>
        </Col>
      </Row>
      <Row className="mt-1">
        {talentJobSearchResult &&
          talentJobSearchResult.map((item) => (
            <JobSearchCard
              key={item.entityKey}
              job={item}
              onAddFavorite={addToFavorite}
              onDeleteFavorite={deleteFavorite}
              onConnectCompany={connectCompany}
            />
          ))}
      </Row>
    </Container>
  );
}

export default TalentFindCompanyJobs;
