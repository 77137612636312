import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {useRecoilValue} from 'recoil';
import {Row, Col, Card, ListGroup} from 'react-bootstrap';
import moment from 'moment';
import fp from 'lodash/fp';
import {FeedbackReplyModal} from '../FeedbackReplyModal';
import {userSettings, systemAlertInfoState} from '../../store/atoms';
import SystemAlert from '../SystemAlert';

function UserFeedbackInbox() {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const [feedbackList, setFeedbackList] = useState([]);
  const [isFeedbackReceiver, setIsFeedbackReceiver] = useState(false);
  const systemAlertInfo = useRecoilValue(systemAlertInfoState);

  useEffect(() => {
    setFeedbackList([]);
    async function fetchInboxFeedbackRecipient() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/${userInfo.loggedInAsUserType.toLowerCase()}/feedback/receive/findByRecipientUserKey/${
            userInfo.userKey
          }`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setFeedbackList(
            Object.entries(
              fp.groupBy('receivedFeedbackAttributes.feedbackKey')(
                response?.data
              )
            )
          );
        }
      } catch (error) {
        console.error('Failed to get sender inbox feedback - ', error);
      }
    }

    async function fetchInboxFeedbackSender() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/${userInfo.loggedInAsUserType.toLowerCase()}/feedback/receive/findByRecipientUserKey/${
            userInfo.userKey
          }`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setFeedbackList(
            Object.entries(
              fp.groupBy('receivedFeedbackAttributes.feedbackKey')(
                response.data
              )
            )
          );
        }
      } catch (error) {
        console.error('Failed to get sender inbox feedback - ', error);
      }
    }

    async function fetchFeedbackUserKey() {
      const FEEDBACK_USER_EMAIL = 'feedback@startnation.live';
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/user/findByEmail/${FEEDBACK_USER_EMAIL}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          if (response.data.userKey === userInfo.userKey) {
            fetchInboxFeedbackRecipient();
            setIsFeedbackReceiver(true);
          } else {
            fetchInboxFeedbackSender();
          }
        }
      } catch (error) {
        console.error('feedback user not found - ', error);
      }
    }

    if (userInfo.userKey) {
      fetchFeedbackUserKey();
    }
    // eslint-disable-next-line
  }, []);

  const sortedFeedbackList = feedbackList.sort((a, b) => {
    const modifyTimeA = new Date(a[1][0].receivedFeedbackAttributes.modifyTime);
    const modifyTimeB = new Date(b[1][0].receivedFeedbackAttributes.modifyTime);
    return modifyTimeB - modifyTimeA; // For descending order
  });

  return (
    <>
      {systemAlertInfo.show && (
        <Col>
          <SystemAlert info={systemAlertInfo} />
        </Col>
      )}
      <p>User Feedback Inbox</p>
      <Row className="mt-1">
        {feedbackList &&
          sortedFeedbackList.map((item) => (
            <FeedbackCard
              key={item[1][0].entityKey}
              feedback={item}
              userInfo={userInfo}
              apiServer={API_SERVER_URL}
              feedbackList={sortedFeedbackList}
              setFeedbackList={setFeedbackList}
              isFeedbackReceiver={isFeedbackReceiver}
            />
          ))}
      </Row>
    </>
  );
}

function FeedbackCard({
  apiServer,
  feedback,
  feedbackList,
  isFeedbackReceiver,
  setFeedbackList,
  userInfo,
}) {
  const [replyModal, setReplyModal] = useState(false);

  const handleDelete = () => {
    async function deleteFeedback(entityKey) {
      try {
        const response = await axios.delete(
          `${apiServer}/api/${userInfo.loggedInAsUserType.toLowerCase()}/feedback/receive/deleteByEntityKey/${entityKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          if (feedback[1].length === 1) {
            setFeedbackList(
              feedbackList.filter((msg) => msg[1][0].entityKey !== entityKey)
            );
          } else {
            setFeedbackList(
              feedbackList.filter((msg) => msg[0] !== feedback[0])
            );
          }
        }
      } catch (error) {
        console.error('Failed to delete feedback - ', error);
      }
    }
    if (userInfo.userKey) {
      if (feedback[1].length === 1) {
        deleteFeedback(feedback[1][0].entityKey);
      } else {
        feedback[1].forEach((element) => {
          deleteFeedback(element.entityKey);
        });
      }
    }
  };

  return (
    <>
      {feedback && feedback[1].length === 1 && (
        <Col md={12} className="my-3">
          <Card className="bg-light">
            <Card.Body>
              <dl className="row my-3">
                {isFeedbackReceiver && (
                  <>
                    <dt className="col-sm-4 text-muted">
                      Received From(
                      {feedback[1][0] &&
                        feedback[1][0].receivedFeedbackAttributes?.userType}
                      ):
                    </dt>
                    {feedback[1][0].receivedFeedbackAttributes?.userName ? (
                      <dd className="col-sm-8">
                        {feedback[1][0].receivedFeedbackAttributes?.userName}(
                        {feedback[1][0].receivedFeedbackAttributes?.userEmail})
                      </dd>
                    ) : (
                      <dd className="col-sm-8">{feedback[1][0].fromUserKey}</dd>
                    )}
                  </>
                )}

                <dt className="col-sm-4 text-muted">Received Time:</dt>
                <dd className="col-sm-8">
                  {moment(
                    feedback[1][0].receivedFeedbackAttributes?.modifyTime
                  ).format('MMMM Do YYYY, h:mm:ss a')}
                </dd>

                <dt className="col-sm-4 text-muted">Subject:</dt>
                <dd className="col-sm-8">
                  {feedback[1][0].receivedFeedbackAttributes?.subject}
                </dd>

                <dt className="col-sm-4 text-muted">Message:</dt>
                <dd className="col-sm-8">
                  {feedback[1][0].receivedFeedbackAttributes?.body}
                </dd>
              </dl>
              {replyModal && (
                <FeedbackReplyModal
                  showModal={replyModal}
                  setShowModal={setReplyModal}
                  feedback={feedback[1][0]}
                />
              )}
            </Card.Body>
            <Card.Footer>
              {feedback[1][0].receivedFeedbackAttributes.subject !==
                'EARLY EQUICOIN ADOPTION' && (
                <>
                  <button
                    type="button"
                    className="btn btn-md btn-outline-success rounded-pill"
                    onClick={() => setReplyModal(true)}
                  >
                    Reply
                  </button>
                  <button
                    type="button"
                    className="btn btn-md btn-outline-success rounded-pill"
                    style={{margin: '0px 20px'}}
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                </>
              )}
            </Card.Footer>
          </Card>
        </Col>
      )}
      {feedback && feedback[1].length > 1 && (
        <Col md={12} className="my-3">
          <Card className="bg-light">
            <Card.Body>
              {isFeedbackReceiver && (
                <Card.Title style={{'font-size': '18px'}}>
                  Received From(
                  {feedback[1][0] &&
                    feedback[1][0].receivedFeedbackAttributes?.userType}
                  ):
                  {feedback[1][0].receivedFeedbackAttributes?.userName
                    ? `${feedback[1][0].receivedFeedbackAttributes?.userName}(${feedback[1][0].receivedFeedbackAttributes?.userEmail})`
                    : `${feedback[1][0].toUserKey}`}
                </Card.Title>
              )}
              {feedback[1][0].receivedFeedbackAttributes.originMsgBody && (
                <>
                  <dt className="col-sm-4 text-muted">Original Messaage:</dt>
                  <dd className="col-sm-8">
                    {feedback[1][0].receivedFeedbackAttributes.originMsgBody}
                  </dd>
                </>
              )}

              <ListGroup variant="flush">
                {feedback[1].map((item) => (
                  <ListGroup.Item key={item.entityKey}>
                    <dl className="row my-3">
                      <dt className="col-sm-4 text-muted">Received Time:</dt>
                      <dd className="col-sm-8">
                        {moment(
                          item.receivedFeedbackAttributes?.modifyTime
                        ).format('MMMM Do YYYY, h:mm:ss a')}
                      </dd>

                      <dt className="col-sm-4 text-muted">Subject:</dt>
                      <dd className="col-sm-8">
                        {item.receivedFeedbackAttributes?.subject}
                      </dd>

                      <dt className="col-sm-4 text-muted">Message:</dt>
                      <dd className="col-sm-8">
                        {item.receivedFeedbackAttributes?.body}
                      </dd>
                    </dl>
                    {replyModal && (
                      <FeedbackReplyModal
                        showModal={replyModal}
                        setShowModal={setReplyModal}
                        feedback={item}
                      />
                    )}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
            <Card.Footer>
              <button
                type="button"
                className="btn btn-md btn-outline-success rounded-pill"
                onClick={() => setReplyModal(true)}
              >
                Reply
              </button>
              <button
                type="button"
                className="btn btn-md btn-outline-success rounded-pill"
                style={{margin: '0px 20px'}}
                onClick={handleDelete}
              >
                Delete
              </button>
            </Card.Footer>
          </Card>
        </Col>
      )}
    </>
  );
}

FeedbackCard.propTypes = {
  feedback: PropTypes.array.isRequired,
  feedbackList: PropTypes.array.isRequired,
  userInfo: PropTypes.object.isRequired,
  apiServer: PropTypes.string.isRequired,
  setFeedbackList: PropTypes.func.isRequired,
  isFeedbackReceiver: PropTypes.bool.isRequired,
};

export default UserFeedbackInbox;
